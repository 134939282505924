import axios from 'axios';

async function apiHandler(path, method = "GET", params = {}, headers = {}) {
  if (localStorage.getItem("uzoneAuthToken")) {
    //var userData= JSON.parse(localStorage.getItem("uzoneAuthToken"))
    headers = {
      'x-auth-token': `${localStorage.getItem("uzoneAuthToken")}`,
    };
  }
  let apiRes = null
  let baseURL = "https://api.thefriendinneedsocial.com/"
  switch (method) {
    case "GET":
      try {
        //return await axios.get(`${process.env.API_URL}${path}`, {
        apiRes = await axios.get(`${baseURL}${path}`, {
          headers: headers,
        })
        return apiRes
      }
      catch (e) {
        return e
      }
      break;

    case "GET1":
      try {
        //console.log("URL console",process.env.API_URL)
        apiRes = await axios.get(`${baseURL}${path}`, {
          headers: headers,
          params: params
        })
        return apiRes
      }
      catch (e) {
        return e
      }
      break;

    case "POST":
      try {
        //console.log("URL console",process.env.API_URL)
        apiRes = await axios.post(`${baseURL}${path}`, params, {
          headers: headers,
        })
        return apiRes
      }
      catch (e) {
        return e
      }
      break;

    case "POSTFILE":
      try {
        //console.log("URL console",process.env.API_URL)
        apiRes = await axios.post(`${baseURL}${path}`, {
          headers: headers,
          data: params
        })
        return apiRes
      }
      catch (e) {
        return e
      }
      break;

    case "DELETE":
      try {
        //console.log("URL console",process.env.API_URL)
        apiRes = await axios.delete(`${baseURL}${path}`, {
          headers: headers,
          data: params
        })
        return apiRes
      }
      catch (e) {
        return e
      }
      break;

    default:
  }

}

export { apiHandler };