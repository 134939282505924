import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { apiHandler } from '../../api/apihandler';
import { handleImageUpload } from "../../api/uploads";
import { COMMENT_LIKE, COMMENT_LIKES, COMMENT_LIST, COMMENT_POST, DELETE_COMMENT, FEEDS_LIST, FEED_DELETE, FEED_MEDIA_POST, FEED_POST, FOLLOWER_LIST, FOLLOWING_LIST, PROFILE_DETAILS } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'
import likeFillIcon from '../../assets/images/icon/like_fill_icon.png'
import likeIcon from '../../assets/images/icon/like_icon.png'
import CommentBox from './commentBox'

// images
import small07 from '../../assets/images/small/07.png'
import small08 from '../../assets/images/small/08.png'
import small09 from '../../assets/images/small/09.png'
import CustomToggleForHover from '../dropdownsForHover'


const SingleUserComment = ({ postId, commentElm, setRefreshCommentList }) => {
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [showLikesList, setShowLikesList] = useState(false);
    const [commentLikesUserList, setcommentLikesUserList] = useState([]);

    useEffect(() => {
        getCommentLikesUserList(commentElm?.id)
    }, [])

    async function getCommentLike(commentId) {
        if (localStorage.getItem("uzoneAuthToken")) {
            try {
                const res = await apiHandler(COMMENT_LIKE, "POST", {
                    comment_id: commentId
                })
                if (res.data?.response?.status === 200) {
                    if (commentElm?.is_like === 1) {
                        commentElm['is_like'] = 0
                        commentElm['like_count'] = commentElm?.like_count - 1
                    } else {
                        commentElm['is_like'] = 1
                        commentElm['like_count'] = commentElm?.like_count + 1
                    }
                    getCommentLikesUserList(commentElm?.id)
                    // Swal.fire({
                    //     title: 'Success',
                    //     text: res?.response?.data?.data?.message,
                    //     icon: 'success',
                    //     confirmButtonText: 'Okay'
                    // })
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            }
            finally {
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    async function getDeleteComment(commentId) {
        if (localStorage.getItem("uzoneAuthToken")) {
            try {
                const res = await apiHandler(DELETE_COMMENT, "DELETE", {
                    comment_id: commentId
                })
                if (res.data?.response?.status === 200) {
                    setRefreshCommentList(true)
                    Swal.fire({
                        title: 'Success',
                        text: 'Your comment deleted successfully',
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            }
            finally {
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    async function getCommentLikesUserList(commentId) {
        try {
            const res = await apiHandler(COMMENT_LIKES, "POST", {
                comment_id: commentId
            })
            if (res.data?.response?.status === 200) {
                setcommentLikesUserList(res?.data?.data?.userLikes)
            } else {
                // Swal.fire({
                //     title: 'Error',
                //     text: res?.response?.data?.data?.message,
                //     icon: 'error',
                //     confirmButtonText: 'Okay'
                // })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }


    return (
        <>
            <div className="d-flex justify-content-between" style={{ marginTop: "10px" }}>
                <div className="me-3">
                    <img loading="lazy" className="rounded-circle  avatar-30"
                        src={commentElm?.user?.user_setting?.photo ? commentElm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                </div>
                <div className="w-100">
                    <div className="d-flex justify-content-between flex-wrap">
                        <div>
                            <h6 className="mb-0 d-inline-block"><Link to="#">{commentElm?.user?.first_name + " " + commentElm?.user?.last_name}</Link></h6>
                            {
                                commentElm?.image ?
                                    <div>
                                        <img loading="lazy" className="avatar-60" src={commentElm?.image} alt="" />
                                    </div> : null
                            }
                            <p className="mb-0">{commentElm?.comment}</p>
                            <div>
                                <h6 className="mb-0 d-inline-block"
                                    onClick={() => { getCommentLike(commentElm?.id) }}
                                    onMouseEnter={() => setShowLikesList(true)}
                                    onMouseLeave={() => setShowLikesList(false)}
                                >
                                    <img loading="lazy" src={commentElm?.is_like === 1 ? likeFillIcon : likeIcon} alt="icon" className="img-fluid" />
                                    {
                                        commentElm?.is_like === 1 ?
                                            <Link to="#">{commentElm?.like_count + " "}Like</Link> :
                                            <Link to="#" style={{ marginLeft: "6px" }}>{commentElm?.like_count + " "}Like</Link>
                                    }
                                    {/* <Link to="#">{commentElm?.like_count + " "}Like</Link> */}
                                </h6>
                                <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                    <Link to="#" onClick={() => { setShowCommentBox((val) => !val) }}>Reply</Link>
                                </h6>
                                {
                                    commentElm?.is_own === 1 ?
                                        <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}><Link to="#" onClick={() =>
                                            Swal.fire({
                                                title: 'Warning!',
                                                text: 'Are you sure, want to delete this comment!',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                showConfirmButton: true,
                                                cancelButtonText: 'Cancel',
                                                confirmButtonText: 'Confirm',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    getDeleteComment(commentElm?.id)
                                                }
                                            })
                                        }>Delete</Link>
                                        </h6> : null
                                }
                            </div>

                            {
                                showLikesList ?
                                    <div className="card-header-toolbar d-flex align-items-center" style={{ position: "absolute" }}
                                        onMouseEnter={() => setShowLikesList(true)}
                                        onMouseLeave={() => setShowLikesList(false)}>
                                        <Dropdown>
                                            <Card className="dropdown-menu-right cardBackgroundColor">
                                                <div style={{ backgroundColor: "#e4f0f1", margin: "1px" }}>
                                                    {
                                                        commentLikesUserList ?
                                                            commentLikesUserList?.map((elm, i) => (
                                                                <Dropdown.Item href={`/dashboard/app/friend-profile/${elm?.user?.slug}`} >{elm?.user?.first_name + " " + elm?.user?.last_name}</Dropdown.Item>
                                                            )) : null
                                                    }
                                                </div>
                                            </Card>
                                        </Dropdown>
                                    </div> : null
                            }

                            {
                                showCommentBox ?
                                    <CommentBox
                                        postId={postId}
                                        commentId={commentElm?.id}
                                        setRefreshCommentList={setRefreshCommentList}
                                        setShowCommentBox={setShowCommentBox} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleUserComment
