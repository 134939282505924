import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { apiHandler } from '../../api/apihandler';
import { CHALLENGE_DELETE_PARTICIPATE, COMMENT_LIKE, COMMENT_LIKES, COMMENT_LIST, COMMENT_POST, DELETE_COMMENT, FEEDS_LIST, FEED_DELETE, FEED_MEDIA_POST, FEED_POST, FOLLOWER_LIST, FOLLOWING_LIST, MARK_AS_WINNER, PROFILE_DETAILS } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
//import VideoThumbnail from 'react-video-thumbnail';
import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'
import likeFillIcon from '../../assets/images/icon/like_fill_icon.png'
import likeIcon from '../../assets/images/icon/like_icon.png'
import CommentBox from '../comment/commentBox'

// images
import small07 from '../../assets/images/small/07.png'
import small08 from '../../assets/images/small/08.png'
import small09 from '../../assets/images/small/09.png'
import CustomToggleForHover from '../dropdownsForHover'
import playButton from '../../assets/images/icon/play_button.png'
import markAsWin from '../../assets/images/icon/mark_as_win_icon.png'
import winnerIcon from '../../assets/images/badges/04.png'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import ChallengeCommentBox from './challengeCommentBox'
import ParticipateCommentList from './participateCommentList'

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;


const SingleUserParticipate = ({ postId, commentElm, winnerParticipateId, setWinnerParticipateId, getParticipantList }) => {
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const dispatch = useDispatch()
    const profileData = JSON.parse(useSelector((state) => state.setting.setting.user_data));
    // const [show, setShow] = useState(false);
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [showLikesList, setShowLikesList] = useState(false);
    const [commentLikesUserList, setcommentLikesUserList] = useState([]);
    const [feedMediaList, setFeedMediaList] = useState([]);
    const [feedMediaTypeList, setFeedMediaTypeList] = useState([]);
    const [refreshPCList, setRefreshPCList] = useState(false);

    const [imageController, setImageController] = useState({
        toggler: false,
        slide: 1
    });
    function imageOnSlide(number) {
        setImageController({
            toggler: !imageController.toggler,
            slide: number
        });
    }

    // const handleClose = () => setShow(false);
    // const handleShow = () => {
    //     setShow(true);
    // }

    async function createMedia() {
        commentElm?.user_post_participate_images.map((image, i) => {
            if (!feedMediaList.includes(image?.image_path)) {
                feedMediaList.push(image?.image_path)
                setFeedMediaList(feedMediaList)
                if (image?.media_type === 1) {
                    feedMediaTypeList.push("video")
                    setFeedMediaTypeList(feedMediaTypeList)
                } else {
                    feedMediaTypeList.push("image")
                    setFeedMediaTypeList(feedMediaTypeList)
                }
            }
        })
        //console.log("arrayList", feedMediaList)
    }

    useEffect(() => {
        getCommentLikesUserList(commentElm?.id)
        createMedia()
    }, [])

    async function getCommentLike(commentId) {
        try {
            const res = await apiHandler(COMMENT_LIKE, "POST", {
                comment_id: commentId
            })
            if (res.data?.response?.status === 200) {
                if (commentElm?.is_like === 1) {
                    commentElm['is_like'] = 0
                    commentElm['like_count'] = commentElm?.like_count - 1
                } else {
                    commentElm['is_like'] = 1
                    commentElm['like_count'] = commentElm?.like_count + 1
                }
                getCommentLikesUserList(commentElm?.id)
                Swal.fire({
                    title: 'Success',
                    text: res?.response?.data?.data?.message,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function getDeleteParticipate(commentId) {
        try {
            const res = await apiHandler(CHALLENGE_DELETE_PARTICIPATE, "DELETE", {
                participate_id: commentId
            })
            if (res.data?.response?.status === 200) {
                getParticipantList()
                Swal.fire({
                    title: 'Success',
                    text: 'Your are successfully exit from this challenge',
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function getCommentLikesUserList(commentId) {
        try {
            const res = await apiHandler(COMMENT_LIKES, "POST", {
                comment_id: commentId
            })
            if (res.data?.response?.status === 200) {
                setcommentLikesUserList(res?.data?.data?.userLikes)
            } else {
                // Swal.fire({
                //     title: 'Error',
                //     text: res?.response?.data?.data?.message,
                //     icon: 'error',
                //     confirmButtonText: 'Okay'
                // })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function markAsWinnerApi(userPostParticipateId) {
        try {
            const res = await apiHandler(MARK_AS_WINNER, "POST", {
                user_post_participate_id: userPostParticipateId
            })
            if (res.data?.response?.status === 200) {
                setWinnerParticipateId(userPostParticipateId)
                Swal.fire({
                    title: 'Success',
                    text: res?.response?.data?.data?.message,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }


    return (
        <>
            {
                feedMediaList?.length > 0 ?
                    // <FsLightbox
                    //     toggler={toggler}
                    //     sources={feedMediaList}
                    //     // sources={[
                    //     //     'https://uzone360live.s3.amazonaws.com/feedImages/img4.jpg',
                    //     //     'https://i.imgur.com/fsyrScY.jpg',
                    //     //     'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
                    //     // ]}
                    //     types={feedMediaTypeList}
                    // /> 
                    <FsLightbox
                        toggler={imageController.toggler}
                        sources={feedMediaList}
                        slide={imageController.slide}
                        types={feedMediaTypeList}
                    />
                    : null
            }
            <div className="d-flex justify-content-between" style={{ marginTop: "10px" }}>
                <div className="me-3">
                    <img loading="lazy" className="rounded-circle  avatar-30"
                        src={commentElm?.user?.user_setting?.photo ? commentElm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                </div>
                <div className="w-100">
                    <div className="d-flex justify-content-between flex-wrap">
                        <div>
                            <h6 className="mb-0 d-inline-block"><Link to="#">{commentElm?.user?.first_name + " " + commentElm?.user?.last_name}</Link></h6>
                        </div>
                    </div>
                    <p className="mb-0">{commentElm?.comment}</p>
                    <div className="user-post" >
                        <Link to="#" >
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                            >
                                <Masonry columnsCount={3} gutter="10px">
                                    {commentElm?.user_post_participate_images.map((image, i) => (
                                        image?.media_type === 1 ?
                                            <div onClick={() => {
                                                imageOnSlide(i + 1)
                                            }}>
                                                <div className="img-fluid" style={{ width: 150, height: 150 }} >
                                                    <img style={{ height: 150, width: 150, marginRight: "17px", }} loading="lazy"
                                                        src={image?.thumb} alt="gallary" className="img-fluid" />
                                                    {/* <VideoThumbnail
                                                        width={150}
                                                        height={150}
                                                        videoUrl={image?.image_path} /> */}
                                                </div>
                                                <Link style={{ position: "absolute", marginTop: "-104px", marginLeft: "49px", zIndex: 1 }}>
                                                    <img style={{ height: 50, width: 50 }} src={playButton} />
                                                </Link>
                                            </div> :
                                            <img loading="lazy" src={image?.image_path}
                                                alt="post" className="img-fluid w-100" style={{ width: "150px" }}
                                                onClick={() => {
                                                    imageOnSlide(i + 1)
                                                }} />
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        </Link>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <h6 className="mb-0 d-inline-block"
                            onClick={() => { getCommentLike(commentElm?.id) }}
                            onMouseEnter={() => setShowLikesList(true)}
                            onMouseLeave={() => setShowLikesList(false)}
                        >
                            <img loading="lazy" src={commentElm?.is_like === 1 ? likeFillIcon : likeIcon} alt="icon" className="img-fluid" />
                            {
                                commentElm?.is_like === 1 ?
                                    <Link to="#">{commentElm?.like_count + " "}Like</Link> :
                                    <Link to="#" style={{ marginLeft: "6px" }}>{commentElm?.like_count + " "}Like</Link>
                            }
                            {/* <Link to="#">{commentElm?.like_count + " "}Like</Link> */}
                        </h6>
                        <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                            <Link to="#" onClick={() => { setShowCommentBox((val) => !val) }}>Reply</Link>
                        </h6>
                        {
                            commentElm?.is_own === 1 ?
                                <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}><Link to="#" onClick={() =>
                                    Swal.fire({
                                        title: 'Warning!',
                                        text: 'Are you sure, want to exit from this challenge!',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        showConfirmButton: true,
                                        cancelButtonText: 'Cancel',
                                        confirmButtonText: 'Confirm',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            getDeleteParticipate(commentElm?.id)
                                        }
                                    })
                                }>Delete</Link>
                                </h6> : null
                        }
                    </div>
                    <ParticipateCommentList participateId={commentElm?.id} refreshPCList={refreshPCList} setRefreshPCList={setRefreshPCList} />
                    {
                        showLikesList ?
                            <div className="card-header-toolbar d-flex align-items-center" style={{ position: "absolute" }}
                                onMouseEnter={() => setShowLikesList(true)}
                                onMouseLeave={() => setShowLikesList(false)}>
                                <Dropdown>
                                    <Card className="dropdown-menu-right cardBackgroundColor">
                                        <div style={{ backgroundColor: "#e4f0f1", margin: "1px" }}>
                                            {
                                                commentLikesUserList ?
                                                    commentLikesUserList?.map((elm, i) => (
                                                        <Dropdown.Item href={`/dashboard/app/friend-profile/${elm?.user?.slug}`} >{elm?.user?.first_name + " " + elm?.user?.last_name}</Dropdown.Item>
                                                    )) : null
                                            }
                                        </div>
                                    </Card>
                                </Dropdown>
                            </div> : null
                    }

                    {
                        showCommentBox ?
                            <ChallengeCommentBox
                                participateId={commentElm?.id}
                                commentId={""}
                                setRefreshPCList={setRefreshPCList}
                                setShowCommentBox={setShowCommentBox} /> : null
                    }
                </div>

                {
                    winnerParticipateId === commentElm?.id ?
                        <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                            <img loading="lazy" className="avatar-40" src={winnerIcon} alt="" />
                        </h6> :
                        // profileData?.id === commentElm?.user_id && winnerParticipateId === 0 ?
                        commentElm?.can_make_winner === 1 ?
                            <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                <Link to="#" className="justify-content-between" onClick={() =>
                                    Swal.fire({
                                        title: 'Warning!',
                                        text: 'Are you sure, want to win this participant?',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        showConfirmButton: true,
                                        cancelButtonText: 'Cancel',
                                        confirmButtonText: 'Confirm',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            markAsWinnerApi(commentElm?.id)
                                        }
                                    })
                                }>
                                    <img loading="lazy" className="avatar-20" src={markAsWin} alt="" />
                                    {"ToWin"}
                                </Link>
                            </h6> : null
                }
            </div>
        </>
    )
}

export default SingleUserParticipate
