import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { handleImageUpload } from "../../api/uploads";
import { CHALLENGE_ADD_PERTICIPANTS_COMMENT, COMMENT_LIST, COMMENT_POST, DELETE_COMMENT, FEEDS_LIST, FEED_DELETE, FEED_MEDIA_POST, FEED_POST, FOLLOWER_LIST, FOLLOWING_LIST, PROFILE_DETAILS } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import LoaderModal from '../loaderModal'

import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'


const ChallengeCommentBox = ({ participateId, commentId, setRefreshPCList, setShowCommentBox }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [postComment, setPostComment] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [commentImage, setCommentImage] = useState('');
    const [commentImageFile, setCommentImageFile] = useState('');

    useEffect(() => {

    }, [])
    const handleCommentImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                setCommentImageFile(file)
                setCommentImage(reader.result)
            };
            reader.readAsDataURL(file);
        }
    };
    async function handleCommentPost() {
        setShowLoader(true)
        if (commentImageFile) {
            const url = await handleImageUpload(commentImageFile, `feedImages/${Date.now()}`, "image/jpeg")
            //console.log("uploaded", url)
            getCommentPost(commentId, participateId, url, "0")
        } else {
            getCommentPost(commentId, participateId, "", "")
        }
    };

    async function getCommentPost(commentId, participateId, imagePath, mediaType) {
        try {
            const res = await apiHandler(CHALLENGE_ADD_PERTICIPANTS_COMMENT, "POST", {
                comment: postComment,
                participate_id: participateId,
                comment_id: commentId,
                image_path: imagePath,
                media_type: mediaType,
                // thumb: "",
                // audio_id: ""
            })
            if (res.data?.response?.status === 200) {
                setShowCommentBox(false)
                setRefreshPCList(true)
                Swal.fire({
                    title: 'Success',
                    text: 'Your comment post successfully',
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setPostComment("")
            setShowPicker(false)
            setCommentImageFile("")
            setCommentImage("")
            setShowLoader(false)
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader}/>
            {
                commentImage ?
                    <div>
                        <hr />
                        <div className="col-md-2 col-sm-12 col-lg-2" style={{ marginTop: "10px" }}>
                            <img style={{ height: 70, width: 70, marginRight: "17px", }} loading="lazy" src={commentImage}
                                alt="gallary" className="img-fluid" />
                            {
                                participateId ?
                                    <Link style={{ position: "absolute", marginTop: "-80px", marginLeft: "43px", }}
                                        onClick={() => {
                                            setCommentImage("")
                                            setCommentImageFile("")
                                        }}>
                                        <img style={{ height: 25, width: 25 }} src={cross} />
                                    </Link> :
                                    <Link style={{ position: "absolute", marginTop: "-9px", marginLeft: "-30px", }}
                                        onClick={() => {
                                            setCommentImage("")
                                            setCommentImageFile("")
                                        }}>
                                        <img style={{ height: 25, width: 25 }} src={cross} />
                                    </Link>
                            }
                        </div>
                    </div>
                    : null
            }
            <form className="comment-text d-flex align-items-center mt-3" >
                <input className="form-control rounded" placeholder="Enter Your Comment"
                    value={postComment} onChange={(e) => setPostComment(e.target.value)} />
                <div className="comment-attagement d-flex" style={{ alignItems: "center" }}>
                    <Link to="#" className="material-symbols-outlined  me-3"
                        onClick={() => {
                            setShowPicker((val) => !val)
                        }}>sentiment_satisfied</Link>
                    <span style={{ marginTop: "7px" }}>
                        <label style={{ cursor: "pointer" }} htmlFor="imageUpload2" className="material-symbols-outlined  me-3">photo_camera</label>
                        <input className="file-upload"
                            id="imageUpload2" type="file" accept="image/*"
                            multiple="multiple"
                            onChange={handleCommentImageChange} />
                    </span>

                    <button type="button" className="btn btn-primary" style={{ height: "38px" }} onClick={() =>
                        handleCommentPost()
                    } >
                        <span className="material-symbols-outlined">send</span>
                    </button>
                </div>
            </form>
            {showPicker ?
                <EmojiPicker pickerStyle={{ width: "100%" }} onEmojiClick={(emojiObject) => setPostComment((prevMsg) => prevMsg + emojiObject.emoji)} /> : null
            }

        </>
    )
}

export default ChallengeCommentBox
