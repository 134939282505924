import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import DOMPurify from "isomorphic-dompurify";
import { apiHandler } from '../../../api/apihandler';
import { CMS_DETAILS } from "../../../api/constants";

// images
import loader from '../../../assets/images/page-img/page-load-loader.gif'

const PrivacyPolicy = () => {
    const [cmsDetailsData, setCMSDetailsData] = useState('');
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    useEffect(() => {
        getCMSDetails("privacy-policy")
    }, [])

    async function getCMSDetails(slug) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(CMS_DETAILS, "POST", {
                slug: slug
            })
            if (res.data?.response?.status === 200) {
                setCMSDetailsData(res?.data?.data?.cmsPage)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }

    return (
        <>
            <Container>
                <Container fluid style={{ marginTop: "20px" }}>
                    <Row>
                        <Col lg="12">
                            {
                                cmsDetailsData?.cms_page_body?.length > 0 ? cmsDetailsData?.cms_page_body?.map((elm, i) => (
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h3 className="card-title">{elm?.heading}</h3>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(elm?.description) }} />
                                        </Card.Body>
                                    </Card>
                                )) : null
                            }
                            {/* <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Why do we use it?</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).  </p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Where does it come from?</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Where can I get some?</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Why do we use it?</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Why do we use it?</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Why do we use it?</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Why do we use it?</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Why do we use it?</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).  </p>
                                </Card.Body>
                            </Card> */}
                        </Col>
                        {
                            showMoreDataLoader ?
                                <div className="col-sm-12 text-center">
                                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                                </div> : null
                        }
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default PrivacyPolicy
