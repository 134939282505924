import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button, Image } from 'react-bootstrap'
import { Link, useNavigate, Route, useSearchParams } from 'react-router-dom'


//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
// import 'swiper/components/navigation/navigation.scss';

//img
import logo from '../../../assets/images/logo.png'
import login1 from '../../../assets/images/login/1.png'
import login2 from '../../../assets/images/login/2.png'
import login3 from '../../../assets/images/login/3.png'

//import {BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";

import { ToastProvider, useToasts } from 'react-toast-notifications';
import Swal from 'sweetalert2'
//import { useRouter } from 'next/navigation'
import { apiHandler } from '../../../api/apihandler';
import { MATCH_EMAIL_OTP, RESEND_OTP, SIGNUP, VERIFY_EMAIL } from "../../../api/constants";
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setUserData } from '../../../store/setting/actions'

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignUp = () => {
   //let history = useNavigate()
   const navigate = useNavigate();
   const { addToast } = useToasts();
   const dispatch = useDispatch()
   const [step, setStep] = useState(1)
   const [email, setEmail] = useState('')
   const [otp, setOtp] = useState('')
   const [firstName, setFirstName] = useState('')
   const [lastName, setLastName] = useState('')
   const [password, setPassword] = useState('')
   const [confirmPass, setConfirmPass] = useState('')
   const [mobile, setMobile] = useState('')
   const [dob, setDOB] = useState('')
   const [gender, setGender] = useState('0')
   const [referalCode, setReferalCode] = useState('')
   const [interestID, setInterestID] = useState('1')
   const [verifyEmailID, setVerifyEmailID] = useState('')
   const [pType, setPType] = useState('password');
   const [pIcon, setPIcon] = useState(eyeOff);
   const [cpType, setCPType] = useState('password');
   const [cpIcon, setCPIcon] = useState(eyeOff);
   const [searchParams, setSearchParams] = useSearchParams();

   const [userType, setUserType] = useState(null);
   // const [userTypeId, setUserTypeId] = useState(null);
   const [schoolName, setSchoolName] = useState('');
   const [schoolUrl, setSchoolUrl] = useState('');

   const genderArray = [
      {
         id: 0,
         name: "Male"
      },
      {
         id: 1,
         name: "Female"
      },
      {
         id: 2,
         name: "Other"
      }
   ]

   const [minutes, setMinutes] = useState(0);
   const [seconds, setSeconds] = useState(0);

   useEffect(() => {
      setReferalCode(searchParams.get("ref-id"))
      setUserType(searchParams.get("type"))
      const interval = setInterval(() => {
         if (seconds > 0) {
            setSeconds(seconds - 1);
         }

         if (seconds === 0) {
            if (minutes === 0) {
               clearInterval(interval);
            } else {
               setSeconds(59);
               setMinutes(minutes - 1);
            }
         }
      }, 1000);

      return () => {
         clearInterval(interval);
      };
   });

   async function enterKeyPressed(event) {
      if (event.keyCode === 13) {
         event.preventDefault();
         //console.log("enter key work")
      }
   }

   const handlePToggle = () => {
      if (pType === 'password') {
         setPIcon(eye);
         setPType('text')
      } else {
         setPIcon(eyeOff)
         setPType('password')
      }
   }

   const handleCPToggle = () => {
      if (cpType === 'password') {
         setCPIcon(eye);
         setCPType('text')
      } else {
         setCPIcon(eyeOff)
         setCPType('password')
      }
   }

   const handleGenderChange = (e) => {
      e.preventDefault();
      setGender(e.target.value);
   };

   const handleSubmit = () => {
      //alert(`The email you entered was: ${email}`)
      if (step == 1) {
         if (email.toLowerCase()
            .match(
               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
            verifyEmail();
         } else {
            addToast("Enter valid email!", { appearance: 'error' });
         }
         //verifyEmail();
      } else if (step == 2) {
         matchEmailOtp();
      } else if (step == 3) {
         //console.log("working signup")
         if (password != confirmPass) {
            addToast("Password did not match!", { appearance: 'error' });
         } else if (password == '') {
            addToast("Enter Password!", { appearance: 'error' });
         } else if (firstName == '') {
            addToast("Enter First Name!", { appearance: 'error' });
         } else if (lastName == '') {
            addToast("Enter Last Name!", { appearance: 'error' });
         } else if (dob == '') {
            addToast("Enter Last Name!", { appearance: 'error' });
         } else if (userType == '0') {
            if (schoolName == '') {
               addToast("Enter School Name!", { appearance: 'error' });
            } else if (schoolUrl == '') {
               addToast("Enter School URL!", { appearance: 'error' });
            }
         } else {
            signUp();
         }
      }
   }

   async function verifyEmail() {
      const res = await apiHandler(VERIFY_EMAIL, "POST", {
         email: email
      })
      if (res.status === 200) {
         setStep(2)
      } else {
         addToast(res?.response?.data?.data?.message, { appearance: 'error' });
      }
   }

   async function reSendOTPApi() {
      const res = await apiHandler(RESEND_OTP, "POST", {
         email: email
      })
      if (res.data?.response?.status === 200) {
         addToast("OTP has been sent to your " + email, { appearance: 'success' });
         //console.log("reSendOTP", res?.data)
         setMinutes(0)
         setSeconds(10)
      } else {
         //console.log("login failed")
         addToast(res?.response?.data?.data?.message, { appearance: 'error' });
      }
   }

   async function matchEmailOtp() {
      const res = await apiHandler(MATCH_EMAIL_OTP, "POST", {
         email: email,
         otp: otp
      })
      if (res.status === 200) {
         setVerifyEmailID(res?.data?.data?.verifyemail?.id)
         setStep(3)
      } else {
         addToast(res?.response?.data?.data?.message, { appearance: 'error' });
      }
   }

   async function signUp() {
      let res
      if (userType === "0") {
         res = await apiHandler(SIGNUP, "POST", {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            password_confirmation: confirmPass,
            mobile_no: mobile,
            dob: dob,
            gender: gender,
            interest_id: interestID,
            verifyemail_id: verifyEmailID,
            ref_code: referalCode,
            type: userType,
            school_name: schoolName,
            school_url: schoolUrl
         })
      } else {
         res = await apiHandler(SIGNUP, "POST", {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            password_confirmation: confirmPass,
            mobile_no: mobile,
            dob: dob,
            gender: gender,
            interest_id: interestID,
            verifyemail_id: verifyEmailID,
            ref_code: referalCode,
            type: userType
         })
      }

      if (res.status === 200) {
         //console.log("OTP verification", res?.data?.data)
         localStorage.setItem('uzoneAuthToken', res?.data?.data?.token?.token)
         dispatch(setUserData(JSON.stringify(res?.data?.data?.user)));
         //addToast("Sign up Successfully.");
         navigate('/')
      } else if (res.status === 201) {
         //console.log("OTP verification", res?.data?.data)
         localStorage.setItem('uzoneAuthToken', res?.data?.data?.token?.token)
         dispatch(setUserData(JSON.stringify(res?.data?.data?.user)));
         //addToast("Sign up Successfully.");
         navigate('/')
      } else {
         Swal.fire({
            title: 'Warning!',
            text: res?.response?.data?.data?.message,
            icon: 'Warning',
            confirmButtonText: 'Okay'
         })
         navigate('/auth/sign-in')
      }
   }

   return (
      <>
         <ToastProvider>
            <section className="sign-in-page">
               <div id="container-inside">
                  <div id="circle-small"></div>
                  <div id="circle-medium"></div>
                  <div id="circle-large"></div>
                  <div id="circle-xlarge"></div>
                  <div id="circle-xxlarge"></div>
               </div>
               <Container className="p-0" style={{ overflowY: "scroll", overflowX: "hidden" }}>
                  <Row className="no-gutters">
                     <Col md="6" className="text-center pt-5">
                        <div className="sign-in-detail text-white">
                           <Link className="sign-in-logo mb-5" to="/dashboard/app/home-page"><Image src={logo} className="img-fluid" alt="logo" /></Link>
                           <div className="sign-slider overflow-hidden">
                              <Swiper
                                 spaceBetween={30}
                                 centeredSlides={true}
                                 autoplay={{
                                    "delay": 2000,
                                    "disableOnInteraction": false
                                 }}
                                 className="list-inline m-0 p-0 ">
                                 <SwiperSlide>
                                    <Image src={login1} className="img-fluid mb-4" alt="logo" />
                                    <h4 className="mb-1 text-white">Find new friends</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                 </SwiperSlide>
                                 <SwiperSlide>
                                    <Image src={login2} className="img-fluid mb-4" alt="logo" />
                                    <h4 className="mb-1 text-white">Connect with the world</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                 </SwiperSlide>
                                 <SwiperSlide>
                                    <Image src={login3} className="img-fluid mb-4" alt="logo" />
                                    <h4 className="mb-1 text-white">Create new events</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                 </SwiperSlide>
                              </Swiper>
                           </div>
                        </div>
                     </Col>
                     <Col md="6" className="bg-white pt-5 pt-5 pb-lg-0 pb-5">
                        <div className="sign-in-from">
                           <h1 className="mb-0">Sign Up</h1>
                           <p>Enter your details to create a new account in a few steps</p>
                           <Form className="mt-4">
                              {step == 1 && <Form.Group className="form-group" >
                                 <Form.Label>Your Email Address *</Form.Label>
                                 <Form.Control type="email" onKeyDown={enterKeyPressed} className="mb-0" id="exampleInputEmail1"
                                    placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                              </Form.Group>}
                              {step == 2 && <Form.Group className="form-group">
                                 <Form.Label>OTP *</Form.Label>
                                 <Form.Control type="text" onKeyDown={enterKeyPressed} className="mb-0" id="exampleInputEmail2"
                                    placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
                              </Form.Group>}
                              {step == 3 && <>
                                 <Row>
                                    <Col md="6" className="mb-1">
                                       <Form.Group className="form-group">
                                          <Form.Label>First name *</Form.Label>
                                          <Form.Control type="text" onKeyDown={enterKeyPressed} className="mb-0" id="exampleInputEmail3" placeholder="First name"
                                             value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                       </Form.Group>
                                    </Col>
                                    <Col md="6" className="mb-1">
                                       <Form.Group className="form-group">
                                          <Form.Label>Last name *</Form.Label>
                                          <Form.Control type="text" onKeyDown={enterKeyPressed} className="mb-0" id="exampleInputEmail4" placeholder="Last name"
                                             value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                       </Form.Group>
                                    </Col>
                                    <Col md="12" className="mb-1">
                                       <Form.Group className="form-group">
                                          <Form.Label>Mobile Number *</Form.Label>
                                          <Form.Control type="text" onKeyDown={enterKeyPressed} className="mb-0" id="exampleInputEmail5" placeholder="Mobile Number"
                                             value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                       </Form.Group>
                                    </Col>
                                    <Col md="6" className="mb-1">
                                       <Form.Group className="form-group">
                                          <Form.Label>Date of Birth *</Form.Label>
                                          <Form.Control type="date" onKeyDown={enterKeyPressed} className="mb-0" id="exampleInputEmail6" placeholder="Date of Birth"
                                             max={new Date().toISOString().split("T")[0]}
                                             //defaultValue={setDOB(date_variable)}
                                             value={dob} onChange={(e) => setDOB(e.target.value)} />
                                       </Form.Group>
                                    </Col>
                                    <Col md="6" className="mb-1">
                                       <Form.Group className="form-group">
                                          <Form.Label>Gender *</Form.Label>
                                          <Form.Select id="validationDefault04" required value={gender} onChange={handleGenderChange}>
                                             {genderArray.map((elm, i) => (
                                                <option value={elm?.id}>
                                                   {elm?.name}
                                                </option>
                                             ))}
                                          </Form.Select>
                                       </Form.Group>
                                    </Col>
                                    <Col md="6" className="mb-1">
                                       <Form.Group className="form-group">
                                          <Form.Label>Password *</Form.Label>
                                          <div style={{ position: "relative", marginBottom: 60, display: "flex", justifyContent: "end" }}>
                                             <Form.Control type={pType} onKeyDown={enterKeyPressed} style={{ position: "absolute", paddingRight: 40 }} className="mb-0 d-inline-block" id="exampleInputPassword6" placeholder="Password"
                                                value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                             <Icon style={{ position: "absolute", marginTop: 7, marginRight: 8 }} icon={pIcon} size={20} onClick={handlePToggle} />
                                          </div>
                                       </Form.Group>
                                    </Col>
                                    <Col md="6" className="mb-1">
                                       <Form.Group className="form-group">
                                          <Form.Label>Confirm Password *</Form.Label>
                                          <div style={{ position: "relative", marginBottom: 65, display: "flex", justifyContent: "end" }}>
                                             <Form.Control type={cpType} onKeyDown={enterKeyPressed} style={{ position: "absolute", paddingRight: 40 }} className="mb-0 d-inline-block" id="exampleInputPassword7" placeholder="Confirm password"
                                                value={confirmPass} onChange={(e) => { setConfirmPass(e.target.value) }} />
                                             <Icon style={{ position: "absolute", marginTop: 7, marginRight: 8 }} icon={cpIcon} size={20} onClick={handleCPToggle} />
                                          </div>
                                       </Form.Group>
                                    </Col>
                                    <Col md="12" className="mb-1">
                                       <Form.Group className="form-group">
                                          <Form.Label>If you have referal code please enter</Form.Label>
                                          <Form.Control type="text" onKeyDown={enterKeyPressed} className="mb-0" id="exampleInputEmail4" placeholder="Referal code"
                                             value={referalCode} onChange={(e) => setReferalCode(e.target.value)} />
                                       </Form.Group>
                                    </Col>
                                    {/*<Col md="12" className="mb-1">
                                       <Form.Group className="form-group">
                                          <Form.Label>You are a</Form.Label>
                                          <div>
                                             <Form.Check className="d-inline-block mt-0 pt-0">
                                                <Form.Check.Input type="checkbox" checked={userTypeId === "customCheck1"? true: false} 
                                                className="me-2" id="customCheck1" onChange={(e) =>{
                                                   setUserType(e.target.value)
                                                   setUserTypeId("customCheck1")
                                                }}/>
                                                <Form.Check.Label>School</Form.Check.Label>
                                             </Form.Check>
                                             <Form.Check className="d-inline-block mt-0 pt-0 ms-2">
                                                <Form.Check.Input type="checkbox" checked={userTypeId === "customCheck2"? true: false}
                                                className="me-2" id="customCheck2" onChange={(e) =>{
                                                   setUserType(e.target.value)
                                                   setUserTypeId("customCheck2")
                                                }}/>
                                                <Form.Check.Label>Teacher</Form.Check.Label>
                                             </Form.Check>
                                             <Form.Check className="d-inline-block mt-0 pt-0 ms-2">
                                                <Form.Check.Input type="checkbox" checked={userTypeId === "customCheck3"? true: false}
                                                className="me-2" id="customCheck3" onChange={(e) =>{
                                                   setUserType(e.target.value)
                                                   setUserTypeId("customCheck3")
                                                }}/>
                                                <Form.Check.Label>Student</Form.Check.Label>
                                             </Form.Check>
                                          </div>
                                       </Form.Group>
                                    </Col>*/}
                                    {
                                       // userTypeId === "customCheck1" ?
                                       userType === "0" ?
                                          <>
                                             <Col md="6" className="mb-1">
                                                <Form.Group className="form-group">
                                                   <Form.Label>School name *</Form.Label>
                                                   <Form.Control type="text" onKeyDown={enterKeyPressed} className="mb-0" id="exampleInputEmail3" placeholder="School name"
                                                      value={schoolName} onChange={(e) => setSchoolName(e.target.value)} />
                                                </Form.Group>
                                             </Col>
                                             <Col md="6" className="mb-1">
                                                <Form.Group className="form-group">
                                                   <Form.Label>School URL *</Form.Label>
                                                   <Form.Control type="text" onKeyDown={enterKeyPressed} className="mb-0" id="exampleInputEmail4" placeholder="School URL"
                                                      value={schoolUrl} onChange={(e) => setSchoolUrl(e.target.value)} />
                                                </Form.Group>
                                             </Col>
                                          </> : null
                                    }
                                 </Row>
                                 {/* <Form.Group className="form-group">
                                    <Form.Label>First name *</Form.Label>
                                    <Form.Control type="text" className="mb-0" id="exampleInputEmail3" placeholder="First name"
                                       value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                 </Form.Group>
                                 <Form.Group className="form-group">
                                    <Form.Label>Last name *</Form.Label>
                                    <Form.Control type="text" className="mb-0" id="exampleInputEmail4" placeholder="Last name"
                                       value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                 </Form.Group>
                                 <Form.Group className="form-group">
                                    <Form.Label>Mobile Number *</Form.Label>
                                    <Form.Control type="text" className="mb-0" id="exampleInputEmail5" placeholder="Mobile Number"
                                       value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                 </Form.Group>
                                 <Form.Group className="form-group">
                                    <Form.Label>Date of Birth *</Form.Label>
                                    <Form.Control type="date" className="mb-0" id="exampleInputEmail6" placeholder="Date of Birth"
                                       max={new Date().toISOString().split("T")[0]}
                                       //defaultValue={setDOB(date_variable)}
                                       value={dob} onChange={(e) => setDOB(e.target.value)} />
                                 </Form.Group>
                                 <Form.Group className="form-group">
                                    <Form.Label>Gender *</Form.Label>
                                    <Form.Select id="validationDefault04" required>
                                       {genderArray.map((elm, i) => (
                                          <option value={elm?.id}>
                                             {elm?.name}
                                          </option>
                                       ))}
                                    </Form.Select>
                                 </Form.Group>
                                 <Form.Group className="form-group">
                                    <Form.Label>Password *</Form.Label>
                                    <div style={{ position: "relative", marginBottom: 60, display: "flex", justifyContent: "end" }}>
                                       <Form.Control type={pType} style={{ position: "absolute", paddingRight: 40 }} className="mb-0 d-inline-block" id="exampleInputPassword6" placeholder="Password"
                                          value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                       <Icon style={{ position: "absolute", marginTop: 7, marginRight: 8 }} icon={pIcon} size={20} onClick={handlePToggle} />
                                    </div>
                                 </Form.Group>
                                 <Form.Group className="form-group">
                                    <Form.Label>Confirm Password *</Form.Label>
                                    <div style={{ position: "relative", marginBottom: 65, display: "flex", justifyContent: "end" }}>
                                       <Form.Control type={cpType} style={{ position: "absolute", paddingRight: 40 }} className="mb-0 d-inline-block" id="exampleInputPassword7" placeholder="Confirm Password"
                                          value={confirmPass} onChange={(e) => { setConfirmPass(e.target.value) }} />
                                       <Icon style={{ position: "absolute", marginTop: 7, marginRight: 8 }} icon={cpIcon} size={20} onClick={handleCPToggle} />
                                    </div>
                                 </Form.Group> */}
                              </>}
                              <div className="d-inline-block w-100">
                                 {/* <Form.Check className="d-inline-block mt-2 pt-1">
                                    <Form.Check.Input type="checkbox" className="me-2" id="customCheck1" />
                                    <Form.Check.Label>I accept <Link to="#">Terms and Conditions</Link></Form.Check.Label>
                                 </Form.Check> */}
                                 {
                                    step === 2 ?
                                       <>
                                          {seconds > 0 || minutes > 0 ? (
                                             <span className="dark-color d-inline-block line-height-2">
                                                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                {seconds < 10 ? `0${seconds}` : seconds}
                                             </span>
                                          ) : (
                                             <span className="dark-color d-inline-block line-height-2">Didn't recieve code?
                                                <Link to="#" onClick={() => reSendOTPApi()}> Resend OTP</Link>
                                             </span>
                                          )}
                                       </> : null
                                 }
                                 <Button type="button" className="btn-primary float-end" onClick={() => {
                                    //history.push('/')
                                    handleSubmit()
                                 }}>
                                    {step == 3 ? 'Register' : 'Next'}
                                 </Button>
                              </div>
                              {/* <Form.Group className="form-group">
                              <Form.Label>Your Full Name</Form.Label>
                              <Form.Control type="email" className="mb-0" id="exampleInputEmail1" placeholder="Your Full Name" />
                           </Form.Group>
                           <Form.Group className="form-group">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control type="email" className="mb-0" id="exampleInputEmail2" placeholder="Enter email" />
                           </Form.Group>
                           <Form.Group className="form-group">
                              <Form.Label>Password</Form.Label>
                              <Form.Control type="password" className="mb-0" id="exampleInputPassword1" placeholder="Password" />
                           </Form.Group>
                           <div className="d-inline-block w-100">
                              <Form.Check className="d-inline-block mt-2 pt-1">
                                 <Form.Check.Input type="checkbox" className="me-2" id="customCheck1" />
                                 <Form.Check.Label>I accept <Link to="#">Terms and Conditions</Link></Form.Check.Label>
                              </Form.Check>
                              <Button type="button" className="btn-primary float-end" onClick={() => history.push('/')}>Sign Up</Button>
                           </div> */}
                              <div className="sign-info">
                                 <span className="dark-color d-inline-block line-height-2">Already Have Account ? <Link to="/auth/sign-in">Log In</Link></span>
                                 {/* <ul className="iq-social-media">
                                    <li>
                                       <Link to="#">
                                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 16 16" fill="currentColor">
                                             <title>facebook</title>
                                             <path d="M15 16h-14c-0.553 0-1-0.447-1-1v-14c0-0.553 0.447-1 1-1h14c0.553 0 1 0.447 1 1v14c0 0.553-0.447 1-1 1zM14 2h-12v12h12v-12zM8 6c0-1.103 0.912-2 1.857-2h1.143v2h-1v1h1v2h-1v3h-2v-3h-1v-2h1v-1z"></path>
                                          </svg>
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#">
                                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="currentColor">
                                             <title>twitter</title>
                                             <path d="M20.833 5.262c-0.186 0.242-0.391 0.475-0.616 0.696-0.233 0.232-0.347 0.567-0.278 0.908 0.037 0.182 0.060 0.404 0.061 0.634 0 5.256-2.429 8.971-5.81 10.898-2.647 1.509-5.938 1.955-9.222 1.12 1.245-0.361 2.46-0.921 3.593-1.69 0.147-0.099 0.273-0.243 0.352-0.421 0.224-0.505-0.003-1.096-0.508-1.32-2.774-1.233-4.13-2.931-4.769-4.593-0.417-1.084-0.546-2.198-0.52-3.227 0.021-0.811 0.138-1.56 0.278-2.182 0.394 0.343 0.803 0.706 1.235 1.038 2.051 1.577 4.624 2.479 7.395 2.407 0.543-0.015 0.976-0.457 0.976-1v-1.011c-0.002-0.179 0.009-0.357 0.034-0.533 0.113-0.806 0.504-1.569 1.162-2.141 0.725-0.631 1.636-0.908 2.526-0.846s1.753 0.463 2.384 1.188c0.252 0.286 0.649 0.416 1.033 0.304 0.231-0.067 0.463-0.143 0.695-0.228zM22.424 2.183c-0.74 0.522-1.523 0.926-2.287 1.205-0.931-0.836-2.091-1.302-3.276-1.385-1.398-0.097-2.836 0.339-3.977 1.332-1.036 0.901-1.652 2.108-1.83 3.372-0.037 0.265-0.055 0.532-0.054 0.8-1.922-0.142-3.693-0.85-5.15-1.97-0.775-0.596-1.462-1.309-2.034-2.116-0.32-0.45-0.944-0.557-1.394-0.237-0.154 0.109-0.267 0.253-0.335 0.409 0 0-0.132 0.299-0.285 0.76-0.112 0.337-0.241 0.775-0.357 1.29-0.163 0.722-0.302 1.602-0.326 2.571-0.031 1.227 0.12 2.612 0.652 3.996 0.683 1.775 1.966 3.478 4.147 4.823-1.569 0.726-3.245 1.039-4.873 0.967-0.552-0.024-1.019 0.403-1.043 0.955-0.017 0.389 0.19 0.736 0.513 0.918 4.905 2.725 10.426 2.678 14.666 0.261 4.040-2.301 6.819-6.7 6.819-12.634-0.001-0.167-0.008-0.33-0.023-0.489 1.006-1.115 1.676-2.429 1.996-3.781 0.127-0.537-0.206-1.076-0.743-1.203-0.29-0.069-0.58-0.003-0.807 0.156z"></path>
                                          </svg>
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#">
                                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 28" fill="currentColor" >
                                             <title>instagram</title>
                                             <path d="M16 14c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM18.156 14c0 3.406-2.75 6.156-6.156 6.156s-6.156-2.75-6.156-6.156 2.75-6.156 6.156-6.156 6.156 2.75 6.156 6.156zM19.844 7.594c0 0.797-0.641 1.437-1.437 1.437s-1.437-0.641-1.437-1.437 0.641-1.437 1.437-1.437 1.437 0.641 1.437 1.437zM12 4.156c-1.75 0-5.5-0.141-7.078 0.484-0.547 0.219-0.953 0.484-1.375 0.906s-0.688 0.828-0.906 1.375c-0.625 1.578-0.484 5.328-0.484 7.078s-0.141 5.5 0.484 7.078c0.219 0.547 0.484 0.953 0.906 1.375s0.828 0.688 1.375 0.906c1.578 0.625 5.328 0.484 7.078 0.484s5.5 0.141 7.078-0.484c0.547-0.219 0.953-0.484 1.375-0.906s0.688-0.828 0.906-1.375c0.625-1.578 0.484-5.328 0.484-7.078s0.141-5.5-0.484-7.078c-0.219-0.547-0.484-0.953-0.906-1.375s-0.828-0.688-1.375-0.906c-1.578-0.625-5.328-0.484-7.078-0.484zM24 14c0 1.656 0.016 3.297-0.078 4.953-0.094 1.922-0.531 3.625-1.937 5.031s-3.109 1.844-5.031 1.937c-1.656 0.094-3.297 0.078-4.953 0.078s-3.297 0.016-4.953-0.078c-1.922-0.094-3.625-0.531-5.031-1.937s-1.844-3.109-1.937-5.031c-0.094-1.656-0.078-3.297-0.078-4.953s-0.016-3.297 0.078-4.953c0.094-1.922 0.531-3.625 1.937-5.031s3.109-1.844 5.031-1.937c1.656-0.094 3.297-0.078 4.953-0.078s3.297-0.016 4.953 0.078c1.922 0.094 3.625 0.531 5.031 1.937s1.844 3.109 1.937 5.031c0.094 1.656 0.078 3.297 0.078 4.953z" ></path>
                                          </svg>
                                       </Link>
                                    </li>
                                 </ul> */}
                              </div>
                           </Form>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </section>
         </ToastProvider>
      </>
   )
}

export default SignUp
