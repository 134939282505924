import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Container, Dropdown, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'
import { apiHandler } from '../../../api/apihandler';
import { FEEDS_LIST } from "../../../api/constants";
import LoaderModal from '../../../components/loaderModal'

//image
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import StoriesSection from '../../../components/stories/storiesSection'
import CreatePostModal from '../../../components/createPost/createPostModal'
//import FeedsList from '../../components/feeds/feedsList'
import PeopleYouMayKnowCard from '../../../components/peopleYouMayKnow/peopleYouMayKnowCard'
import FeedPostCard from '../../../components/feeds/feedPostCard'
import { InView } from "react-intersection-observer";
import NoDataFound from '../../../components/noDataFound'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import { setPostModalShow, setCreatePostType } from '../../../store/setting/actions'
import CreatePostInputBox from '../../../components/createPost/createPostInputBox'

const Announcement = () => {
    const dispatch = useDispatch()
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [feedsList, setFeedsList] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [showLoader, setShowLoader] = useState(false);
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    useEffect(() => {
        if (profileData?.user_type === 2) {
            dispatch(setCreatePostType("6"))
        }
        getFeedsList(1)
    }, [])

    const loadMore = (inView) => {
        if (inView) {
            if (page < maxPage) {
                getFeedsList(page + 1)
                //console.log("scroll working...", page + 1)
                setPage(page + 1)
            }
        }
    };

    function refreshApi() {
        setPage(1)
        getFeedsList(1)
    }

    async function getFeedsList(pageVal) {
        setShowMoreDataLoader(true)
        //setShowLoader(true)
        try {
            const res = await apiHandler(FEEDS_LIST, "POST", {
                type: 6,
                page: pageVal,
                limit: 10,
            })
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.feeds?.meta?.last_page)
                if (pageVal === 1) {
                    setFeedsList(res?.data?.data?.feeds?.data)
                } else {
                    setFeedsList(feedsList.concat(res?.data?.data?.feeds?.data))
                }
            } else {
                //navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowMoreDataLoader(false)
            //setShowLoader(false)
        }
    }


    return (
        <>
            <LoaderModal showLoader={showLoader} />

            <div className="content-page scroller">
                <Container>
                    <Row>
                        <Col lg={8} className="row m-0 p-0">
                            <Col sm={12}>
                                {
                                    profileData?.user_type === 2 ?
                                        // <CreatePostModal postType={"6"} refreshApi={refreshApi} eventId={""} /> :
                                        <CreatePostInputBox postType={"6"} refreshApi={refreshApi} eventId={""} /> :
                                        null
                                }
                            </Col>
                            <Col sm={12}>
                                {
                                    feedsList?.length > 0 ? feedsList?.map((elm, i) => (
                                        <FeedPostCard elm={elm} getFeedsList={refreshApi} key={elm?.id} feedDetails={false} />
                                    )) : <NoDataFound />
                                }
                                {
                                    showMoreDataLoader ?
                                        <div className="col-sm-12 text-center">
                                            <img src={loader} alt="loader" style={{ height: "100px" }} />
                                        </div> : null
                                }
                                <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                            </Col>
                        </Col>
                        <Col lg={4}>
                            {
                                profileData?.user_type === 2 ? null :
                                    <>
                                        <StoriesSection />
                                        <PeopleYouMayKnowCard />
                                    </>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Announcement
