import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter, Form } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { EVENT_ADD_MEMBER, EVENT_FIND_MEMBERS, EVENT_INVITATION, FOLLOWER_LIST, GET_CONNECTED_USERS, GROUP_ADD_MEMBER, GROUP_DELETE, GROUP_MEMBERS, GROUP_REMOVE_MEMBER } from '../../api/constants'
import Swal from 'sweetalert2';
import Loader from "react-js-loader";
import Cropper from 'react-easy-crop'
import { InView } from "react-intersection-observer";

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

import getCroppedImg from '../../utilities/crop-image'
//import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import img7 from '../../assets/images/page-img/profile-bg7.jpg'
import loader from '../../assets/images/page-img/page-load-loader.gif'
import NoDataFound from '../noDataFound';

const EventParticipateModal = ({ showParticipateModal, setShowParticipateModal, eventDetails }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);
    const [userListForAddNewMember, setUserListForAddNewMember] = useState([]);
    const [searchContent, setSearchContent] = useState('');
    const [inviteEmail, setInviteEmail] = useState('');
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);

    const [isMyStudent, setIsMyStudent] = useState(true);
    const [followerList, setFollowerList] = useState([]);
    const [myStudentPage, setMyStudentPage] = useState(1);
    const [myStudentMaxPage, setMyStudentMaxPage] = useState(1);


    useEffect(() => {
        if (isMyStudent) {
            getMyStudents("", 1)
        } else {
            getUserSearchApi("", 1)
        }
    }, [])

    useEffect(() => {
        if (isMyStudent) {
            getMyStudents(searchContent, 1)
        } else {
            getUserSearchApi(searchContent, 1)
        }
    }, [isMyStudent, searchContent])

    const loadMoreMyStudent = (inView) => {
        if (inView) {
            if (myStudentPage < myStudentMaxPage) {
                getMyStudents(myStudentPage + 1)
                //console.log("scroll working...", page + 1)
                setMyStudentPage(myStudentPage + 1)
            }
        }
    };

    async function getMyStudents(searchData, pageVal) {
        setShowMoreDataLoader(true)
        //setShowLoader(true)
        try {
            const res = await apiHandler(EVENT_FIND_MEMBERS, "POST", {
                is_follower: 1,
                event_id: eventDetails?.id,
                search_text: searchData,
                page: pageVal,
                limit: 10
            })
            if (res.data?.response?.status === 200) {
                setMyStudentMaxPage(res?.data?.data?.users?.meta?.last_page)
                if (pageVal === 1) {
                    setFollowerList(res?.data?.data?.users?.data)
                } else {
                    setFollowerList(followerList.concat(res?.data?.data?.users?.data))
                }
            } else {
                //   navigate('/auth/sign-in')
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
            //setShowLoader(false)
        }
    }

    async function getUserSearchApi(searchData, currentPage) {
        try {
            const res = await apiHandler(EVENT_FIND_MEMBERS, 'POST', {
                event_id: eventDetails?.id,
                search_text: searchData,
                page: currentPage,
                limit: 10
            });
            if (res.data?.response?.status === 200) {
                if (currentPage === 1) {
                    setMaxPage(res?.data?.data?.users?.meta?.last_page)
                    setUserListForAddNewMember(res?.data?.data?.users?.data)
                } else {
                    setMaxPage(res?.data?.data?.users?.meta?.last_page)
                    setUserListForAddNewMember(userListForAddNewMember.concat(res?.data?.data?.users?.data))
                }
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function addRemoveMember(eventId, memberId, index, addedVal) {
        try {
            const res = await apiHandler(EVENT_ADD_MEMBER, 'POST', {
                event_id: eventId,
                member_id: memberId
            });
            if (res.data?.response?.status === 200) {
                let temporaryarray = userListForAddNewMember.slice();
                temporaryarray[index]['is_added'] = addedVal;
                setUserListForAddNewMember(temporaryarray);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function eventInvitationApi(eventId) {
        try {
            const res = await apiHandler(EVENT_INVITATION, 'POST', {
                event_id: eventId,
                email: inviteEmail
            });
            if (res.data?.response?.status === 200) {
                Swal.fire({
                    title: 'Success',
                    text: 'Your invitation send successfully',
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }


    return (
        <>
            <Modal scrollable={true} show={showParticipateModal} onHide={() => setShowParticipateModal(false)} size="md" style={{ marginTop: 80 }}>
                <Modal.Header className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        <div className="w-100">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div className="me-3">
                                    <img loading="lazy" className="rounded-circle  avatar-60"
                                        src={eventDetails?.image ? eventDetails?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                </div>
                                <div>
                                    <h4 className="mb-0 d-inline-block"><Link to="#">{eventDetails?.name}</Link></h4>
                                    <p className="mb-0">+Add members for event</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-secondary lh-1" onClick={() => setShowParticipateModal(false)} ><span className="material-symbols-outlined">close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="post-item" style={{ maxHeight: "500px" }}>
                        <div className="iq-todo-page mb-3">
                            <Form className="position-relative">
                                <Form.Group className="form-group mb-0">
                                    <Form.Control
                                        type="text"
                                        className="form-control todo-search bg-soft-primary"
                                        id="exampleInputEmail002"
                                        placeholder="Search by name or email"
                                        value={searchContent} onChange={(e) => {
                                            setSearchContent(e.target.value)
                                            if(isMyStudent){
                                                getUserSearchApi(e.target.value, 1)
                                            } else{
                                                getMyStudents(e.target.value, 1)
                                            }
                                        }}
                                    />
                                    <Link className="search-link" to="#">
                                        <span className="material-symbols-outlined lh-1">
                                            search
                                        </span>
                                    </Link>
                                </Form.Group>
                            </Form>
                        </div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Card className="p-0">
                                <Card.Body className="p-0">
                                    <div className="user-tabing">
                                        <Nav as="ul" variant="pills" className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                                            <Nav.Item as="li" className=" col-12 col-sm-6 p-0 " onClick={() => setIsMyStudent(true)}>
                                                <Nav.Link href="#upcomming-tab" eventKey="first" role="button" className=" text-center p-2">My Student</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="col-12 col-sm-6 p-0" onClick={() => setIsMyStudent(false)}>
                                                <Nav.Link href="#past-tab" eventKey="second" role="button" className="text-center p-2">All Students</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first" >
                                        <Card.Body className=" p-0">
                                            <Row>
                                                <Col lg={12}>
                                                    {
                                                        followerList?.length > 0 ?
                                                            <ul className="request-list m-0 p-0 mt-0">
                                                                {/* <p className="mb-1 fs-5">My Students</p> */}
                                                                {followerList.map((elm, i) => (
                                                                    <li key={elm?.id} className="d-flex align-items-center  flex-wrap">
                                                                        <div className="user-img img-fluid flex-shrink-0">
                                                                            <img src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                                                alt="story-img" className="rounded-circle avatar-40" />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <Link to={`/dashboard/app/friend-profile/${elm?.slug}`}>
                                                                                <h5>{elm?.first_name && (elm?.first_name + " " + elm?.follower?.last_name)}</h5>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="d-flex align-items-center mt-2 mt-md-0">
                                                                            {
                                                                                elm?.is_added == 1 ?
                                                                                    <Link to="#" className="me-3 btn btn-secondary rounded"
                                                                                        onClick={() => {
                                                                                            addRemoveMember(eventDetails?.id, elm?.id, i, 0)
                                                                                        }}>Added</Link> :
                                                                                    <Link to="#" className="me-3 btn btn-primary rounded"
                                                                                        onClick={() => {
                                                                                            addRemoveMember(eventDetails?.id, elm?.id, i, 1)
                                                                                        }}><i class="ri-user-add-line me-1"></i>Add</Link>
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                                {
                                                                    myStudentPage < myStudentMaxPage ?
                                                                        <li className="d-block text-center mb-0 pb-3">
                                                                            <Link to="#" className="me-3 btn btn-primary"
                                                                                onClick={() => {
                                                                                    getMyStudents(searchContent, myStudentPage + 1)
                                                                                    setMyStudentPage(page + 1)
                                                                                }}>View More Request</Link>
                                                                        </li> : null
                                                                }
                                                            </ul> :
                                                            <ul className="request-list m-0 p-5 mt-2">
                                                                <div className="align-items-center ">
                                                                    <h5 className="mb-0">Users not found!</h5>
                                                                    <p className="mb-0">You can share invitation link by email</p>
                                                                    <Form.Control type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter email"
                                                                        value={inviteEmail} onChange={(e) => setInviteEmail(e.target.value)} />
                                                                    <Button className="btn btn-primary mt-2" onClick={() => {
                                                                        if (inviteEmail.toLowerCase().match(
                                                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                                        )) {
                                                                            eventInvitationApi(eventDetails?.id)
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                text: "Enter a valid email id!",
                                                                                icon: 'error',
                                                                                confirmButtonText: 'Okay'
                                                                            })
                                                                        }

                                                                    }}>Share by email</Button>
                                                                </div>
                                                            </ul>
                                                    }

                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content>
                                    <Tab.Pane eventKey="second">
                                        <Card.Body className=" p-0">
                                            <Row>
                                                <Col lg={12}>
                                                    {/* <div className="iq-todo-page">
                                                        <Form className="position-relative">
                                                            <Form.Group className="form-group mb-0">
                                                                <Form.Control
                                                                    type="text"
                                                                    className="form-control todo-search bg-soft-primary"
                                                                    id="exampleInputEmail002"
                                                                    placeholder="Search by name or email"
                                                                    value={searchContent} onChange={(e) => {
                                                                        setSearchContent(e.target.value)
                                                                        getUserSearchApi(e.target.value, 1)
                                                                    }}
                                                                />
                                                                <Link className="search-link" to="#">
                                                                    <span className="material-symbols-outlined lh-1">
                                                                        search
                                                                    </span>
                                                                </Link>
                                                            </Form.Group>
                                                        </Form>
                                                    </div> */}
                                                    {
                                                        userListForAddNewMember?.length > 0 ?
                                                            <ul className="request-list m-0 p-0 mt-0">
                                                                {/* <p className="mb-1 fs-5">All Students</p> */}
                                                                {userListForAddNewMember.map((elm, i) => (
                                                                    <li key={elm?.id} className="d-flex align-items-center  flex-wrap">
                                                                        <div className="user-img img-fluid flex-shrink-0">
                                                                            <img src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                                                alt="story-img" className="rounded-circle avatar-40" />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <Link to={`/dashboard/app/friend-profile/${elm?.slug}`}>
                                                                                <h5>{elm?.first_name && (elm?.first_name + " " + elm?.last_name)}</h5>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="d-flex align-items-center mt-2 mt-md-0">
                                                                            {
                                                                                elm?.is_added == 1 ?
                                                                                    <Link to="#" className="me-3 btn btn-secondary rounded"
                                                                                        onClick={() => {
                                                                                            addRemoveMember(eventDetails?.id, elm?.id, i, 0)
                                                                                        }}>Added</Link> :
                                                                                    <Link to="#" className="me-3 btn btn-primary rounded"
                                                                                        onClick={() => {
                                                                                            addRemoveMember(eventDetails?.id, elm?.id, i, 1)
                                                                                        }}><i class="ri-user-add-line me-1"></i>Add</Link>
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                                {
                                                                    page < maxPage ?
                                                                        <li className="d-block text-center mb-0 pb-3">
                                                                            <Link to="#" className="me-3 btn btn-primary"
                                                                                onClick={() => {
                                                                                    getUserSearchApi(searchContent, page + 1)
                                                                                    setPage(page + 1)
                                                                                }}>View More Request</Link>
                                                                        </li> : null
                                                                }
                                                            </ul> :
                                                            <ul className="request-list m-0 p-5 mt-2">
                                                                <div className="align-items-center ">
                                                                    <h5 className="mb-0">Users not found!</h5>
                                                                    <p className="mb-0">You can share invitation link by email</p>
                                                                    <Form.Control type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter email"
                                                                        value={inviteEmail} onChange={(e) => setInviteEmail(e.target.value)} />
                                                                    <Button className="btn btn-primary mt-2" onClick={() => {
                                                                        if (inviteEmail.toLowerCase().match(
                                                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                                        )) {
                                                                            eventInvitationApi(eventDetails?.id)
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                text: "Enter a valid email id!",
                                                                                icon: 'error',
                                                                                confirmButtonText: 'Okay'
                                                                            })
                                                                        }

                                                                    }}>Share by email</Button>
                                                                </div>
                                                            </ul>
                                                    }
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Tab.Container>

                    </div>
                </Modal.Body>
                {/* <ModalFooter>
                                    <div className="w-100">
                                        <CommentBox postId={elm?.id} commentId={""} />
                                    </div>
                                </ModalFooter> */}
            </Modal>
        </>
    )
}

export default EventParticipateModal
