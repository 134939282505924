import React, { useEffect, useState } from 'react'
import {
    Nav,
    Container,
    Image,
    Accordion,
    Button,
} from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom'

//image
import logo from '../../../../assets/images/logo.png'


const DefaultHeader = ({ notificationCount }) => {
    let location = useLocation();
    const [scrollYPosition, setScrollYPosition] = useState(0);
    // let  scrollYPosition = 0;
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div className="iq-top-navbar">
                <Nav
                    expand="lg"
                    variant="light"
                    className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-3"
                >
                    <Container fluid className="navbar-inner ">
                        <div className="d-flex align-items-center gap-3  pb-2 pb-lg-0">
                            <Link
                                to="/dashboard/app/home-page"
                                className="d-flex align-items-center gap-2 iq-header-logo"
                            >
                                <Image src={logo} className="img-fluid" style={{ width: 50 }} />
                                <h3
                                    className="logo-title d-none d-sm-block ps-1"
                                    data-setting="app_name"
                                >
                                    thefriendinneed
                                </h3>
                            </Link>
                        </div>

                        <Accordion as="ul" className="navbar-nav iq-main-menu d-flex align-items-center" id="sidebar-menu">
                            <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                                <Link className={`${location.pathname === '/dashboard/app/home-page' ? 'active' : ''} nav-link`} to="/dashboard/app/home-page">
                                    <span className="item-name fs-6"> Home </span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                                <Link className={`${location.pathname === '/dashboard/app/publicEvents' ? 'active' : ''} nav-link`} to="/dashboard/app/publicEvents">
                                    <span className="item-name fs-6"> Events </span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                                <Link className={`${location.pathname === '/dashboard/app/public-blog' ? 'active' : ''} nav-link`} to="/dashboard/app/public-blog">
                                    <span className="item-name fs-6"> Blogs </span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                                <Link className={`${location.pathname === '/auth/login-with-otp' ? 'active' : ''} nav-link`} to="/auth/login-with-otp"
                                    onClick={() => {
                                        localStorage.setItem('uzoneSelectedTicketId', "")
                                    }}>
                                    <span className="item-name fs-6"> My Tickets </span>
                                </Link>
                            </li>
                            {/* <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                                <Link className={`${location.pathname === '/dashboard/extrapages/privacy-policy' ? 'active' : ''} nav-link`} to="/dashboard/extrapages/privacy-policy">
                                    <span className="item-name fs-6"> Privacy Policy </span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                                <Link className={`${location.pathname === '/dashboard/extrapages/terms-of-service' ? 'active' : ''} nav-link`} to="/dashboard/extrapages/terms-of-service">
                                    <span className="item-name fs-6"> Terms of Use </span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                                <Link className={`${location.pathname === '/dashboard/extrapages/pages-faq' ? 'active' : ''} nav-link`} to="/dashboard/extrapages/pages-faq">
                                    <span className="item-name fs-6"> FAQ </span>
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className={`${location.pathname === '/auth/sign-in' ? 'active' : ''} nav-link`} to="/auth/sign-in">
                                    <Button variant="accent" className="mb-1" disabled style={{ width: "100px" }}>Ligin</Button>{' '}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`${location.pathname === '/auth/sign-up' ? 'active' : ''} nav-link`} to="/auth/sign-up">
                                    <Button variant="primary" className="mb-1" disabled style={{ width: "100px" }}>Sign up</Button>{' '}
                                </Link>
                            </li>
                        </Accordion>
                        {/* <ul className="navbar-nav navbar-list">
              <Nav.Item as="li">
                <Link to="/" className="d-flex align-items-center">
                  <i className="material-symbols-outlined">home</i>
                  <span className="mobile-text ms-3">Privacy Policy</span>
                </Link>
              </Nav.Item>
            </ul> */}
                    </Container>
                </Nav>
            </div>
        </>
    );
};

export default DefaultHeader;
