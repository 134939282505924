import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Container, Dropdown, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'
import { apiHandler } from '../../../api/apihandler';
import { CHALLENGE_DETAILS } from "../../../api/constants";
import LoaderModal from '../../../components/loaderModal'

import StoriesSection from '../../../components/stories/storiesSection'
import CreatePostModal from '../../../components/createPost/createPostModal'
import PeopleYouMayKnowCard from '../../../components/peopleYouMayKnow/peopleYouMayKnowCard'
import FeedPostCard from '../../../components/feeds/feedPostCard'
import ChallengeCard from '../../../components/challenge/challengeCard'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import { setPostModalShow, setCreatePostType } from '../../../store/setting/actions'
import CreatePostInputBox from '../../../components/createPost/createPostInputBox'

const ChallengeDetails = () => {
    const dispatch = useDispatch()
    const { slug } = useParams();
    const profileData = JSON.parse(useSelector((state) => state.setting.setting.user_data));
    const [challenge, setChallenge] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (profileData?.user_setting?.is_dance_corner === 1) {
            dispatch(setCreatePostType("2"))
        } else if (profileData?.user_setting?.is_influencer === 1) {
            dispatch(setCreatePostType("3"))
        } else {
            dispatch(setCreatePostType("4"))
        }
        getChallengeDetail()
    }, [])

    function refreshApi() {
        getChallengeDetail()
    }

    async function getChallengeDetail() {
        setShowLoader(true)
        try {
            const res = await apiHandler(CHALLENGE_DETAILS, "POST", {
                post_id: slug
            })
            if (res.data?.response?.status === 200) {
                setChallenge(res?.data?.data?.userPost)
            } else {
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    }


    return (
        <>
            <LoaderModal showLoader={showLoader} />

            <div className="content-page scroller">
                <Container>
                    <Row>
                        <Col lg={8} className="row m-0 p-0">
                            <Col sm={12}>
                                {/* <CreatePostModal
                                    postType={profileData?.user_setting?.is_dance_corner === 1 ? "2" :
                                        profileData?.user_setting?.is_influencer === 1 ? "3" : "4"}
                                    refreshApi={refreshApi}
                                    eventId={""} /> */}
                                <CreatePostInputBox
                                    postType={profileData?.user_setting?.is_dance_corner === 1 ? "2" :
                                        profileData?.user_setting?.is_influencer === 1 ? "3" : "4"}
                                    refreshApi={refreshApi}
                                    eventId={""} />
                            </Col>
                            <Col sm={12}>
                                {
                                    challenge ? <ChallengeCard elm={challenge} getChallengeList={refreshApi} challengeDetails={true} /> : null
                                }
                            </Col>
                        </Col>
                        <Col lg={4}>
                            <StoriesSection />
                            <PeopleYouMayKnowCard />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ChallengeDetails
