import React, { useState, useEffect } from 'react'

import { Link, useNavigate, Route } from 'react-router-dom'

const BlogSingleUserComment = ({ postId, commentElm, setRefreshCommentList }) => {
    const navigate = useNavigate();

    useEffect(() => {
        
    }, [])

    return (
        <>
            <div className="d-flex justify-content-between" style={{ marginTop: "10px" }}>
                <div className="me-3">
                    <img loading="lazy" className="rounded-circle  avatar-30"
                        src={commentElm?.user?.user_setting?.photo ? commentElm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                </div>
                <div className="w-100">
                    <div className="d-flex justify-content-between flex-wrap">
                        <div>
                            <h6 className="mb-0 d-inline-block">
                                <Link to="#">{commentElm?.user?.first_name + " " + commentElm?.user?.last_name}</Link>
                                </h6>
                            {
                                commentElm?.image ?
                                    <div>
                                        <img loading="lazy" className="avatar-60" src={commentElm?.image} alt="" />
                                    </div> : null
                            }
                            <p className="mb-0">{commentElm?.comment}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogSingleUserComment
