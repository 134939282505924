import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, OverlayTrigger, Tooltip, Button, Modal, Form } from 'react-bootstrap'
import Card from '../../../../components/Card'
import CustomToggle from '../../../../components/dropdowns'
import ShareOffcanvas from '../../../../components/share-offcanvas'
import { InView } from "react-intersection-observer";
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../../../api/apihandler';
import { GENERAL_SETTINGS, GET_MY_ADS } from "../../../../api/constants";
import Swal from 'sweetalert2'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setUserData } from '../../../../store/setting/actions'

//image
import user1 from '../../../../assets/images/user/1.jpg'
import user01 from '../../../../assets/images/user/01.jpg'
import user2 from '../../../../assets/images/user/02.jpg'
import user3 from '../../../../assets/images/user/03.jpg'
import user4 from '../../../../assets/images/user/04.jpg'
import img1 from '../../../../assets/images/small/07.png'
import img2 from '../../../../assets/images/small/08.png'
import img3 from '../../../../assets/images/small/09.png'
import img4 from '../../../../assets/images/small/10.png'
import img5 from '../../../../assets/images/small/11.png'
import img6 from '../../../../assets/images/small/12.png'
import img7 from '../../../../assets/images/small/13.png'
import img8 from '../../../../assets/images/small/14.png'
import p1 from '../../../../assets/images/page-img/p1.jpg'
import s4 from '../../../../assets/images/page-img/s4.jpg'
import s5 from '../../../../assets/images/page-img/s5.jpg'
import p2 from '../../../../assets/images/page-img/p2.jpg'
import p3 from '../../../../assets/images/page-img/p3.jpg'
import p4 from '../../../../assets/images/page-img/p4.jpg'
import p5 from '../../../../assets/images/page-img/p5.jpg'
import img42 from '../../../../assets/images/page-img/42.png'
import small6 from '../../../../assets/images/small/12.png'
import img9 from '../../../../assets/images/small/img-1.jpg'
import img10 from '../../../../assets/images/small/img-2.jpg'
import loader from '../../../../assets/images/page-img/page-load-loader.gif'
import video from '../../../../assets/images/small/video.png'
import LoaderModal from '../../../../components/loaderModal'
import AdCard from '../../../../components/ads/adCard'
import EventRightSide from '../../../../components/event/eventRightSide'
import CreateNewAd from '../../../../components/ads/createNewAd'


const FriendList = () => {
    // let profileData = (useSelector((state) => state.setting.setting.user_data))
    // profileData = profileData ? JSON.parse(profileData) : "";
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const dispatch = useDispatch()
    const [showCreateAdModal, setShowCreateAdModal] = useState(false);
    const [adsList, setAdsList] = useState([]);
    const [generalSettings, setGeneralSettings] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);
    const handleClose = () => setShowCreateAdModal(false);

    useEffect(() => {
        if (localStorage.getItem("uzoneAuthToken")) {
            getGeneralSettings()
            defaultLoading()
        } else {
            navigate('/auth/sign-in')
        }
    }, []);

    async function getGeneralSettings() {
        try {
            const res = await apiHandler(GENERAL_SETTINGS, "POST", {
            })
            if (res.data?.response?.status === 200) {
                setGeneralSettings(res?.data?.data?.generalSettings)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const defaultLoading = () => {
        getAdsList(1)
        setPage(1)
    };

    const loadMore = (inView) => {
        if (inView) {
            if (page < maxPage) {
                getAdsList(page + 1)
                //console.log("scroll working...", page + 1)
                setPage(page + 1)
            }
        }
    };

    async function getAdsList(pageVal) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(GET_MY_ADS, "POST", {
                page: pageVal,
                limit: 10
            })
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.userPosts?.meta?.last_page)
                if (res?.data?.data?.userPosts?.meta?.total > 0) {
                    if (pageVal === 1) {
                        setAdsList(res?.data?.data?.userPosts?.data)
                    } else {
                        setAdsList(adsList.concat(res?.data?.data?.userPosts?.data))
                    }
                }
            } else {
                navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            {
                showCreateAdModal ?
                    <CreateNewAd showCreateAdModal={showCreateAdModal} handleClose={handleClose} generalSettings={generalSettings} refreshApi={defaultLoading} /> : null
            }
            <div id="content-page" className="content-page">
                <Container>
                    <Row>
                        <Col className="row m-0 p-0">
                            <div>
                                {adsList?.length > 0 ? adsList.map((elm, i) => (
                                    <AdCard elm={elm} getAdsList={getAdsList} />
                                )) : null
                                }
                            </div>
                            {
                                showMoreDataLoader ?
                                    <div className="col-sm-12 text-center">
                                        <img src={loader} alt="loader" style={{ height: "100px" }} />
                                    </div> : null
                            }
                            <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                        </Col>
                        <Col lg={4}>
                            <Card>
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Create Advertise</h4>
                                    </div>
                                </div>
                                <Card.Body>
                                    <ul className="media-story list-inline m-0 p-0">
                                        <li className="d-flex mb-3 align-items-center" style={{ cursor: "pointer" }}
                                            onClick={() => { setShowCreateAdModal(true) }}>
                                            <i className="ri-add-line"></i>
                                            <div className="stories-data ms-3">
                                                <h5>Create New Advertise</h5>
                                                <p className="mb-0">create your own ad</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>

                            <EventRightSide />

                            {/* <Card>
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Events</h4>
                                    </div>
                                    <div className="card-header-toolbar d-flex align-items-center">
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                                <i className="ri-more-fill h4"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className=" dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <Dropdown.Item href="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                <Dropdown.Item href="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                <Dropdown.Item href="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                <Dropdown.Item href="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                <Dropdown.Item href="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <Card.Body>
                                    <ul className="media-story list-inline m-0 p-0">
                                        <li className="d-flex mb-4 align-items-center ">
                                            <img src={s4} alt="story1" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Web Workshop</h5>
                                                <p className="mb-0">1 hour ago</p>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <img src={s5} alt="story2" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Fun Events and Festivals</h5>
                                                <p className="mb-0">1 hour ago</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                            <Card>
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Upcoming Birthday</h4>
                                    </div>
                                </div>
                                <Card.Body>
                                    <ul className="media-story list-inline m-0 p-0">
                                        <li className="d-flex mb-4 align-items-center">
                                            <img src={user01} alt="story3" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Anna Sthesia</h5>
                                                <p className="mb-0">Today</p>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <img src={user2} alt="story-img" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Paul Molive</h5>
                                                <p className="mb-0">Tomorrow</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                            <Card>
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Suggested Pages</h4>
                                    </div>
                                    <div className="card-header-toolbar d-flex align-items-center">
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle}>
                                                <i className="ri-more-fill h4"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-right" aria-labelledby="dropdownMenuButton01">
                                                <Dropdown.Item href="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                <Dropdown.Item href="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                <Dropdown.Item href="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                <Dropdown.Item href="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                <Dropdown.Item href="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <Card.Body>
                                    <ul className="suggested-page-story m-0 p-0 list-inline">
                                        <li className="mb-3">
                                            <div className="d-flex align-items-center mb-3">
                                                <img src={img42} alt="story-img" className="rounded-circle img-fluid avatar-50" />
                                                <div className="stories-data ms-3">
                                                    <h5>Iqonic Studio</h5>
                                                    <p className="mb-0">Lorem Ipsum</p>
                                                </div>
                                            </div>
                                            <img src={img9} className="img-fluid rounded" alt="Responsive" />
                                            <div className="mt-3"><Link to="#" className="btn d-block"><i className="ri-thumb-up-line me-2"></i> Like Page</Link></div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center mb-3">
                                                <img src={img42} alt="story-img" className="rounded-circle img-fluid avatar-50" />
                                                <div className="stories-data ms-3">
                                                    <h5>Cakes & Bakes </h5>
                                                    <p className="mb-0">Lorem Ipsum</p>
                                                </div>
                                            </div>
                                            <img src={img10} className="img-fluid rounded" alt="Responsive" />
                                            <div className="mt-3"><Link to="#" className="btn d-block"><i className="ri-thumb-up-line me-2"></i> Like Page</Link></div>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card> */}
                        </Col>
                    </Row>

                </Container>
            </div>
        </>
    )

}

export default FriendList;