import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Card from '../Card'
// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import CreatePostModal from './createPostModal';

const CreatePostInputBox = ({ postType, refreshApi, eventId }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";
    const [createPostModalShow, setCreatePostModalShow] = useState(false);

    return (
        <>
            <Card id="post-modal-data" >
                <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">Create
                            {postType === "2" || postType === 2 ? " a Challenge" :
                                postType === "3" || postType === 3 ? " a Challenge" :
                                    postType === "4" || postType === 4 ? " Sponsor Challenge" :
                                        postType === "5" || postType === 5 ? " Event Post" :
                                            postType === "6" || postType === 6 ? " Announcement Post" : " Post"}</h4>
                    </div>
                </div>
                <Card.Body>
                    <div className="d-flex align-items-center">
                        <div className="user-img">
                            <img loading="lazy" src={profileData?.user_setting?.photo ? profileData?.user_setting?.photo :
                                "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="userimg" className="avatar-60 rounded-circle" />
                        </div>
                        <form className="post-text ms-3 w-100 " onClick={() => setCreatePostModalShow(true)}>
                            <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }} />
                        </form>
                    </div>
                </Card.Body>
            </Card>
            {
                createPostModalShow ?
                    <CreatePostModal postType={postType} refreshApi={refreshApi} createPostModalShow={createPostModalShow}
                        setCreatePostModalShow={setCreatePostModalShow} eventId={eventId} /> : null
            }
        </>
    )
}

export default CreatePostInputBox
