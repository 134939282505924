import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button, Image } from 'react-bootstrap'
import { Link, useNavigate, Route, useSearchParams } from 'react-router-dom'


//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
// import 'swiper/components/navigation/navigation.scss';

//img
import logo from '../../../assets/images/logo.png'
import login1 from '../../../assets/images/login/1.png'
import login2 from '../../../assets/images/login/2.png'
import login3 from '../../../assets/images/login/3.png'

//import {BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";

import { ToastProvider, useToasts } from 'react-toast-notifications';
import Swal from 'sweetalert2'
//import { useRouter } from 'next/navigation'
import { apiHandler } from '../../../api/apihandler';
import { LOGIN_WITH_EMAIL, LOGIN_WITH_EMAIL_OTP_MATCH, MATCH_EMAIL_OTP, RESEND_OTP, SIGNUP, VERIFY_EMAIL } from "../../../api/constants";

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setUserData } from '../../../store/setting/actions'

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const LoginWithOTP = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { addToast } = useToasts();
    const [showTicketBuy, setShowTicketBuy] = useState(false);
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [startCountDownTimer, setStartCountDownTimer] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

   useEffect(() => {
      //console.log("eventId",localStorage.getItem("uzoneSelectedTicketId"))
      const interval = setInterval(() => {
         if (seconds > 0) {
            setSeconds(seconds - 1);
         }

         if (seconds === 0) {
            if (minutes === 0) {
               clearInterval(interval);
            } else {
               setSeconds(59);
               setMinutes(minutes - 1);
            }
         }
      }, 1000);

      return () => {
         clearInterval(interval);
      };
   });

   const handleSubmit = () => {
    if (step == 1) {
        if (email.toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
            verifyEmail();
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Enter valid email!",
                icon: 'error',
                confirmButtonText: 'Okay'
             })
        }
    } else if (step == 2) {
        matchEmailOtp();
    }
   }

   async function verifyEmail() {
    const res = await apiHandler(LOGIN_WITH_EMAIL, "POST", {
        email: email
    })
    if (res.status === 200) {
        // setMinutes(0)
        // setSeconds(10)
        // setStartCountDownTimer(true)
        setStep(2)
    } else {
        addToast(res?.response?.data?.data?.message, { appearance: 'error' });
    }
}

async function reSendOTPApi() {
    const res = await apiHandler(RESEND_OTP, "POST", {
        email: email
    })
    if (res.data?.response?.status === 200) {
        addToast("OTP has been sent to your " + email, { appearance: 'success' });
        setMinutes(0)
        setSeconds(10)
        // setStartCountDownTimer(true)
    } else {
        addToast(res?.response?.data?.data?.message, { appearance: 'error' });
    }
}

async function matchEmailOtp() {
    const res = await apiHandler(LOGIN_WITH_EMAIL_OTP_MATCH, "POST", {
        email: email,
        otp: otp
    })
    if (res.status === 200) {
        localStorage.setItem('uzoneAuthToken', res?.data?.data?.token?.token)
        dispatch(setUserData(JSON.stringify(res?.data?.data?.user)));
        navigate('/dashboards/app/events')
    } else {
        addToast(res?.response?.data?.data?.message, { appearance: 'error' });
    }
}

   return (
      <>
         <ToastProvider>
            <section className="sign-in-page">
               <div id="container-inside">
                  <div id="circle-small"></div>
                  <div id="circle-medium"></div>
                  <div id="circle-large"></div>
                  <div id="circle-xlarge"></div>
                  <div id="circle-xxlarge"></div>
               </div>
               <Container className="p-0">
                  <Row className="no-gutters">
                     <Col md="6" className="text-center pt-5">
                        <div className="sign-in-detail text-white">
                           <Link className="sign-in-logo mb-5" to="/dashboard/app/home-page"><Image src={logo} className="img-fluid" alt="logo" /></Link>
                           <div className="sign-slider overflow-hidden">
                              <Swiper
                                 spaceBetween={30}
                                 centeredSlides={true}
                                 autoplay={{
                                    "delay": 2000,
                                    "disableOnInteraction": false
                                 }}
                                 className="list-inline m-0 p-0 ">
                                 <SwiperSlide>
                                    <Image src={login1} className="img-fluid mb-4" alt="logo" />
                                    <h4 className="mb-1 text-white">Find new friends</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                 </SwiperSlide>
                                 <SwiperSlide>
                                    <Image src={login2} className="img-fluid mb-4" alt="logo" />
                                    <h4 className="mb-1 text-white">Connect with the world</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                 </SwiperSlide>
                                 <SwiperSlide>
                                    <Image src={login3} className="img-fluid mb-4" alt="logo" />
                                    <h4 className="mb-1 text-white">Create new events</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                 </SwiperSlide>
                              </Swiper>
                           </div>
                        </div>
                     </Col>
                     <Col md="6" className="bg-white pt-5 pt-5 pb-lg-0 pb-5">
                        <div className="sign-in-from">
                           <h1 className="mb-0">Login with otp</h1>
                           <p>Enter your details to create a new account in a few steps</p>
                           <Form className="mt-4">
                              {step == 1 && <Form.Group className="form-group">
                                 <Form.Label>Your Email Address *</Form.Label>
                                 <Form.Control type="email" className="mb-0" id="exampleInputEmail1" placeholder="Email address"
                                    value={email} onChange={(e) => setEmail(e.target.value)} />
                              </Form.Group>}
                              {step == 2 && <Form.Group className="form-group">
                                 <Form.Label>OTP *</Form.Label>
                                 <Form.Control type="text" className="mb-0" id="exampleInputEmail2" placeholder="Enter OTP"
                                    value={otp} onChange={(e) => setOtp(e.target.value)} />
                              </Form.Group>}
                              <div className="d-inline-block w-100">
                                 {
                                    step === 2 ?
                                       <>
                                          {seconds > 0 || minutes > 0 ? (
                                             <span className="dark-color d-inline-block line-height-2">
                                                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                {seconds < 10 ? `0${seconds}` : seconds}
                                             </span>
                                          ) : (
                                             <span className="dark-color d-inline-block line-height-2">Didn't recieve code?
                                                <Link to="#" onClick={() => reSendOTPApi()}> Resend OTP</Link>
                                             </span>
                                          )}
                                       </> : null
                                 }
                                 <Button type="button" className="btn-primary float-end" onClick={() => {
                                    //history.push('/')
                                    handleSubmit()
                                 }}>
                                    {step == 2 ? 'Register' : 'Next'}
                                 </Button>
                              </div>
                              <div className="sign-info">
                                 <span className="dark-color d-inline-block line-height-2">Already Have Account ? <Link to="/auth/sign-in">Log In</Link></span>
                              </div>
                           </Form>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </section>
         </ToastProvider>
      </>
   )
}

export default LoginWithOTP
