import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { GROUP_MEMBERS } from '../../api/constants'
//import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';


const GroupMember = ({ groupId }) => {
    const [membersList, setMembersList] = useState([]);


    useEffect(() => {
        getGroupMembers()
    }, [])

    async function getGroupMembers() {
        try {
            const res = await apiHandler(GROUP_MEMBERS, 'POST', {
                page: 1,
                limit: 5,
                group_id: groupId
            });
            if (res.data?.response?.status === 200) {
                setMembersList(res?.data?.data?.group_members?.data)
            } else {
                setMembersList([])
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    return (
        <div className="iq-media-group">
            {
                membersList?.map((elm, index) => (
                    <Link to="#" className="iq-media">
                        <img className="img-fluid avatar-40 rounded-circle"
                            src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                    </Link>
                ))}
            {/* <Link to="#" className="iq-media">
                <img className="img-fluid avatar-40 rounded-circle" src={user05} alt="" />
            </Link>
            <Link to="#" className="iq-media">
                <img className="img-fluid avatar-40 rounded-circle" src={user06} alt="" />
            </Link>
            <Link to="#" className="iq-media">
                <img className="img-fluid avatar-40 rounded-circle" src={user07} alt="" />
            </Link>
            <Link to="#" className="iq-media">
                <img className="img-fluid avatar-40 rounded-circle" src={user08} alt="" />
            </Link>
            <Link to="#" className="iq-media">
                <img className="img-fluid avatar-40 rounded-circle" src={user09} alt="" />
            </Link>
            <Link to="#" className="iq-media">
                <img className="img-fluid avatar-40 rounded-circle" src={user10} alt="" />
            </Link> */}
        </div>
    )
}

export default GroupMember
