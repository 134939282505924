import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useNavigate, Route } from 'react-router-dom'
// img

import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import user11 from '../../../assets/images/user/11.jpg'
import user12 from '../../../assets/images/user/12.jpg'
import user13 from '../../../assets/images/user/13.jpg'
import user14 from '../../../assets/images/user/14.jpg'
import user15 from '../../../assets/images/user/15.jpg'
import user16 from '../../../assets/images/user/16.jpg'
import user17 from '../../../assets/images/user/17.jpg'
//Sweet alert
import Swal from 'sweetalert2'


const FriendReqest = () => {

   const navigate = useNavigate();

   const questionAlert = () => {
      const swalWithBootstrapButtons = Swal.mixin({
         customClass: {
            cancelButton: 'btn btn-outline-primary btn-lg ms-2',
            confirmButton: 'btn btn-primary btn-lg',
         },
         buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
         title: 'Are you sure?',
         text: "You won't be able to revert this!",
         icon: 'warning',
         showCancelButton: true,
         cancelButtonText: 'cancel',
         confirmButtonText: 'Yes, delete it!',

         reverseButtons: false,
         showClass: {
            popup: 'animate__animated animate__zoomIn'
         },
         hideClass: {
            popup: 'animate__animated animate__zoomOut'
         }

      }).then((result) => {
         if (result.isConfirmed) {
            swalWithBootstrapButtons.fire({
               title: 'Deleted!',
               text: 'Your Request has been deleted.',
               icon: 'success',
               showClass: {
                  popup: 'animate__animated animate__zoomIn'
               },
               hideClass: {
                  popup: 'animate__animated animate__zoomOut'
               }
            })
         } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
         ) {
            swalWithBootstrapButtons.fire({
               title: 'Your Request is safe!',
               showClass: {
                  popup: 'animate__animated animate__zoomIn'
               },
               hideClass: {
                  popup: 'animate__animated animate__zoomOut'
               }
            })
         }
      })
   }

   return (
      <>
         <div id='content-page' className='content-page'>
            <Container>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Friend Request</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <ul className="request-list list-inline m-0 p-0">
                              <li className="d-flex align-items-center  justify-content-between flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user5} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Jaques Amole</h6>
                                    <p className="mb-0">40  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <div className="confirm-click-btn">
                                       <Link to="#" className="me-3 btn btn-primary rounded confirm-btn">Confirm</Link>
                                       <Link to="#" className="me-3 btn btn-primary rounded request-btn" style={{ display: "none" }}>View All</Link>
                                    </div>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-flex align-items-center  justify-content-between flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user6} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Lucy Tania</h6>
                                    <p className="mb-0">12  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <div className="confirm-click-btn">
                                       <Link to="#" className="me-3 btn btn-primary rounded confirm-btn">Confirm</Link>
                                       <Link to="#" className="me-3 btn btn-primary rounded request-btn" style={{ display: "none" }}>View All</Link>
                                    </div>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-flex align-items-center flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user7} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Val Adictorian</h6>
                                    <p className="mb-0">0  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <div className="confirm-click-btn">
                                       <Link to="#" className="me-3 btn btn-primary rounded confirm-btn">Confirm</Link>
                                       <Link to="#" className="me-3 btn btn-primary rounded request-btn" style={{ display: "none" }}>View All</Link>
                                    </div>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-flex align-items-center flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user8} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Manny Petty</h6>
                                    <p className="mb-0">3  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-flex align-items-center  flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user9} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Marsha Mello</h6>
                                    <p className="mb-0">15  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-flex align-items-center  flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user10} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Caire Innet</h6>
                                    <p className="mb-0">8  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-flex align-items-center  flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user11} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Gio Metric</h6>
                                    <p className="mb-0">10  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-flex align-items-center  flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user12} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Chris P. Cream</h6>
                                    <p className="mb-0">18  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-flex align-items-center  flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user13} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Paul Misunday</h6>
                                    <p className="mb-0">6  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-flex align-items-center  flex-wrap">
                                 <div className="user-img img-fluid flex-shrink-0">
                                    <img src={user14} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="flex-grow-1 ms-3">
                                    <h6>Reanne Carnation</h6>
                                    <p className="mb-0">12  friends</p>
                                 </div>
                                 <div className="d-flex align-items-center mt-2 mt-md-0">
                                    <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                    <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Delete Request</Link>
                                 </div>
                              </li>
                              <li className="d-block text-center mb-0 pb-0">
                                 <Link to="#" className="me-3 btn btn-primary">View More Request</Link>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>

                  </Col>
               </Row>
            </Container>
         </div>
      </>
   )
}

export default FriendReqest