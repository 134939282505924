import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import { apiHandler } from '../../api/apihandler';
import { PROFILE_GALLERY } from "../../api/constants";
import { Link, useNavigate, Route } from 'react-router-dom'
import ReactFsLightbox from 'fslightbox-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;

const GalleryImageCard = ({ userId }) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [galleryImageList, setGalleryImageList] = useState([]);
    const [storiesMediaList, setStoriesMediaList] = useState([]);
    const [storiesMediaTypeList, setStoriesMediaTypeList] = useState([]);

    const [imageController, setImageController] = useState({
        toggler: false,
        slide: 1
    });
    function imageOnSlide(number) {
        setImageController({
            toggler: !imageController.toggler,
            slide: number
        });
    }

    useEffect(() => {
        getGalleryImage()
    }, [])

    async function getGalleryImage() {
        try {
            const res = await apiHandler(PROFILE_GALLERY, "POST", {
                user_id: userId,
                media_type: 0,
                page_no: 1,
                limit: 12,
            })
            if (res.data?.response?.status === 200) {
                if (res?.data?.data?.userPostImages?.meta?.total > 0) {
                    setGalleryImageList(res?.data?.data?.userPostImages?.data)
                }
            } else {
                //navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function createMedia() {
        galleryImageList.map((image, i) => {
            if (!storiesMediaList.includes(image?.image_path)) {
                storiesMediaList.push(image?.image_path)
                setStoriesMediaList(storiesMediaList)

                storiesMediaTypeList.push("image")
                setStoriesMediaTypeList(storiesMediaTypeList)

                // if (image?.media_type === 1) {
                //     storiesMediaTypeList.push("video")
                //     setStoriesMediaTypeList(storiesMediaTypeList)
                // } else {
                //     storiesMediaTypeList.push("image")
                //     setStoriesMediaTypeList(storiesMediaTypeList)
                // }
            }
        })
        console.log("arrayImageList", storiesMediaList)
    }

    return (
        <>
            <div>
                {
                    storiesMediaList?.length > 0 ?
                        <FsLightbox
                            toggler={imageController.toggler}
                            sources={storiesMediaList}
                            slide={imageController.slide}
                            types={storiesMediaTypeList}
                        />
                        : null
                }

                {
                    galleryImageList?.length > 0 ?
                        <Card>
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Photos</h4>
                                </div>
                            </div>
                            <Card.Body>
                                <ResponsiveMasonry
                                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                >
                                    <Masonry columnsCount={3} gutter="10px">
                                        {galleryImageList.map((image, i) => (
                                            <img loading="lazy" src={image?.image_path} key={image?.id}
                                                alt="post" className="img-fluid w-100"
                                                onClick={() => {
                                                    //createMedia()
                                                    imageOnSlide(i + 1)
                                                }} />
                                        ))}
                                    </Masonry>
                                </ResponsiveMasonry>
                            </Card.Body>
                        </Card> : null
                }


                {/* <Card.Body className="p-0">
                    <div className="d-grid gap-2 d-grid-template-1fr-13">
                        {
                            galleryImageList?.map((elm, i) => (
                                <div>
                                    <div className="user-images position-relative overflow-hidden">
                                        <Link onClick={() => imageOnSlide(10)} to="#">
                                            <img loading="lazy" src={elm?.image_path} className="img-fluid rounded" alt="Responsive" />
                                        </Link>
                                        <div className="image-hover-data">
                                            <div className="product-elements-icon">
                                                <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                    <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                    <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                    <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                        forward
                                                    </span> </Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Card.Body> */}

            </div>
        </>
    )
}

export default GalleryImageCard
