import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter, Image } from 'react-bootstrap'
import '../../assets/css/main.css';
import { Link, useNavigate, Route } from 'react-router-dom'
import gsap from "gsap";

// import Image from "next/image";

// images
import img1 from '../../assets/images/home/masthead/shape.svg'
import img2 from '../../assets/images/home/masthead/1.webp'
import img3 from '../../assets/images/home/1.svg'
import img4 from '../../assets/images/home/2.svg'
import img5 from '../../assets/images/home/home_page_banner.png'


const HeroHeader = ({ elm, getEventList, isPast }) => {
    const navigate = useNavigate();
    //const router = useRouter();
    const handleSubmit = (e) => {
        e.preventDefault();
    };
    useEffect(() => {
        const parallaxIt = () => {
            const target = document.querySelectorAll(".js-mouse-move-container");

            target.forEach((container) => {
                const targets = container.querySelectorAll(".js-mouse-move");

                targets.forEach((el) => {
                    const movement = el.getAttribute("data-move");

                    document.addEventListener("mousemove", (e) => {
                        const relX = e.pageX - container.offsetLeft;
                        const relY = e.pageY - container.offsetTop;

                        gsap.to(el, {
                            x:
                                ((relX - container.offsetWidth / 2) / container.offsetWidth) *
                                Number(movement),
                            y:
                                ((relY - container.offsetHeight / 2) / container.offsetHeight) *
                                Number(movement),
                            duration: 0.2,
                        });
                    });
                });
            });
        };

        parallaxIt();
    }, []);

    return (
        <section className="masthead -type-3 bg-light-6 js-mouse-move-container" style={{ overflowX: "hidden" }}>
            <img
                style={{ maxWidth: "100%" }}
                src={img5}
                alt="image"
            />
            {/* <div className="container">
                <div className="row y-gap-30 items-center justify-center">

                    <div
                        className="col-xl-7 col-lg-11 relative z-5"
                        data-aos="fade-up"
                        data-aos-delay="500"
                    >
                        <div className="masthead__content pl-32 lg:pl-0">
                            <h1 className="masthead__title">
                                Find Your Preferred
                                <br /> <span className="text-purple-1">Courses</span> & Improve
                                Your Skills
                            </h1>

                            <p className="masthead__text text-17 text-dark-1 mt-25">
                                Build skills with courses, certificates, and degrees online from
                                <br className="lg:d-none" />
                                world-class universities and companies.
                            </p>
                        </div>
                    </div>

                    <div
                        className="col-xl-5 col-lg-7 relative z-2"
                        data-aos="fade-up"
                        data-aos-delay="750"
                    >
                        <div className="masthead-image">
                            <div className="masthead-image__img1">
                                <div className="masthead-image__shape xl:d-none">
                                    <img
                                        style={{ height: "800px", width: "800px" }}
                                        src={img1}
                                        alt="image"
                                    />
                                </div>
                                <img
                                    style={{ height: "556px", width: "487px" }}
                                    data-move="20"
                                    className="js-mouse-move"
                                    src={img2}
                                    alt="image"
                                />
                            </div>

                            <div className="masthead-image__el1">
                                <div
                                    data-move="40"
                                    className="lg:d-none img-el -w-250 px-20 py-20 d-flex items-center bg-white rounded-8 js-mouse-move">
                                    <div className="size-50 d-flex justify-center items-center bg-red-2 rounded-full">
                                        <img
                                            style={{ height: "23px", width: "24px" }}
                                            src={img3}
                                            alt="icon"
                                        />
                                    </div>
                                    <div className="ml-20">
                                        <div className="text-orange-1 text-16 fw-500 lh-1">
                                            3.000 +
                                        </div>
                                        <div className="mt-3">Free Courses</div>
                                    </div>
                                </div>
                            </div>

                            <div className="masthead-image__el2">
                                <div
                                    data-move="40"
                                    className="shadow-4 img-el -w-260 px-40 py-20 d-flex items-center bg-white rounded-8 js-mouse-move"
                                >
                                    <div className="img-el__side">
                                        <div className="size-50 d-flex justify-center items-center bg-dark-1 rounded-full">
                                            <img
                                                style={{ height: "27px", width: "20px" }}
                                                src={img4}
                                                alt="icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="text-purple-1 text-16 fw-500 lh-1">
                                            Congrats!
                                        </div>
                                        <div className="mt-3">Your Admission Completed</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
    )
}

export default HeroHeader
