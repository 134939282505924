import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { COMMENT_LIKE, FEED_DELETE, FEED_LIKE, FEED_LIKES, POST_FLAG, REPORT_FLAGS_LIST } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import CommentList from '../comment/commentList'
import CommentBox from '../comment/commentBox'
import LoaderModal from '../loaderModal'

// images
import icon1 from '../../assets/images/icon/01.png'
import icon2 from '../../assets/images/icon/02.png'
import icon3 from '../../assets/images/icon/03.png'
import icon4 from '../../assets/images/icon/04.png'
import icon5 from '../../assets/images/icon/05.png'
import icon6 from '../../assets/images/icon/06.png'
import icon7 from '../../assets/images/icon/07.png'
import g1 from '../../assets/images/page-img/g1.jpg'
import g2 from '../../assets/images/page-img/g2.jpg'
import g3 from '../../assets/images/page-img/g3.jpg'
import g4 from '../../assets/images/page-img/g4.jpg'
import g5 from '../../assets/images/page-img/g5.jpg'
import likeFillIcon from '../../assets/images/icon/like_fill_icon.png'
import likeIcon from '../../assets/images/icon/like_icon.png'
import playButton from '../../assets/images/icon/play_button.png'
import { Scrollbar } from 'smooth-scrollbar/scrollbar'


// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import MiddleAdCard from '../ads/middleAdCard'

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;


const FeedPostCard = ({ elm, getFeedsList, feedDetails, index, adsPageVal, setAdsPageVal }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [show, setShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showFlagModal, setFlagModalShow] = useState(false);
    const navigate = useNavigate();
    const [refreshCommentList, setRefreshCommentList] = useState(false);
    const [feedMediaList, setFeedMediaList] = useState([]);
    const [feedMediaTypeList, setFeedMediaTypeList] = useState([]);
    const [feedLikeUserList, setFeedLikeUserList] = useState([]);
    const [reportFlagList, setReportFlagList] = useState([]);
    const [showLikesList, setShowLikesList] = useState(false);


    const [imageController, setImageController] = useState({
        toggler: false,
        slide: 1
    });
    function imageOnSlide(number) {
        setImageController({
            toggler: !imageController.toggler,
            slide: number
        });
    }

    const handleClose = () => {
        setFlagModalShow(false)
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }

    async function createMedia() {
        elm?.user_post_images.map((image, i) => {
            if (!feedMediaList.includes(image?.image_path)) {
                feedMediaList.push(image?.image_path)
                setFeedMediaList(feedMediaList)
                if (image?.media_type === 1) {
                    feedMediaTypeList.push("video")
                    setFeedMediaTypeList(feedMediaTypeList)
                } else {
                    feedMediaTypeList.push("image")
                    setFeedMediaTypeList(feedMediaTypeList)

                }
            }
        })
        //console.log("arrayList", feedMediaList)
    }

    useEffect(() => {
        createMedia()
        getFeedLikesUserList(elm?.id)
        //getFeedLikesUserList()
    }, [])

    async function getFeedLike(postId) {
        if (localStorage.getItem("uzoneAuthToken")) {
            setShowLoader(true)
            try {
                const res = await apiHandler(FEED_LIKE, "POST", {
                    post_id: postId
                })
                if (res.data?.response?.status === 200) {
                    // Swal.fire({
                    //     title: 'Success',
                    //     text: res?.response?.data?.data?.message,
                    //     icon: 'success',
                    //     confirmButtonText: 'Okay'
                    // })
                    if (elm?.is_like === 1) {
                        elm['is_like'] = 0
                        elm['like_count'] = elm?.like_count - 1
                    } else {
                        elm['is_like'] = 1
                        elm['like_count'] = elm?.like_count + 1
                    }
                    getFeedLikesUserList(elm?.id)
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            }
            finally {
                setShowLoader(false)
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    async function getFeedLikesUserList(postId) {
        try {
            const res = await apiHandler(FEED_LIKES, "POST", {
                post_id: postId
            })
            if (res.data?.response?.status === 200) {
                setFeedLikeUserList(res?.data?.data?.userLikes)
            } else {
                // Swal.fire({
                //     title: 'Error',
                //     text: res?.response?.data?.data?.message,
                //     icon: 'error',
                //     confirmButtonText: 'Okay'
                // })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function getFeedDelete(post_id) {
        if (localStorage.getItem("uzoneAuthToken")) {
            try {
                const res = await apiHandler(FEED_DELETE, "DELETE", {
                    post_id: post_id
                })
                if (res.data?.response?.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Your post deleted successfully',
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    getFeedsList()
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    //navigate('/auth/sign-in')
                    //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                    //console.log("profileRes",res)
                }
            } catch (error) {
                console.error(error);
            }
            finally {
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    async function getReportFlagList() {
        if (localStorage.getItem("uzoneAuthToken")) {
            try {
                const res = await apiHandler(REPORT_FLAGS_LIST, "POST")
                if (res.data?.response?.status === 200) {
                    setReportFlagList(res?.data?.data?.reportFlags)
                    setFlagModalShow(true)
                }
            } catch (error) {
                console.error(error);
            } finally {
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    async function flagPostApi(flagId) {
        if (localStorage.getItem("uzoneAuthToken")) {
            try {
                const res = await apiHandler(POST_FLAG, "POST", {
                    post_id: elm?.id,
                    report_flag_id: flagId
                })
                if (res.data?.response?.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: res?.response?.data?.data?.message,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    getFeedsList()
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            }
            finally {
            }
        } else {
            navigate('/auth/sign-in')
        }
    }



    return (
        <>
            <LoaderModal showLoader={showLoader} />
            {
                feedMediaList?.length > 0 ?
                    // <FsLightbox
                    //     toggler={toggler}
                    //     sources={feedMediaList}
                    //     // sources={[
                    //     //     'https://uzone360live.s3.amazonaws.com/feedImages/img4.jpg',
                    //     //     'https://i.imgur.com/fsyrScY.jpg',
                    //     //     'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
                    //     // ]}
                    //     types={feedMediaTypeList}
                    // /> 
                    <FsLightbox
                        toggler={imageController.toggler}
                        sources={feedMediaList}
                        slide={imageController.slide}
                        types={feedMediaTypeList}
                    />
                    : null
            }

            <Card>
                <Card.Body>
                    <div className="post-item">
                        <div className="user-post-data pb-3">
                            <div className="d-flex justify-content-between">
                                <div className="me-3">
                                    <img loading="lazy" className="rounded-circle  avatar-45"
                                        src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                </div>
                                <div className="w-100">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <div>
                                            <h5 className="mb-0 d-inline-block"><Link to={profileData?.user_type === 4 ? "#" : `/dashboard/app/friend-profile/${elm?.user?.slug}`}>{elm?.user?.first_name + " " + elm?.user?.last_name}</Link></h5>
                                            {/* <p className="ms-1 mb-0 d-inline-block">Add New Post</p> */}
                                            <p className="mb-0">{Moment(elm?.created_at).format('DD MMMM YYYY')}</p>
                                        </div>
                                        <div className="card-post-toolbar">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="bg-transparent">
                                                    <span className="material-symbols-outlined">
                                                        more_horiz
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className=" m-0 p-0">
                                                    {
                                                        elm?.is_own === 1 ?
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top"
                                                                    onClick={() =>
                                                                        Swal.fire({
                                                                            title: 'Warning!',
                                                                            text: 'Are you sure, want to delete this feed?',
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            showConfirmButton: true,
                                                                            cancelButtonText: 'Cancel',
                                                                            confirmButtonText: 'Confirm',
                                                                            confirmButtonColor: "#83b988"
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                getFeedDelete(elm?.id)
                                                                            }
                                                                        })}>
                                                                    <i className="ri-delete-bin-7-line h4"></i>
                                                                    <div className="data ms-2">
                                                                        <h6>Delete</h6>
                                                                        <p className="mb-0">Remove this Post from your profile</p>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item> : null
                                                    }
                                                    <Dropdown.Item className=" p-3" to="#">
                                                        <div className="d-flex -top"
                                                            onClick={() => getReportFlagList()}>
                                                            <i className="ri-flag-2-line h4"></i>
                                                            <div className="data ms-2">
                                                                <h6>Report Flag</h6>
                                                                <p className="mb-0">Flag reason for this post .</p>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>

                                                    {/* <Dropdown.Item className=" p-3" to="#">
                                                                           <div className="d-flex align-items-top">
                                                                              <i className="ri-save-line h4"></i>
                                                                              <div className="data ms-2">
                                                                                 <h6>Save Post</h6>
                                                                                 <p className="mb-0">Add this to your saved items</p>
                                                                              </div>
                                                                           </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className=" p-3" to="#">
                                                                           <div className="d-flex align-items-top">
                                                                              <i className="ri-pencil-line h4"></i>
                                                                              <div className="data ms-2">
                                                                                 <h6>Edit Post</h6>
                                                                                 <p className="mb-0">Update your post and saved items</p>
                                                                              </div>
                                                                           </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className=" p-3" to="#">
                                                                           <div className="d-flex align-items-top">
                                                                              <i className="ri-delete-bin-7-line h4"></i>
                                                                              <div className="data ms-2">
                                                                                 <h6>Delete</h6>
                                                                                 <p className="mb-0">Remove thids Post on Timeline</p>
                                                                              </div>
                                                                           </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className=" p-3" to="#">
                                                                           <div className="d-flex align-items-top">
                                                                              <i className="ri-notification-line h4"></i>
                                                                              <div className="data ms-2">
                                                                                 <h6>Notifications</h6>
                                                                                 <p className="mb-0">Turn on notifications for this post</p>
                                                                              </div>
                                                                           </div>
                                                                        </Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            elm?.content ?
                                <div className="mt-1">
                                    <p>{elm?.content}</p>
                                </div> : null
                        }
                        <div className="user-post">
                            <Link to="#" >
                                {
                                    elm?.user_post_images?.length === 1 ?
                                        <ResponsiveMasonry
                                            columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 1 }}>
                                            <Masonry columnsCount={3} gutter="10px">
                                                {elm?.user_post_images.map((image, i) => (
                                                    image?.media_type === 1 ?
                                                        <div key={image?.id} onClick={() => {
                                                            imageOnSlide(i + 1)
                                                        }}>
                                                            <div className="img-fluid" style={{ position: "relative" }} >
                                                                <img loading="lazy"
                                                                    src={image?.thumb} alt="gallary" className="img-fluid" />
                                                            </div>
                                                            <Link style={{ position: "absolute", left: "47%", top: "47%", zIndex: 1 }}>
                                                                <img style={{ height: 50, width: 50 }} src={playButton} />
                                                            </Link>
                                                        </div> :
                                                        <img key={image?.id} loading="lazy" src={image?.image_path}
                                                            alt="post" className="img-fluid w-100"
                                                            onClick={() => {
                                                                imageOnSlide(i + 1)
                                                            }} />

                                                ))}
                                            </Masonry>
                                        </ResponsiveMasonry> :
                                        elm?.user_post_images?.length === 2 ?
                                            <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 2 }}>
                                                <Masonry columnsCount={3} gutter="10px">
                                                    {elm?.user_post_images.map((image, i) => (
                                                        image?.media_type === 1 ?
                                                            <div key={image?.id} onClick={() => {
                                                                imageOnSlide(i + 1)
                                                            }} style={{ position: "relative" }}>
                                                                <div className="img-fluid">
                                                                    <img loading="lazy"
                                                                        src={image?.thumb} alt="gallary" className="img-fluid" />
                                                                </div>
                                                                <Link style={{ position: "absolute", left: "45%", top: "40%", zIndex: 1 }}>
                                                                    <img style={{ height: 50, width: 50 }} src={playButton} />
                                                                </Link>
                                                            </div> :
                                                            <img key={image?.id} loading="lazy" src={image?.image_path}
                                                                alt="post" className="img-fluid w-100"
                                                                onClick={() => {
                                                                    imageOnSlide(i + 1)
                                                                }} />

                                                    ))}
                                                </Masonry>
                                            </ResponsiveMasonry> :
                                            <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                                                <Masonry columnsCount={3} gutter="10px">
                                                    {elm?.user_post_images.map((image, i) => (
                                                        image?.media_type === 1 ?
                                                            <div key={image?.id} onClick={() => {
                                                                imageOnSlide(i + 1)
                                                            }} style={{ position: "relative" }}>
                                                                <div className="img-fluid">
                                                                    <img loading="lazy"
                                                                        src={image?.thumb} alt="gallary" className="img-fluid" />
                                                                </div>
                                                                <Link className="video-img-btn">
                                                                    <img style={{ height: 50, width: 50 }} src={playButton} />
                                                                </Link>
                                                            </div> :
                                                            <img key={image?.id} loading="lazy" src={image?.image_path}
                                                                alt="post" className="img-fluid w-100"
                                                                onClick={() => {
                                                                    imageOnSlide(i + 1)
                                                                }} />

                                                    ))}
                                                </Masonry>
                                            </ResponsiveMasonry>
                                }

                            </Link>
                            {/* <img loading="lazy" src={elm?.user_post_images[0]?.image_path} alt="post" className="img-fluid w-100" /> */}
                        </div>

                        <div className="comment-area mt-4">
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div className="like-block position-relative d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h6 className="mb-0 d-inline-block">
                                                {
                                                    elm?.is_like === 1 ?
                                                        <img loading="lazy" src={likeFillIcon} alt="icon" className="img-fluid" style={{ marginTop: "-2px", cursor: "pointer" }}
                                                            onClick={() => { getFeedLike(elm?.id) }} /> :
                                                        <img loading="lazy" src={likeIcon} alt="icon" className="img-fluid" style={{ marginTop: "-5px", cursor: "pointer" }}
                                                            onClick={() => { getFeedLike(elm?.id) }} />
                                                }
                                                {
                                                    elm?.is_like === 1 ?
                                                        elm?.like_count > 1 ?
                                                            <Link to="#"
                                                                onMouseEnter={() => setShowLikesList(true)}
                                                                onMouseLeave={() => setShowLikesList(false)}>
                                                                You and {((elm?.like_count) - 1) + " "} others
                                                            </Link> :
                                                            <Link to="#"
                                                                onMouseEnter={() => setShowLikesList(true)}
                                                                onMouseLeave={() => setShowLikesList(false)}>
                                                                You like
                                                            </Link> :
                                                        <Link to="#" style={{ marginLeft: "6px" }}
                                                            onMouseEnter={() => setShowLikesList(true)}
                                                            onMouseLeave={() => setShowLikesList(false)}>
                                                            {elm?.like_count + " "}like
                                                        </Link>
                                                }
                                            </h6>
                                            <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                <Link to="#" onClick={handleShow}
                                                >{elm?.comment_count + " "}Comment</Link>
                                            </h6>
                                        </div>

                                        {
                                            showLikesList ?
                                                <div className="card-header-toolbar d-flex align-items-center" style={{ position: "absolute" }}
                                                    onMouseEnter={() => setShowLikesList(true)}
                                                    onMouseLeave={() => setShowLikesList(false)}>
                                                    <Dropdown>
                                                        <Card className="dropdown-menu-right cardBackgroundColor">
                                                            <div style={{ backgroundColor: "#e4f0f1", margin: "1px" }}>
                                                                {
                                                                    feedLikeUserList ?
                                                                        feedLikeUserList?.map((elm, i) => (
                                                                            <Dropdown.Item
                                                                                key={elm?.id}
                                                                                href={`/dashboard/app/friend-profile/${elm?.user?.slug}`} >
                                                                                {elm?.user?.first_name + " " + elm?.user?.last_name}
                                                                            </Dropdown.Item>
                                                                        )) : null
                                                                }
                                                            </div>
                                                        </Card>
                                                    </Dropdown>
                                                </div> : null
                                        }

                                        {/* <div className="like-data">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} >
                                                    <img loading="lazy" src={icon1} className="img-fluid" alt="" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className=" py-2">
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img loading="lazy" src={icon1} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img loading="lazy" src={icon2} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img loading="lazy" src={icon3} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img loading="lazy" src={icon4} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img loading="lazy" src={icon5} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img loading="lazy" src={icon6} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img loading="lazy" src={icon7} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="total-like-block ms-2 me-3">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                    {elm?.like_count} Likes
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item to="#">Max Emum</Dropdown.Item>
                                                    <Dropdown.Item to="#">Bill Yerds</Dropdown.Item>
                                                    <Dropdown.Item to="#">Hap E. Birthday</Dropdown.Item>
                                                    <Dropdown.Item to="#">Tara Misu</Dropdown.Item>
                                                    <Dropdown.Item to="#">Midge Itz</Dropdown.Item>
                                                    <Dropdown.Item to="#">Sal Vidge</Dropdown.Item>
                                                    <Dropdown.Item to="#">Other</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <Modal scrollable={true} show={show} onHide={handleClose} size="lg" style={{ marginTop: 80, maxHeight: "650px" }}>
                                <Modal.Header className="d-flex justify-content-between">
                                    <div className="d-flex justify-content-between">
                                        <div className="me-3">
                                            <img loading="lazy" className="rounded-circle  avatar-40"
                                                src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                        </div>
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <div>
                                                    <h5 className="mb-0 d-inline-block"><Link to="#">{elm?.user?.first_name + " " + elm?.user?.last_name}</Link></h5>
                                                    <p className="mb-0">{Moment(elm?.created_at).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="post-item" style={{ maxHeight: "500px" }}>
                                        {
                                            elm?.content ?
                                                <div className="mt-1">
                                                    <p>{elm?.content}</p>
                                                </div> : null
                                        }
                                        <div className="user-post">
                                            <Link to="#">
                                                <ResponsiveMasonry
                                                //columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                                >
                                                    <Masonry columnsCount={3} gutter="10px">
                                                        {elm?.user_post_images.map((image, i) => (
                                                            image?.media_type === 1 ?
                                                                <div key={image?.id} onClick={() => {
                                                                    imageOnSlide(i + 1)
                                                                }}>
                                                                    <div className="img-fluid" style={{ width: 200, height: 200 }} >
                                                                        <img style={{ height: 200, width: 200, marginRight: "17px", }} loading="lazy"
                                                                            src={image?.thumb} alt="gallary" className="img-fluid" />
                                                                        {/* <VideoThumbnail
                                                                            width={200}
                                                                            height={200}
                                                                            videoUrl={image?.image_path} /> */}
                                                                    </div>
                                                                    <Link style={{ position: "absolute", marginTop: "-123px", marginLeft: "70px", zIndex: 1 }}>
                                                                        <img style={{ height: 50, width: 50 }} src={playButton} />
                                                                    </Link>
                                                                </div> :
                                                                <img key={image?.id} loading="lazy" src={image?.image_path}
                                                                    alt="post" className="img-fluid w-100"
                                                                    onClick={() => {
                                                                        imageOnSlide(i + 1)
                                                                    }} />
                                                        ))}
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </Link>
                                        </div>
                                        <div className="like-block position-relative d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <div style={{ marginTop: "10px" }}>
                                                    <h6 className="mb-0 d-inline-block" style={{ cursor: "pointer" }}
                                                        onClick={() => { getFeedLike(elm?.id) }}>
                                                        {
                                                            elm?.is_like === 1 ?
                                                                <img loading="lazy" src={likeFillIcon} alt="icon" className="img-fluid" style={{ marginTop: "-2px" }} /> :
                                                                <img loading="lazy" src={likeIcon} alt="icon" className="img-fluid" style={{ marginTop: "-5px" }} />
                                                        }

                                                        {
                                                            elm?.is_like === 1 ?
                                                                elm?.like_count > 1 ?
                                                                    <Link to="#">You and {((elm?.like_count) - 1) + " "} others</Link> :
                                                                    <Link to="#">You like</Link> :
                                                                <Link to="#" style={{ marginLeft: "6px" }}>{elm?.like_count + " "}like</Link>
                                                        }
                                                    </h6>
                                                    <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                        <Link to="#" onClick={handleShow}
                                                        >{elm?.comment_count + " "}Comment</Link>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <CommentList postId={elm?.id} refreshCommentList={refreshCommentList} setRefreshCommentList={setRefreshCommentList} />
                                    </div>
                                </Modal.Body>
                                <ModalFooter>
                                    <div className="w-100">
                                        <CommentBox postId={elm?.id} commentId={""} setRefreshCommentList={setRefreshCommentList} />
                                    </div>
                                </ModalFooter>
                            </Modal>

                            <Modal scrollable={true} show={showFlagModal} onHide={handleClose} size="lg" style={{ marginTop: 80 }}>
                                <Modal.Header className="d-flex justify-content-between">
                                    <div className="d-flex justify-content-between">
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <h4 className="mb-0 d-inline-block">Select Flag Reason</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="post-item" style={{ maxHeight: "500px" }}>
                                        <ul className="request-list m-0 p-0">
                                            {
                                                reportFlagList?.map((elm, i) => (
                                                    <li key={elm?.id} className="d-flex flex-wrap " style={{ justifyContent: "center", cursor: "pointer" }}
                                                        onClick={() => {
                                                            handleClose()
                                                            flagPostApi(elm?.id)
                                                        }}>
                                                        <h5 className="mb-0 d-flex justify-content-between flex-wrap">
                                                            <Link to="#">{elm?.name}</Link>
                                                        </h5>
                                                    </li>


                                                ))
                                            }
                                        </ul>
                                    </div>
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div>
                    {
                        feedDetails ?
                            <>
                                <hr />
                                <CommentList postId={elm?.id} refreshCommentList={refreshCommentList} setRefreshCommentList={setRefreshCommentList} />
                                <div className="w-100">
                                    <CommentBox postId={elm?.id} commentId={""} setRefreshCommentList={setRefreshCommentList} />
                                </div>
                            </> : null
                    }

                </Card.Body>
            </Card>

        </>
    )
}

export default FeedPostCard
