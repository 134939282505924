import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { apiHandler } from '../../../api/apihandler';
import { NOTIFICATION_DELETE, NOTIFICATION_LIST, NOTIFICATION_READ } from '../../../api/constants';
import Moment from 'moment';
import Swal from 'sweetalert2'
import LoaderModal from '../../../components/loaderModal';
import { InView } from "react-intersection-observer";

// img
import user1 from '../../../assets/images/user/01.jpg'
import user2 from '../../../assets/images/user/02.jpg'
import user3 from '../../../assets/images/user/03.jpg'
import user4 from '../../../assets/images/user/04.jpg'
import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import user11 from '../../../assets/images/user/11.jpg'
import user12 from '../../../assets/images/user/12.jpg'
import user13 from '../../../assets/images/user/13.jpg'
import user14 from '../../../assets/images/user/14.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import NoDataFound from '../../../components/noDataFound';

const Notification = () => {
   const [notificationlist, setNotificationList] = useState([]);
   const [page, setPage] = useState(1);
   const [maxPage, setMaxPage] = useState(1);
   const [showLoader, setShowLoader] = useState(false);
   const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

   useEffect(() => {
      getNotificationList(1)
   }, [])

   const loadMore = (inView) => {
      if (inView) {
         if (page < maxPage) {
            getNotificationList(page + 1)
            //console.log("scroll working...", page + 1)
            setPage(page + 1)
         }
      }
   };

   function refreshApi() {
      setPage(1)
      getNotificationList(1)
   }

   async function getNotificationList(pageVal) {
      setShowMoreDataLoader(true)
      //setShowLoader(true)
      try {
         const res = await apiHandler(NOTIFICATION_LIST, "POST", {
            page: pageVal,
            limit: 10
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.notifications?.meta?.last_page)
            if (pageVal === 1) {
               setNotificationList(res?.data?.data?.notifications?.data)
            } else {
               setNotificationList(notificationlist.concat(res?.data?.data?.notifications?.data))
            }

         } else {
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowMoreDataLoader(false)
         //setShowLoader(false)
      }
   }

   async function readNotification(notificationId, index, isReadVal) {
      try {
         const res = await apiHandler(NOTIFICATION_READ, "POST", {
            notification_id: notificationId
         })
         if (res.data?.response?.status === 200) {
            let temporaryarray = notificationlist.slice();
            temporaryarray[index]['is_read'] = isReadVal;
            setNotificationList(temporaryarray);
            // Swal.fire({
            //    title: 'Success',
            //    text: res?.response?.data?.data?.message,
            //    icon: 'success',
            //    confirmButtonText: 'Okay'
            // })
         } else {
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   async function deleteNotification(notificationId) {
      try {
         const res = await apiHandler(NOTIFICATION_DELETE, "DELETE", {
            notification_id: notificationId
         })
         if (res.data?.response?.status === 200) {
            refreshApi()
            // Swal.fire({
            //    title: 'Success',
            //    text: res?.response?.data?.data?.message,
            //    icon: 'success',
            //    confirmButtonText: 'Okay'
            // })
         } else {
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   function generateURL(elm) {
      let dynaMicURL = null
      if (elm?.type === 0) {
         dynaMicURL = `/dashboard/app/friend-profile/${elm?.value_id}`
      } else if (elm?.type === 2) {
         dynaMicURL = `/dashboard/app/challengeDetails/${elm?.value_id}`
      } else if (elm?.type === 3) {
         dynaMicURL = `/dashboard/app/challengeDetails/${elm?.value_id}`
      } else if (elm?.type === 4) {
         dynaMicURL = `/dashboard/app/challengeDetails/${elm?.value_id}`
      } else if (elm?.type === 5) {
         dynaMicURL = `/dashboard/app/challengeDetails/${elm?.value_id}`
      } else if (elm?.feed_type === 0) {
         dynaMicURL = `/dashboard/app/feedDetails/${elm?.value_id}`
      } else {
         dynaMicURL = `/dashboard/app/challengeDetails/${elm?.value_id}`
      }
      return dynaMicURL
   }

   return (
      <>
         <LoaderModal showLoader={showLoader}/>
         <div id='content-page' className='content-page'>
            <Container>
               <Row>
                  <Col sm="12">
                     <h4 className="card-title mb-3">Notification</h4>
                  </Col>
                  <Col sm="12">
                     {
                        notificationlist.length > 0 ?
                           <div>
                              {
                                 notificationlist.map((elm, i) => (
                                    <Link to={generateURL(elm)}>
                                       {
                                          elm?.is_read === 1 ?
                                             <Card className="card_read_background_color">
                                                <Card.Body>
                                                   <ul className="notification-list m-0 p-0">
                                                      <li className="d-flex align-items-center justify-content-between">
                                                         <div className="user-img img-fluid">
                                                            <img src={elm?.user?.user_setting?.photo ?
                                                               elm?.user?.user_setting?.photo :
                                                               "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                               alt="story-img" className="rounded-circle avatar-40" />
                                                         </div>
                                                         <div className="w-100">
                                                            <div className="d-flex justify-content-between">
                                                               <div className=" ms-3">
                                                                  <h6>{elm?.description}</h6>
                                                                  <p className="mb-0">{Moment(elm?.updated_at).format('DD MMMM YYYY')}</p>
                                                               </div>
                                                               <div className="d-flex align-items-center">
                                                                  {/* <Link to="#" className="me-3 iq-notify bg-soft-primary rounded">
                                                            <i className="material-symbols-outlined md-18">
                                                               military_tech
                                                            </i>
                                                         </Link> */}
                                                                  <div className="card-header-toolbar d-flex align-items-center">
                                                                     <Dropdown>
                                                                        <Link to="#">
                                                                           <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                                              more_horiz
                                                                           </Dropdown.Toggle>
                                                                        </Link>
                                                                        <Dropdown.Menu className="dropdown-menu-right">
                                                                           {
                                                                              elm?.is_read === 1 ?
                                                                                 <Dropdown.Item to="#" onClick={() => readNotification(elm?.id, i, 0)}><i className="ri-eye-off-fill me-2"></i>Mark as unread</Dropdown.Item> :
                                                                                 <Dropdown.Item to="#" onClick={() => readNotification(elm?.id, i, 1)}><i className="ri-eye-fill me-2"></i>Mark as read</Dropdown.Item>
                                                                           }

                                                                           <Dropdown.Item to="#" onClick={() => deleteNotification(elm?.id)}><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                                           {/* <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item> */}
                                                                        </Dropdown.Menu>
                                                                     </Dropdown>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </li>
                                                   </ul>
                                                </Card.Body>
                                             </Card> :
                                             <Card>
                                                <Card.Body>
                                                   <ul className="notification-list m-0 p-0">
                                                      <li className="d-flex align-items-center justify-content-between">
                                                         <div className="user-img img-fluid">
                                                            <img src={elm?.user?.user_setting?.photo ?
                                                               elm?.user?.user_setting?.photo :
                                                               "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                               alt="story-img" className="rounded-circle avatar-40" />
                                                         </div>
                                                         <div className="w-100">
                                                            <div className="d-flex justify-content-between">
                                                               <div className=" ms-3">
                                                                  <h6>{elm?.description}</h6>
                                                                  <p className="mb-0">{Moment(elm?.updated_at).format('DD MMMM YYYY')}</p>
                                                               </div>
                                                               <div className="d-flex align-items-center">
                                                                  {/* <Link to="#" className="me-3 iq-notify bg-soft-primary rounded">
                                                         <i className="material-symbols-outlined md-18">
                                                            military_tech
                                                         </i>
                                                      </Link> */}
                                                                  <div className="card-header-toolbar d-flex align-items-center">
                                                                     <Dropdown>
                                                                        <Link to="#">
                                                                           <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                                              more_horiz
                                                                           </Dropdown.Toggle>
                                                                        </Link>
                                                                        <Dropdown.Menu className="dropdown-menu-right">
                                                                           {
                                                                              elm?.is_read === 1 ?
                                                                                 <Dropdown.Item to="#" onClick={() => readNotification(elm?.id, i, 0)}><i className="ri-eye-off-fill me-2"></i>Mark as unread</Dropdown.Item> :
                                                                                 <Dropdown.Item to="#" onClick={() => readNotification(elm?.id, i, 1)}><i className="ri-eye-fill me-2"></i>Mark as read</Dropdown.Item>
                                                                           }

                                                                           <Dropdown.Item to="#" onClick={() => deleteNotification(elm?.id)}><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                                           {/* <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item> */}
                                                                        </Dropdown.Menu>
                                                                     </Dropdown>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </li>
                                                   </ul>
                                                </Card.Body>
                                             </Card>
                                       }
                                    </Link>
                                 ))
                              }
                           </div> : <NoDataFound/>
                     }

                     {
                        showMoreDataLoader ?
                           <div className="col-sm-12 text-center">
                              <img src={loader} alt="loader" style={{ height: "100px" }} />
                           </div> : null
                     }
                     <InView as="div" onChange={(inView, entry) => loadMore(inView)} />

                     {/* <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user2} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className=" ms-3">
                                          <h6>Anne Fibbiyon Like Your Post</h6>
                                          <p className="mb-0">15  ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-danger rounded">
                                             <i className="material-symbols-outlined md-18">
                                                favorite_border
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user3} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="ms-3">
                                          <h6>Barry Cuda add Story</h6>
                                          <p className="mb-0">40  ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-primary rounded">
                                             <i className="material-symbols-outlined md-18">
                                                military_tech
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user4} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="ms-3">
                                          <h6>Cliff Hanger posted a comment on your status update</h6>
                                          <p className="mb-0">42  ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-success rounded">
                                             <i className="material-symbols-outlined md-16">
                                                chat_bubble_outline
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user4} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="ms-3">
                                          <h6>Rick O'Shea posted a comment on your photo</h6>
                                          <p className="mb-0">50  ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-success rounded">
                                             <i className="material-symbols-outlined md-16">
                                                chat_bubble_outline
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user5} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="ms-3">
                                          <h6>Brock Lee Sent a Friend Request</h6>
                                          <p className="mb-0">1 hour ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-warning rounded">
                                             <i className="material-symbols-outlined md-18">
                                                reply
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user6} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className=" ms-3">
                                          <h6>Ira Membrit shared your status update</h6>
                                          <p className="mb-0">1 hour ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-info rounded">
                                             <i className="material-symbols-outlined md-18">
                                                share
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user11} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="ms-3">
                                          <h6>Paul Molive and 3 ther have Birthday Today</h6>
                                          <p className="mb-0">3 houe ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-danger rounded">
                                             <i className="material-symbols-outlined md-18">
                                                cake
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user7} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="ms-3">
                                          <h6>Petey Cruiser Sent a Friend request</h6>
                                          <p className="mb-0">1 day ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-warning rounded">
                                             <i className="material-symbols-outlined md-18">
                                                reply
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user8} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="ms-3">
                                          <h6>Bob Frapples and 45 other Like Your Pst on timeline</h6>
                                          <p className="mb-0">1 week ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-danger rounded">
                                             <i className="material-symbols-outlined md-18">
                                                favorite_border
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user12} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="ms-3">
                                          <h6>Maya Didas share Your Post</h6>
                                          <p className="mb-0">1 month ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-info rounded">
                                             <i className="material-symbols-outlined md-18">
                                                share
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user9} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className=" ms-3">
                                          <h6>Sal Monella Add Photo with You</h6>
                                          <p className="mb-0">1 month ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-primary rounded">
                                             <i className="material-symbols-outlined md-18">
                                                military_tech
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user10} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="ms-3">
                                          <h6>Barb Dwyer commented on your new profile status</h6>
                                          <p className="mb-0">2 month ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-success rounded">
                                             <i className="material-symbols-outlined md-16">
                                                chat_bubble_outline
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user13} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className="info ms-3">
                                          <h6>Terry Aki commented on your new profile status</h6>
                                          <p className="mb-0">2 month ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-success rounded">
                                             <i className="material-symbols-outlined md-16">
                                                chat_bubble_outline
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user14} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className=" ms-3">
                                          <h6>Paige Turner Posted in Development Community</h6>
                                          <p className="mb-0">3 month ago</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <Link to="#" className="me-3 iq-notify bg-soft-primary rounded">
                                             <i className="material-symbols-outlined md-18 ">
                                                military_tech
                                             </i>
                                          </Link>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card> */}
                  </Col>
               </Row>
            </Container>
         </div>
      </>
   )
}

export default Notification