import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, ModalFooter, Dropdown } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Loader from "react-js-loader";
import { apiHandler } from '../../api/apihandler';
import { EVENT_LIST } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setUserData } from '../../store/setting/actions'
import EventCard from './eventCard';


const EventRightSide = ({ elm, getEventList, isPast }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [upcomingEventList, setUpcomingEventList] = useState([]);

    useEffect(() => {
        getUpcomingEventList()
    }, [])

    async function getUpcomingEventList() {
        //setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(EVENT_LIST, "POST", {
                page: 1,
                limit: 3,
                type: "upcoming"
            })
            if (res.data?.response?.status === 200) {
                setUpcomingEventList(res?.data?.data?.events?.data)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            //setShowMoreDataLoader(false)
        }
    }

    return (
        <>{
            upcomingEventList?.length > 0 ?
                <Card>
                    <div className="card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Events</h4>
                        </div>
                        <div className="card-header-toolbar d-flex align-items-center">
                            <Link to="/dashboards/app/events">View All</Link>
                        {/* <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                <i className="ri-more-fill h4"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=" dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <Dropdown.Item href="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                <Dropdown.Item href="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                <Dropdown.Item href="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                <Dropdown.Item href="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                <Dropdown.Item href="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </div>
                    </div>
                    <Card.Body>
                        {
                            upcomingEventList.map((elm, i) => (
                                <EventCard elm={elm} getEventList={""} key={elm?.id} isPast={false} />
                            ))}
                    </Card.Body>
                </Card> : null
        }
        </>
    )
}

export default EventRightSide
