import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import ReactFsLightbox from 'fslightbox-react';
//import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Masonry from 'react-masonry-css';
// images
import playButton from '../../assets/images/icon/play_button.png'

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;


const ChatMediaView = ({ chatData }) => {
    const [feedMediaList, setFeedMediaList] = useState([]);
    const [feedMediaTypeList, setFeedMediaTypeList] = useState([]);

    const [imageController, setImageController] = useState({
        toggler: false,
        slide: 1
    });
    function imageOnSlide(number) {
        setImageController({
            toggler: !imageController.toggler,
            slide: number
        });
    }

    async function createMedia() {
        chatData?.user_chat_images.map((image, i) => {
            if (!feedMediaList.includes(image?.image_path)) {
                feedMediaList.push(image?.image_path)
                setFeedMediaList(feedMediaList)
                if (image?.media_type === 1) {
                    feedMediaTypeList.push("video")
                    setFeedMediaTypeList(feedMediaTypeList)
                } else {
                    feedMediaTypeList.push("image")
                    setFeedMediaTypeList(feedMediaTypeList)

                }
            }
        })
        //console.log("arrayList", feedMediaList)
    }

    useEffect(() => {
        createMedia()
    }, [])

    const breakpointColumnsObj = {
        default: 3,
        300: 3,
        200: 2,
        100: 1,
    };

    return (
        <>
            {
                feedMediaList?.length > 0 ?
                    <FsLightbox
                        toggler={imageController.toggler}
                        sources={feedMediaList}
                        slide={imageController.slide}
                        types={feedMediaTypeList}
                    />
                    : null
            }

            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {chatData?.user_chat_images.map((image, i) => (
                    image?.media_type === 1 ?
                        <div className="my-masonry-grid_item" onClick={() => { imageOnSlide(i + 1) }}>
                            <img src={image?.thumb} alt="Image" className="image-fluid"
                                style={{ width: 100, height: 100 }} />
                            <Link style={{ position: "absolute", marginTop: "-65px", marginLeft: "5px", zIndex: 1 }}>
                                <img style={{ height: 30, width: 30 }} src={playButton} />
                            </Link>
                        </div> :
                        <div className="my-masonry-grid_item" onClick={() => { imageOnSlide(i + 1) }}>
                            <img src={image?.image_path} alt="Image" className="image-fluid"
                                style={{ width: 100, height: 100 }} />
                        </div>
                ))}
            </Masonry>
        </>
    )
}

export default ChatMediaView
