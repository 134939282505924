import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Container, Image } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'


//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
// import 'swiper/components/navigation/navigation.scss';

// img
import logo from '../../../assets/images/logo.png'
import login1 from '../../../assets/images/login/1.png'
import login2 from '../../../assets/images/login/2.png'
import login3 from '../../../assets/images/login/3.png'

import { ToastProvider, useToasts } from 'react-toast-notifications';
//import { useRouter } from 'next/navigation'
import { apiHandler } from '../../../api/apihandler';
import { FORGOT_PASSWORD, FORGOT_PASSWORD_SAVE, RESEND_OTP } from "../../../api/constants";
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Recoverpw = () => {
    //let history = useNavigate()
    //const router = useRouter()
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [pType, setPType] = useState('password');
    const [pIcon, setPIcon] = useState(eyeOff);
    const [cpType, setCPType] = useState('password');
    const [cpIcon, setCPIcon] = useState(eyeOff);

    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });

    const handlePToggle = () => {
        if (pType === 'password') {
            setPIcon(eye);
            setPType('text')
        } else {
            setPIcon(eyeOff)
            setPType('password')
        }
    }

    const handleCPToggle = () => {
        if (cpType === 'password') {
            setCPIcon(eye);
            setCPType('text')
        } else {
            setCPIcon(eyeOff)
            setCPType('password')
        }
    }

    const handleSubmit = () => {
        //alert(`The email you entered was: ${email}`)
        if (step == 1) {
            if (email.toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                forgotPassword();
            } else {
                addToast("Enter valid email!", { appearance: 'error' });
            }
        } else if (step == 2) {
            if (password === confirmPass) {
                forgotPasswordSave();
            } else {
                addToast("Password did not match!", { appearance: 'error' });
                //alert(`Password did not match!`)
            }
        }
    }

    async function forgotPassword() {
        const res = await apiHandler(FORGOT_PASSWORD, "POST", {
            email: email
        })
        if (res.data?.response?.status === 200) {
            setStep(2)
            setMinutes(0)
            setSeconds(10)
        } else {
            addToast(res?.response?.data?.data?.message, { appearance: 'error' });
        }
    }

    async function reSendOTPApi() {
        const res = await apiHandler(RESEND_OTP, "POST", {
            email: email
        })
        if (res.data?.response?.status === 200) {
            addToast("OTP has been sent to your " + email, { appearance: 'success' });
            //console.log("reSendOTP", res?.data)
            setMinutes(0)
            setSeconds(10)
        } else {
            //console.log("login failed")
            addToast(res?.response?.data?.data?.message, { appearance: 'error' });
        }
    }

    async function forgotPasswordSave() {
        const res = await apiHandler(FORGOT_PASSWORD_SAVE, "POST", {
            email: email,
            password: password,
            otp: otp
        })
        if (res.data?.response?.status === 200) {
            addToast("Password change successfully.");
            navigate('/auth/sign-in')
        } else {
            addToast(res?.response?.data?.data?.message, { appearance: 'error' });
        }
    }


    return (
        <>
            <section className="sign-in-page">
                <div id="container-inside">
                    <div id="circle-small"></div>
                    <div id="circle-medium"></div>
                    <div id="circle-large"></div>
                    <div id="circle-xlarge"></div>
                    <div id="circle-xxlarge"></div>
                </div>
                <Container className="p-0">
                    <Row className="no-gutters">
                        <Col md="6" className="text-center pt-5">
                            <div className="sign-in-detail text-white">
                                <Link className="sign-in-logo mb-5" to="/dashboard/app/home-page">
                                    <Image src={logo} className="img-fluid" alt="logo" />
                                </Link>
                                <div className="sign-slider overflow-hidden">
                                    <Swiper
                                        spaceBetween={30}
                                        centeredSlides={true}
                                        autoplay={{
                                            "delay": 2000,
                                            "disableOnInteraction": false
                                        }}
                                        className="list-inline m-0 p-0">
                                        <SwiperSlide>
                                            <Image src={login1} className="img-fluid mb-4" alt="logo" />
                                            <h4 className="mb-1 text-white">Find new friends</h4>
                                            <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Image src={login2} className="img-fluid mb-4" alt="logo" />
                                            <h4 className="mb-1 text-white">Connect with the world</h4>
                                            <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Image src={login3} className="img-fluid mb-4" alt="logo" />
                                            <h4 className="mb-1 text-white">Create new events</h4>
                                            <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </Col>
                        <Col md="6" className="bg-white pt-5 pt-5 pb-lg-0 pb-5">
                            <div className="sign-in-from">
                                <h1 className="mb-0">Reset Password</h1>
                                <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
                                <Form className="mt-4">
                                    {step == 1 && <Form.Group className="form-group">
                                        <Form.Label>Your Email Address *</Form.Label>
                                        <Form.Control type="email" className="mb-0" id="exampleInputEmail1" placeholder="Email address"
                                            value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>}
                                    {step == 2 && <>
                                        <Form.Group className="form-group">
                                            <Form.Label>OTP *</Form.Label>
                                            <Form.Control type="text" className="mb-0" id="exampleInputEmail2" placeholder="Enter OTP"
                                                value={otp} onChange={(e) => setOtp(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Password *</Form.Label>
                                            <div style={{ position: "relative", marginBottom: 60, display: "flex", justifyContent: "end" }}>
                                                <Form.Control type={pType} style={{ position: "absolute", paddingRight: 40 }} className="mb-0 d-inline-block" id="exampleInputPassword6" placeholder="Password"
                                                    value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                                <Icon style={{ position: "absolute", marginTop: 7, marginRight: 8 }} icon={pIcon} size={20} onClick={handlePToggle} />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Confirm Password *</Form.Label>
                                            <div style={{ position: "relative", marginBottom: 65, display: "flex", justifyContent: "end" }}>
                                                <Form.Control type={cpType} style={{ position: "absolute", paddingRight: 40 }} className="mb-0 d-inline-block" id="exampleInputPassword7" placeholder="Confirm Password"
                                                    value={confirmPass} onChange={(e) => { setConfirmPass(e.target.value) }} />
                                                <Icon style={{ position: "absolute", marginTop: 7, marginRight: 8 }} icon={cpIcon} size={20} onClick={handleCPToggle} />
                                            </div>
                                        </Form.Group>
                                    </>}

                                    {
                                        step === 2 ?
                                            <>
                                                {seconds > 0 || minutes > 0 ? (
                                                    <span className="dark-color d-inline-block line-height-2">
                                                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                        {seconds < 10 ? `0${seconds}` : seconds}
                                                    </span>
                                                ) : (
                                                    <span className="dark-color d-inline-block line-height-2">Didn't recieve code?
                                                        <Link to="#" onClick={() => reSendOTPApi()}> Resend OTP</Link>
                                                    </span>
                                                )}
                                            </> : null
                                    }

                                    <div className="d-inline-block w-100">
                                        <Button variant="primary" type="button" className="float-right mt-3" onClick={() => {
                                            //history.push('/auth/sign-in')
                                            handleSubmit()
                                        }}>
                                            {step == 2 ? 'Reset Password' : 'Next'}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Recoverpw
