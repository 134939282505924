import React from "react";
import { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { apiHandler } from '../../api/apihandler';
import { EVENT_MEMBER_PAYMENT, EVENT_TICKET_BUY } from '../../api/constants'
import { loadStripe } from '@stripe/stripe-js';
import LoaderModal from '../loaderModal'
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

function StripeCard({ eventId, count, handleClose, isParticipate }) {
  const stripe = useStripe();
  const elements = useElements();
  const [showLoader, setShowLoader] = useState(false);

  const addCard = async () => {
    try {
      setShowLoader(true)
      const cardElement = elements.getElement(CardElement);
      let token = await stripe.createToken(cardElement);
      console.log('createToken--', token?.token?.id)
      let res
      if (token?.token?.id === null || token?.token?.id === undefined) {
        Swal.fire({
          title: 'Error',
          text: "Sorry!, Your card details is not valid.",
          icon: 'error',
          confirmButtonText: 'Okay'
        })
      } else {
        if (isParticipate) {
          res = await apiHandler(EVENT_MEMBER_PAYMENT, "POST", {
            event_id: eventId,
            token: token?.token?.id
          })
        } else {
          res = await apiHandler(EVENT_TICKET_BUY, "POST", {
            event_id: eventId,
            quantity: count,
            token: token?.token?.id
          })
        }
      }

      if (res.data?.response?.status === 200) {
        handleClose()
        Swal.fire({
          title: 'Success',
          text: res?.response?.data?.data?.message,
          icon: 'success',
          confirmButtonText: 'Okay'
        })
      } else {
        Swal.fire({
          title: 'Error',
          text: res?.response?.data?.data?.message,
          icon: 'error',
          confirmButtonText: 'Okay'
        })
      }
    } catch (error) {
      console.log('error===>>', error);
      //addToast('Not a valid card.', { appearance: 'error' });
    }
    finally {
      setShowLoader(false)
    }

  }
  return (
    <>
      <LoaderModal showLoader={showLoader} />
      <CardElement />
      <button type="submit" className="btn btn-primary mt-5 w-100"
        onClick={() => {
          addCard()
        }}
      >Pay Now</button>
    </>
  );
}
export default StripeCard;
