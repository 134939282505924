import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Image, Form, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { COMMENT_LIST, COMMENT_POST, DELETE_COMMENT, FEEDS_LIST, FEED_DELETE, FEED_MEDIA_POST, FEED_POST, FOLLOWER_LIST, FOLLOWING_LIST, PROFILE_DETAILS, PROFILE_SEARCH } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'


const SearchResult = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [searchProfileData, setSearchProfileData] = useState([]);
    const [searchContent, setSearchContent] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
    })

    async function getSearchProfile(searchText) {
        try {
            const res = await apiHandler(PROFILE_SEARCH, "POST", {
                page: 1,
                limit: 100,
                search_text: searchText
            })
            if (res.data?.response?.status === 200) {
                setSearchProfileData(res?.data?.data?.users?.data)
                //console.log(searchProfileData)
            } else {
                setSearchProfileData([])
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    return (
        <>
            <div className="iq-search-bar device-search  position-relative">
                {/* <form
                    action="#"
                    className="searchbox"
                    onClick={handleShow}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalFullscreenSm"
                >
                    <Link className="search-link d-none d-lg-block" to="/">
                        <span className="material-symbols-outlined">search</span>
                    </Link>
                    <Form.Control
                        type="text"
                        className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                        placeholder="Search here test..."
                        value={searchContent} onChange={(e) => {
                            setSearchContent(e.target.value)
                            getSearchProfile(e.target.value)
                        }}
                    />
                    <Link
                        className="d-lg-none d-flex d-none d-lg-block"
                        to="/"
                        onClick={handleShow}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalFullscreenSm"
                    >
                        <span className="material-symbols-outlined">search</span>
                    </Link>
                </form> */}
                <div className="card-header-toolbar d-flex align-items-center">

                    <Dropdown >
                        <Dropdown.Toggle as={CustomToggle} id="post-option" >
                            <form
                                action="#"
                                className="searchbox"
                                onClick={handleShow}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalFullscreenSm"
                            >
                                <Link className="search-link d-none d-lg-block" to="/">
                                    <span className="material-symbols-outlined">search</span>
                                </Link>
                                <Form.Control
                                    type="text"
                                    className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                                    placeholder="Search here test..."
                                    value={searchContent} onChange={(e) => {
                                        setSearchContent(e.target.value)
                                        getSearchProfile(e.target.value)
                                    }}
                                />
                                <Link
                                    // className="d-lg-none d-flex d-none d-lg-block"
                                    className="d-lg-none d-none d-lg-block"
                                    to="/"
                                    onClick={handleShow}
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalFullscreenSm"
                                >
                                    <span className="material-symbols-outlined">search</span>
                                </Link>
                            </form>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-right cardBackgroundColor" style={{ maxHeight: "500px", overflowY: "scroll" }}>
                            <div style={{ backgroundColor: "#FFFFFF", margin: "1px" }}>
                                {
                                    searchProfileData?.map((elm, i) => (
                                        <Dropdown.Item href={`/dashboard/app/friend-profile/${elm?.slug}`} className='border-bottom search-hover'>
                                            <div className="d-flex align-items-center py-1 px-4">
                                                <div className="flex-shrink-0">
                                                    <Image
                                                        className="align-self-center img-fluid avatar-40 rounded-pill"
                                                        src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                        alt=""
                                                        loading="lazy" />
                                                </div>
                                                <div className="d-flex flex-column ms-3">
                                                    <h5>{elm?.first_name && (elm?.first_name + " " + elm?.last_name)}</h5>
                                                    <h6>{elm?.follower_count + " Followers " + " ● " + elm?.following_count + " Following "}</h6>
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                    ))
                                }
                            </div>
                        </Dropdown.Menu>

                    </Dropdown>
                </div>
                {/* <form
                action="#"
                className="searchbox"
                onClick={handleShow}
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFullscreenSm"
              >
                <Link className="search-link d-none d-lg-block" to="/">
                  <span className="material-symbols-outlined">search</span>
                </Link>
                <Form.Control
                  type="text"
                  className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                  placeholder="Search here test..."
                  value={searchContent} onChange={(e) => {
                    setSearchContent(e.target.value)
                    getSearchProfile(e.target.value)
                  }}
                />
                <Link
                  className="d-lg-none d-flex d-none d-lg-block"
                  to="/"
                  onClick={handleShow}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalFullscreenSm"
                >
                  <span className="material-symbols-outlined">search</span>
                </Link>
              </form>
              <Modal
                show={show}
                onHide={handleClose}
                className="search-modal"
                id="post-modal"
                style={{marginTop: "50px"}}
              >
                <div className="modal-dialog modal-fullscreen-lg-down m-0">
                  <Modal.Header className="py-2">
                    <div className="d-flex align-items-center justify-content-between d-lg-none w-100">
                      <form
                        action="#"
                        className="searchbox w-50"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalFullscreenSm"
                        onClick={handleShow}
                      >
                        <Link className="search-link" to="/">
                          <span className="material-symbols-outlined">
                            search
                          </span>
                        </Link>

                        <Form.Control
                          type="text"
                          className="text search-input bg-soft-primary"
                          placeholder="Search here..."
                          value={searchContent} 
                          onChange={(e) => {
                            setSearchContent(e.target.value)
                            getSearchProfile(e.target.value)
                          }}
                        />

                      </form>

                      <Link
                        to="/"
                        className="material-symbols-outlined text-dark"
                        onClick={handleClose}
                      >
                        close
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between ms-auto w-100">
                      <h5 className=" h4" id="exampleModalFullscreenLabel">
                        Recent
                      </h5>

                      <Link to="/" className="text-dark">
                        Clear All
                      </Link>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="p-0">
                    <div className="d-flex d-lg-none align-items-center justify-content-between w-100 p-3 pb-0">
                      <h5 className=" h4" id="exampleModalFullscreenLabel">
                        Recent
                      </h5>

                      <Link to="/" className="text-dark">
                        Clear All
                      </Link>
                    </div>
                    <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user6}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Paige Turner
                        </Link>

                        <span>Paige001</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Follow</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user7}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Monty Carlo
                        </Link>

                        <span>Carlo.m</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Unfollow</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center search-hover py-2 px-3 border-bottom">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user8}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Paul Molive
                        </Link>

                        <span>Paul.45</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Request</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="px-3 py-2">Suggestions</h4>

                      <div className="suggestion-card px-3 d-flex">
                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user8}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Ammy Paul
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user9}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Roger Carlo
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story ">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user10}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Justin Molive
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile ">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user11}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Roy Fisher
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Request</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user12}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Johan Carlo
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user13}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              MedrLink Miles
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user14}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Aohan Paul
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Request</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user15}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Rokni Joy
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user16}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Sepid Ryan
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </div>
              </Modal> */}
            </div>
        </>
    )
}

export default SearchResult
