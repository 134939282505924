import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'

// images
import noDataFound from '../assets/images/error/no_data_found_s.png'


const NoDataFound = ({ elm }) => {

    useEffect(() => {
    }, [])


    return (
        <div align="center" className="mt-5 mb-5">
            <img src={noDataFound} className="img-fluid w-10" alt="header-bg"/>
            <h2 style={{color: "#C7C7C7"}}>No Data Found!</h2>
        </div>
    )
}

export default NoDataFound
