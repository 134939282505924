import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Container, Dropdown, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import { Link, useSearchParams, useParams } from 'react-router-dom'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'
import { apiHandler } from '../../../api/apihandler';
import { FEEDS_DETAIL } from "../../../api/constants";
import LoaderModal from '../../../components/loaderModal'

import StoriesSection from '../../../components/stories/storiesSection'
import CreatePostModal from '../../../components/createPost/createPostModal'
import PeopleYouMayKnowCard from '../../../components/peopleYouMayKnow/peopleYouMayKnowCard'
import FeedPostCard from '../../../components/feeds/feedPostCard'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import { setPostModalShow, setCreatePostType } from '../../../store/setting/actions'
import CreatePostInputBox from '../../../components/createPost/createPostInputBox'

const FeedDetails = () => {
    const dispatch = useDispatch()
    const { slug } = useParams();
    const [feed, setFeed] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get("type")
    const feed_type = searchParams.get("feed_type")
    const value_id = searchParams.get("value_id")
    useEffect(() => {
        dispatch(setCreatePostType("0"))
        getFeed()
    }, [])

    function refreshApi() {
        getFeed()
    }

    async function getFeed() {
        setShowLoader(true)
        try {
            const res = await apiHandler(FEEDS_DETAIL, "POST", {
                post_id: slug
            })
            if (res.data?.response?.status === 200) {
                setFeed(res?.data?.data?.post)
            } else {
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    }


    return (
        <>
            <LoaderModal showLoader={showLoader} />

            <div className="content-page scroller">
                <Container>
                    <Row>
                        <Col lg={8} className="row m-0 p-0">
                            <Col sm={12}>
                                {/* <CreatePostModal
                                    postType={"0"}
                                    refreshApi={refreshApi}
                                    eventId={""} /> */}
                                <CreatePostInputBox
                                    postType={"0"}
                                    refreshApi={refreshApi}
                                    eventId={""} />
                            </Col>
                            <Col sm={12}>
                                {
                                    feed ? <FeedPostCard elm={feed} getFeedsList={refreshApi} feedDetails={true} /> : null
                                }
                            </Col>
                        </Col>
                        <Col lg={4}>
                            <StoriesSection />
                            <PeopleYouMayKnowCard />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default FeedDetails
