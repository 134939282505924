import React, { useState, useEffect } from 'react'
import { apiHandler } from '../../api/apihandler';
import { CHALLENGE_PERTICIPANTS_COMMENT } from "../../api/constants";
import SingleUserParticipateComment from './singleUserParticipateComment'


const ParticipateCommentList = ({ participateId, refreshPCList, setRefreshPCList }) => {
    const [commentList, setCommentList] = useState([]);

    useEffect(() => {
        getCommentList()
    }, [])

    useEffect(() => {
        getCommentList()
        setRefreshPCList(false)
    }, [refreshPCList])


    async function getCommentList() {
        try {
            const res = await apiHandler(CHALLENGE_PERTICIPANTS_COMMENT, "POST", {
                page: 1,
                limit: 100,
                participate_id: participateId
            })
            if (res.data?.response?.status === 200) {
                setCommentList(res?.data?.data?.comments?.data)
            } else {
                //navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    return (
        <>
            <div className="comment-area mt-3" style={{ marginTop: "10px" }}>
                {/* <h5 style={{ marginTop: "5px" }}>List of Comments</h5> */}
                {
                    commentList?.map((elm, i) => (
                        <div key={elm?.id}>
                            <SingleUserParticipateComment participateId={participateId} commentElm={elm} setRefreshPCList={setRefreshPCList} />
                            {
                                elm?.replies?.map((innerElm, index) => (
                                    <div style={{ marginLeft: "40px" }}>
                                        <SingleUserParticipateComment participateId={participateId} commentElm={innerElm} setRefreshPCList={setRefreshPCList} />
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
                {/* <CommentBox postId={postId} commentId={""} /> */}
            </div>
        </>
    )
}

export default ParticipateCommentList
