import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Card from '../../components/Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { FOLLOW_UNFOLLOW, PEOPLE_YOU_MAY_KNOW } from "../../api/constants";

//Sweet alert
import Swal from 'sweetalert2'


const PeopleYouMayKnowCard = () => {

    const navigate = useNavigate();
    const [peopleYouMayKnow, setPeopleYouMayKnow] = useState([]);

    useEffect(() => {
        //console.log("token",localStorage.getItem("authToken"))
        if (localStorage.getItem("uzoneAuthToken")) {
            setPeopleYouMayKnow([])
            getPeopleYouMayKnow()
        } else {
            navigate('/auth/sign-in')
        }
    }, []);

    async function getPeopleYouMayKnow() {
        try {
            const res = await apiHandler(PEOPLE_YOU_MAY_KNOW, "POST", {
                page: 1,
                limit: 5
            })
            if (res.data?.response?.status === 200) {
                setPeopleYouMayKnow(peopleYouMayKnow.concat(res?.data?.data?.users?.data))
            } else {
                navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function getFollowUnfollow(followerId, index, isFollowingVal) {
        try {
            const res = await apiHandler(FOLLOW_UNFOLLOW, "POST", {
                follower_id: followerId
            })
            if (res.data?.response?.status === 200) {
                let temporaryarray = peopleYouMayKnow.slice();
                temporaryarray[index]['is_following'] = isFollowingVal;
                setPeopleYouMayKnow(temporaryarray);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            //console.error(error);
        }
        finally {
        }
    }


    return (
        <>
            <Card>
                <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">People You May Know</h4>
                    </div>
                </div>
                <Card.Body>
                    <ul className="request-list m-0 p-0">
                        {peopleYouMayKnow.map((elm, i) => (
                            <li key={elm?.id} className="d-flex  flex-wrap">
                                <div className="user-img img-fluid flex-shrink-0">
                                    <img src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                        alt="story-img" className="rounded-circle avatar-40" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <Link to={`/dashboard/app/friend-profile/${elm?.slug}`}>
                                        <h5>{elm?.first_name && (elm?.first_name + " " + elm?.last_name)}</h5>
                                    </Link>
                                    <p className="mb-0">{elm?.follower_count + " Followers " + " ● " + elm?.following_count + " Following "}</p>
                                    <div className="d-flex align-items-center mt-2 mt-md-0">
                                        {
                                            elm?.is_following == 0 ?
                                                <Link to="#" className="me-3 btn btn-primary rounded"
                                                    onClick={() => {
                                                        getFollowUnfollow(elm?.id, i, 1)
                                                    }}><i className="ri-user-add-line me-1"></i>Follow</Link> :
                                                <Link to="#" className="me-3 btn btn-secondary rounded"
                                                    onClick={() => {
                                                        getFollowUnfollow(elm?.id, i, 0)
                                                    }}>Following</Link>
                                        }
                                    </div>
                                </div>
                                {/* <div className="d-flex align-items-center mt-2 mt-md-0">
                                    {
                                        elm?.is_following == 0 ?
                                            <Link to="#" className="me-3 btn btn-primary rounded"
                                                onClick={() => {
                                                    getFollowUnfollow(elm?.id, i, 1)
                                                }}><i className="ri-user-add-line me-1"></i>Follow</Link> :
                                            <Link to="#" className="me-3 btn btn-secondary rounded"
                                                onClick={() => {
                                                    getFollowUnfollow(elm?.id, i, 0)
                                                }}>Following</Link>
                                    }
                                </div> */}
                            </li>
                        ))}
                    </ul>
                </Card.Body>
            </Card>

        </>
    )
}

export default PeopleYouMayKnowCard