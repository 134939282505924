import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import { Link, useNavigate, Route } from 'react-router-dom'
// images
import sideImage from '../assets/images/getappimg/img.webp'
import apple from '../assets/images/getappimg/1.svg'
import android from '../assets/images/getappimg/2.svg'


const GetApp = ({ }) => {

    useEffect(() => {
    }, [])


    return (
        <div className="d-flex flex-wrap" style={{ justifyContent: "space-evenly", background: "#FFF", paddingTop: "70px", paddingBottom: "70px" }}>
            <div>
                <img src={sideImage} className="img-fluid w-10" alt="header-bg" />
            </div>
            <div className="d-flex align-items-center">
                <div>
                    <h1 >Get Access From</h1>
                    <h1 style={{ marginTop: "-13px" }}><Link to="#" className="mt-0">Anywhere</Link></h1>
                    <p className="pt-3 pb-3" >Take classes on the go with the educrat app. Stream or download to <br />watch on the plane, the subway, or wherever you learn best.</p>
                    <div >
                        <img src={apple} alt="loader" style={{ height: "60px" }} />
                        <img src={android} alt="loader" style={{ height: "60px", marginLeft: "18px" }} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GetApp
