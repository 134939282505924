import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { EVENT_TICKET_BUY } from "../../api/constants";
import Moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import StripeCard from '../stripe/stripeCard';


const TicketBuyPopup = ({ elm, showTicketBuy, setShowTicketBuy, getEventList }) => {
    const stripePromise = loadStripe('pk_test_51LAnCBDvkV73SGhIyJPlSJ5hZ4P92HGd47jfYWpZofysweYVmtzTDZuKEWQZLgHIX8z1mkNcV5vegJAZ76rmflE500j3BgZTQf');
    const [count, setCount] = useState(1);

    const handleClose = () => {
        getEventList()
        setShowTicketBuy(false)
    }

    useEffect(() => {

    }, [])

    return (
        <>
            <Modal scrollable={true} show={showTicketBuy} onHide={handleClose} size="md" style={{ marginTop: 100 }}>
                <Modal.Header className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        <div className="me-3">
                            <img loading="lazy" className="rounded-circle  avatar-50"
                                src={elm?.image ? elm?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                        </div>
                        <div className="w-100">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <h5 className="mb-0 d-inline-block"><Link to="#">{elm?.name}</Link></h5>
                                    <p className="mb-0">{Moment(elm?.start_time).format('DD MMMM YYYY - hh:mm A')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="post-item" style={{ maxHeight: "500px" }}>
                        <Row>
                            <span className="product-price" style={{ fontSize: "16px", marginTop: "5px" }}>Tickets Qty:</span>
                            <div className="quantity buttons_added mt-2">
                                <input type="button" defaultValue="-" className="minus h5" onClick={() => {
                                    if (count > 1) {
                                        setCount(count - 1)
                                    }
                                }} />
                                <input type="button" defaultValue={count} title="Qty" className="input-text qty text ms-1" />
                                <input type="button" defaultValue="+" className="plus h5" onClick={() => setCount(count + 1)} />
                                <span className="product-price ms-4" style={{ fontSize: "16px" }}>{"Price: $" + (count * elm?.ticket_price)}</span>
                            </div>

                            <span className="product-price" style={{ fontSize: "16px", marginTop: "20px" }}>Card Details:</span>
                            <div style={{ marginTop: "10px" }}>
                                <Elements stripe={stripePromise}>
                                    <StripeCard eventId={elm?.id} count={count} handleClose={handleClose} isParticipate={false}/>
                                </Elements>
                            </div>

                            {/* <button type="submit" className="btn btn-primary mt-5"
                                onClick={() => {
                                }}
                            >Buy Tickets</button> */}
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TicketBuyPopup
