import React from "react";
import { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { apiHandler } from '../../api/apihandler';
import { EVENT_MEMBER_PAYMENT, EVENT_TICKET_BUY } from '../../api/constants'
import { loadStripe } from '@stripe/stripe-js';
import LoaderModal from '../loaderModal'
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

function StripeCardForAd({ handlePostSubmit }) {
  const stripe = useStripe();
  const elements = useElements();
  const [showLoader, setShowLoader] = useState(false);

  const addCard = async () => {
    try {
      const cardElement = elements.getElement(CardElement);
      let token = await stripe.createToken(cardElement);
      console.log('createToken--', token?.token?.id)
      setShowLoader(false)
      if(token?.token?.id === null || token?.token?.id === undefined){
        Swal.fire({
            title: 'Error',
            text: "Sorry!, Your card details is not valid.",
            icon: 'error',
            confirmButtonText: 'Okay'
        })
      } else{
        handlePostSubmit(token?.token?.id)
      }
    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: "Sorry!, Your card details is not valid.",
            icon: 'error',
            confirmButtonText: 'Okay'
        })
    }
  }
  return (
    <>
      <LoaderModal showLoader={showLoader} />
      <CardElement />
      <button type="submit" className="btn btn-primary mt-5 w-100"
        onClick={() => {
          setShowLoader(true)
          addCard()
        }}
      >Create Ad & Pay Now</button>
    </>
  );
}
export default StripeCardForAd;
