import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter, Form } from 'react-bootstrap'
import Card from '../Card'
import Moment from 'moment';
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { EVENT_ADD_MEMBER, EVENT_FIND_MEMBERS, EVENT_INVITATION, EVENT_MEMBERS } from '../../api/constants'
import Swal from 'sweetalert2';
import Loader from "react-js-loader";
import Cropper from 'react-easy-crop'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

import getCroppedImg from '../../utilities/crop-image'
//import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import img7 from '../../assets/images/page-img/profile-bg7.jpg'
import NoDataFound from '../noDataFound';
import loader from '../../assets/images/page-img/page-load-loader.gif'

const ParticipatedMemberModal = ({ showParticipateModal, setShowParticipateModal, eventDetails }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);
    const [memberList, setMemberList] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);


    useEffect(() => {
        getMembersList(1)
    }, [])

    async function getMembersList() {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(EVENT_MEMBERS, "POST", {
                event_id: eventDetails?.id,
                page: page,
                limit: 10
            })
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.eventParticipants?.meta?.last_page)
                setMemberList(res?.data?.data?.eventParticipants?.data)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowMoreDataLoader(false)
        }
    }

    async function addRemoveMember(eventId, memberId, index, addedVal) {
        try {
            const res = await apiHandler(EVENT_ADD_MEMBER, 'POST', {
                event_id: eventId,
                member_id: memberId
            });
            if (res.data?.response?.status === 200) {
                let temporaryarray = memberList.slice();
                temporaryarray[index]['is_added'] = addedVal;
                setMemberList(temporaryarray);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }


    return (
        <>
            <Modal scrollable={true} show={showParticipateModal} onHide={() => setShowParticipateModal(false)} size="md" style={{ marginTop: 80 }}>
                <Modal.Header className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        <div className="w-100">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div className="me-3">
                                    <img loading="lazy" className="rounded-circle  avatar-60"
                                        src={eventDetails?.image ? eventDetails?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                </div>
                                <div>
                                    <h4 className="mb-0 d-inline-block"><Link to="#">{eventDetails?.name}</Link></h4>
                                    <p className="mb-0">{Moment(eventDetails?.start_time).format('DD MMMM YYYY - hh:mm A')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-secondary lh-1" onClick={() => setShowParticipateModal(false)} ><span className="material-symbols-outlined">close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="post-item" style={{ maxHeight: "500px" }}>
                        {
                            memberList?.length > 0 ?
                                <ul className="request-list m-0 p-0 mt-0">
                                    <p className="mb-1 fs-4">List of Participants</p>
                                    {memberList.map((elm, i) => (
                                        <li key={elm?.id} className="d-flex align-items-center  flex-wrap">
                                            <div className="user-img img-fluid flex-shrink-0">
                                                <img src={elm?.member?.user_setting?.photo ? elm?.member?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                    alt="story-img" className="rounded-circle avatar-40" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <Link to={`/dashboard/app/friend-profile/${elm?.member?.slug}`}>
                                                    <h5>{elm?.member?.first_name && (elm?.member?.first_name + " " + elm?.member?.last_name)}</h5>
                                                </Link>
                                                {
                                                    elm?.payment_status == 1 ?
                                                        <Link to="#" className="me-3 rounded" style={{ color: "#51b4fb" }}
                                                            onClick={() => {
                                                                //addRemoveMember(eventDetails?.id, elm?.id, i, 0)
                                                            }}><i class="ri-checkbox-circle-fill"></i>Paid</Link> :
                                                        <Link to="#" className="me-3 rounded" style={{ color: "#c71e1e" }}
                                                            onClick={() => {
                                                                //addRemoveMember(eventDetails?.id, elm?.id, i, 1)
                                                            }}><i class="ri-indeterminate-circle-fill"></i> Unpaid</Link>
                                                }
                                            </div>
                                            <div className="d-flex align-items-center mt-2 mt-md-0">
                                                {
                                                    elm?.is_added == 0 ?
                                                        <Link to="#" className="me-3 btn btn-secondary rounded"
                                                            onClick={() => {
                                                                addRemoveMember(eventDetails?.id, elm?.member_id, i, 1)
                                                            }}>Removed</Link> :
                                                        <Link to="#" className="me-3 btn btn-primary rounded"
                                                            onClick={() => {
                                                                addRemoveMember(eventDetails?.id, elm?.member_id, i, 0)
                                                            }}>Remove</Link>
                                                }
                                            </div>
                                        </li>
                                    ))}
                                    {
                                        page < maxPage ?
                                            <li className="d-block text-center mb-0 pb-3">
                                                <Link to="#" className="me-3 btn btn-primary"
                                                    onClick={() => {
                                                        getMembersList(page + 1)
                                                        setPage(page + 1)
                                                    }}>View More Request</Link>
                                            </li> : null
                                    }
                                </ul> : <NoDataFound />
                        }
                        {
                            showMoreDataLoader ?
                                <div className="col-sm-12 text-center">
                                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                                </div> : null
                        }
                    </div>
                </Modal.Body>
                {/* <ModalFooter>
                                    <div className="w-100">
                                        <CommentBox postId={elm?.id} commentId={""} />
                                    </div>
                                </ModalFooter> */}
            </Modal>
        </>
    )
}

export default ParticipatedMemberModal
