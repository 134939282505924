import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import ReactFsLightbox from 'fslightbox-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
//import VideoThumbnail from 'react-video-thumbnail';

import playButton from '../../assets/images/icon/play_button.png'

const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;

const GalleryVideo = ({ galleryVideoList }) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [storiesList, setStoriesList] = useState([]);
    const [storiesMediaList, setStoriesMediaList] = useState([]);
    const [storiesMediaTypeList, setStoriesMediaTypeList] = useState([]);

    const [imageController, setImageController] = useState({
        toggler: false,
        slide: 1
    });
    function imageOnSlide(number) {
        setImageController({
            toggler: !imageController.toggler,
            slide: number
        });
    }

    useEffect(() => {
        //createMedia()
    }, [])

    async function createMedia() {
        galleryVideoList.map((image, i) => {
            if (!storiesMediaList.includes(image?.image_path)) {
                storiesMediaList.push(image?.image_path)
                setStoriesMediaList(storiesMediaList)

                storiesMediaTypeList.push("video")
                setStoriesMediaTypeList(storiesMediaTypeList)

                // if (image?.media_type === 1) {
                //     storiesMediaTypeList.push("video")
                //     setStoriesMediaTypeList(storiesMediaTypeList)
                // } else {
                //     storiesMediaTypeList.push("image")
                //     setStoriesMediaTypeList(storiesMediaTypeList)
                // }
            }
        })
        console.log("arrayVideoList", storiesMediaList)
    }

    return (
        <>
            <div>
                {
                    storiesMediaList?.length > 0 ?
                        <FsLightbox
                            toggler={imageController.toggler}
                            sources={storiesMediaList}
                            slide={imageController.slide}
                            types={storiesMediaTypeList}
                        />
                        : null
                }

                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                    <Masonry columnsCount={3} gutter="10px">
                        {galleryVideoList.map((image, i) => (
                            <div key={image?.id} onClick={() => {
                                //createMedia()
                                imageOnSlide(i + 1)
                            }}>
                                <div className="img-fluid" style={{ width: 200, height: 200 }} >
                                    <img style={{ height: 200, width: 200, marginRight: "17px", }} loading="lazy"
                                        src={image?.thumb} alt="gallary" className="img-fluid" />
                                    {/* <VideoThumbnail
                                        width={200}
                                        height={200}
                                        videoUrl={image?.image_path} /> */}
                                </div>
                                <Link style={{ position: "absolute", marginTop: "-123px", marginLeft: "70px", zIndex: 1 }}>
                                    <img style={{ height: 50, width: 50 }} src={playButton} />
                                </Link>
                            </div>
                            // image?.media_type === 1 ?
                            //     <div onClick={() => {
                            //         imageOnSlide(i + 1)
                            //     }}>
                            //         <div className="img-fluid" style={{ width: 200, height: 200 }} >
                            //             <VideoThumbnail
                            //                 width={200}
                            //                 height={200}
                            //                 videoUrl={image?.image_path} />
                            //         </div>
                            //         <Link style={{ position: "absolute", marginTop: "-123px", marginLeft: "70px", zIndex: 1 }}>
                            //             <img style={{ height: 50, width: 50 }} src={playButton} />
                            //         </Link>
                            //     </div> :
                            //     <img loading="lazy" src={image?.image_path}
                            //         alt="post" className="img-fluid w-100"
                            //         onClick={() => {
                            //             imageOnSlide(i + 1)
                            //         }} />
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
                {/* <Card.Body className="p-0">
                    <div className="d-grid gap-2 d-grid-template-1fr-13">
                        {
                            galleryImageList?.map((elm, i) => (
                                <div>
                                    <div className="user-images position-relative overflow-hidden">
                                        <Link onClick={() => imageOnSlide(10)} to="#">
                                            <img loading="lazy" src={elm?.image_path} className="img-fluid rounded" alt="Responsive" />
                                        </Link>
                                        <div className="image-hover-data">
                                            <div className="product-elements-icon">
                                                <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                    <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                    <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                    <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                        forward
                                                    </span> </Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Card.Body> */}

            </div>
        </>
    )
}

export default GalleryVideo
