import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter, Form } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { GROUP_ADD_MEMBER, GROUP_DELETE, GROUP_MEMBERS, GROUP_REMOVE_MEMBER } from '../../api/constants'
import Swal from 'sweetalert2';
import Loader from "react-js-loader";
import Cropper from 'react-easy-crop'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

import getCroppedImg from '../../utilities/crop-image'
//import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import img7 from '../../assets/images/page-img/profile-bg7.jpg'

const GroupMemberModal = ({ showGroupModalShow, setShowGroupModalShow, selectedMemberDetails }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [membersList, setMembersList] = useState([]);
    const [totalMembers, setTotalMembers] = useState(0);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);


    useEffect(() => {
        getGroupMembers()
    }, [])

    async function pageRefreshFunc(){
        window.location = '/chat/index';
    }

    async function getGroupMembers() {
        try {
            const res = await apiHandler(GROUP_MEMBERS, 'POST', {
                page: page,
                limit: 10,
                group_id: selectedMemberDetails?.group_id
            });
            if (res.data?.response?.status === 200) {
                setTotalMembers(res?.data?.data?.group_members?.meta?.total)
                setMaxPage(res?.data?.data?.group_members?.meta?.last_page)
                setMembersList(res?.data?.data?.group_members?.data)
            } else {
                setMembersList([])
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function removeGroupMemberApi(groupId, memberId, index, addedVal) {
        try {
            const res = await apiHandler(GROUP_REMOVE_MEMBER, 'DELETE', {
                group_id: groupId,
                member_id: memberId
            });
            if (res.data?.response?.status === 200) {
                let temporaryarray = setMembersList.slice();
                temporaryarray[index]['is_added'] = addedVal;
                setMembersList(temporaryarray);
            } else {
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function addGroupMemberApi(groupId, memberId, index, addedVal) {
        try {
            const res = await apiHandler(GROUP_ADD_MEMBER, 'POST', {
                group_id: groupId,
                member_id: memberId
            });
            if (res.data?.response?.status === 200) {
                let temporaryarray = setMembersList.slice();
                temporaryarray[index]['is_added'] = addedVal;
                setMembersList(temporaryarray);
            } else {
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function deleteGroupApi(groupId) {
        try {
            const res = await apiHandler(GROUP_DELETE, 'DELETE', {
                group_id: groupId
            });
            if (res.data?.response?.status === 200) {
                pageRefreshFunc()
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    return (
        <>
            <Modal scrollable={true} show={showGroupModalShow} onHide={() => setShowGroupModalShow(false)} size="lg" style={{ marginTop: 80 }}>
                <Modal.Header className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        <div className="w-100">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div className="me-3">
                                    <img loading="lazy" className="rounded-circle  avatar-60"
                                        src={selectedMemberDetails?.user_group?.image ? selectedMemberDetails?.user_group?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                </div>
                                <div>
                                    <h4 className="mb-0 d-inline-block"><Link to="#">{selectedMemberDetails?.user_group?.name}</Link></h4>
                                    <p className="mb-0">{totalMembers+" Group Members"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-secondary lh-1" onClick={() => setShowGroupModalShow(false)} ><span className="material-symbols-outlined">close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="post-item" style={{ maxHeight: "500px" }}>
                        <ul className="request-list m-0 p-0">
                            {membersList.map((elm, i) => (
                                <li key={elm?.id} className="d-flex align-items-center  flex-wrap">
                                    <div className="user-img img-fluid flex-shrink-0">
                                        <img src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                            alt="story-img" className="rounded-circle avatar-40" />
                                    </div>
                                    <div className="flex-grow-1 ms-3 d-flex align-items-center list-inline">
                                        <Link to={`/dashboard/app/friend-profile/${elm?.slug}`}>
                                            <h5>{elm?.user?.first_name && (elm?.user?.first_name + " " + elm?.user?.last_name)}</h5>
                                        </Link>
                                        {
                                            elm?.is_owner === 1 ?
                                                <h6 className="mb-0 d-inline-block bg-soft-primary" style={{ padding: "5px", marginLeft: "10px" }}>
                                                    <Link to="#">Admin</Link>
                                                </h6> : null
                                        }

                                        {/* <p className="mb-0">{elm?.follower_count + " Followers " + " ● " + elm?.following_count + " Following "}</p> */}
                                    </div>
                                    {
                                        profileData?.id === selectedMemberDetails?.user_group?.user_id ?
                                            elm?.is_owner === 1 ?
                                                <div className="d-flex align-items-center mt-2 mt-md-0">
                                                    <Link to="#" className="me-3 btn btn-primary rounded"
                                                        onClick={() => {
                                                            Swal.fire({
                                                                title: 'Warning!',
                                                                text: 'Are you sure, want to delete this ' + selectedMemberDetails?.user_group?.name + '?',
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                showConfirmButton: true,
                                                                cancelButtonText: 'Cancel',
                                                                confirmButtonText: 'Confirm',
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    deleteGroupApi(selectedMemberDetails?.group_id)
                                                                }
                                                            })
                                                        }}><i className="ri-group-line me-1"></i>Delete Group</Link> :
                                                </div> :
                                                <div className="d-flex align-items-center mt-2 mt-md-0">
                                                    {
                                                        elm?.is_added == 0 ?
                                                            <Link to="#" className="me-3 btn btn-primary rounded"
                                                                onClick={() => {
                                                                    addGroupMemberApi(selectedMemberDetails?.group_id, elm?.id, i, 1)
                                                                }}><i className="ri-user-add-line me-1"></i>Add</Link> :
                                                            <Link to="#" className="me-3 btn btn-primary rounded"
                                                                onClick={() => {
                                                                    removeGroupMemberApi(selectedMemberDetails?.group_id, elm?.id, i, 0)
                                                                }}>Remove</Link>
                                                    }
                                                </div> : null
                                    }
                                </li>
                            ))}
                            {
                                page < maxPage ?
                                    <li className="d-block text-center mb-0 pb-3">
                                        <Link to="#" className="me-3 btn btn-primary"
                                            onClick={() => {
                                                setPage(page + 1)
                                                getGroupMembers(selectedMemberDetails?.group_id)
                                            }}>View More Request</Link>
                                    </li> : null
                            }
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GroupMemberModal
