import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Image, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter, Form } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { apiHandler } from '../../api/apihandler';
import { AD_EDIT, AD_LIST } from '../../api/constants'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { InView } from "react-intersection-observer";
import Swal from 'sweetalert2'

import img42 from '../../assets/images/page-img/42.png'
import img9 from '../../assets/images/small/img-1.jpg'
import img10 from '../../assets/images/small/img-2.jpg'
import LoaderModal from '../loaderModal';


const AdEditCard = ({ adPostId, showEditPostModal, handleClose, refreshApi }) => {

    const navigate = useNavigate();
    const [adTitle, setAdTitle] = useState("");
    const [adContent, setAdContent] = useState('');
    const [adDescription, setAdDescription] = useState("");
    const [adLink, setAdLink] = useState("");
    const [adButtonText, setAdButtonText] = useState("");
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
    }, []);

    function handlePostSubmit() {
        setShowLoader(true)
        if (adTitle != "" && adDescription != "" && adContent != "" && adLink != "" && adButtonText != "") {
            getAdPostEditApi()
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Please fillup the all fields!',
                icon: 'warning',
                confirmButtonText: 'Okay'
            })
            setShowLoader(false)
        }
    };

    async function getAdPostEditApi() {
        setShowLoader(true)
        try {
            const res = await apiHandler(AD_EDIT, "POST", {
                post_id: adPostId,
                content: adContent,
                ad_title: adTitle,
                ad_description: adDescription,
                ad_link: adLink,
                ad_button_text: adButtonText
            })
            if (res.data?.response?.status === 200) {
                refreshApi()
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            <Modal scrollable={true} show={showEditPostModal} onHide={handleClose} size="lg" style={{ marginTop: 100, maxHeight: "630px" }}>
                <Modal.Header className="d-flex justify-content-between">
                    <h4 className="modal-title" id="post-modalLabel">Edit Advertise</h4>
                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "650px" }}>
                    <div>
                        <Row className="align-items-center">
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adTitle" className="form-label">Ad Title</Form.Label>
                                <Form.Control type="text" className="mb-0" id="adTitle" placeholder="Ad Title"
                                    value={adTitle} onChange={(e) => setAdTitle(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adLink" className="form-label">Ad Link</Form.Label>
                                <Form.Control type="text" className="mb-0" id="adLink" placeholder="Ad Link"
                                    value={adLink} onChange={(e) => setAdLink(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adContent" className="form-label">Ad Content</Form.Label>
                                <Form.Control type="text" className="mb-0" id="adContent" placeholder="Ad Content"
                                    value={adContent} onChange={(e) => setAdContent(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adButtonText" className="form-label">Ad Button Text</Form.Label>
                                <Form.Control type="text" className="mb-0" id="btnText" placeholder="Ad Button Text"
                                    value={adButtonText} onChange={(e) => setAdButtonText(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                                <Form.Label htmlFor="adDescription" className="form-label">Ad Description</Form.Label>
                                <Form.Control type="text" className="mb-0" id="description" placeholder="Ad Description"
                                    value={adDescription} onChange={(e) => setAdDescription(e.target.value)} />
                            </Form.Group>
                        </Row>
                    </div>
                    <Button variant="primary" className="d-block w-100 mt-3"
                        onClick={() => {
                            handlePostSubmit()
                        }}>Update Advertise</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdEditCard