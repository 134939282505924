import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Image, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import BlogCommentBox from './blogCommentBox';
import BlogCommentList from './blogCommentList';

const BlogCommentModal = ({ elm, showCommentModal, handleClose }) => {
    const navigate = useNavigate();
    const [refreshCommentList, setRefreshCommentList] = useState(false);

    // useEffect(() => {

    // }, []);

    return (
        <>
            <Modal scrollable={true} show={showCommentModal} onHide={handleClose} size="lg" style={{ marginTop: "80px", maxHeight: "630px" }}>
                <Modal.Header className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        {/* <div className="me-3">
                                        <img loading="lazy" className="rounded-circle  avatar-40"
                                            src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                    </div> */}
                        <div className="w-100">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <h5 className="mb-0 d-inline-block"><Link to="#">{elm?.title}</Link></h5>
                                    <p className="mb-0">{Moment(elm?.created_at).format('DD MMMM YYYY')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-secondary lh-1" onClick={() => handleClose()} ><span className="material-symbols-outlined">close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="post-item" style={{ maxHeight: "500px" }}>
                        {
                            elm?.body ?
                                <div className="mt-1">
                                    <p>{elm?.body}</p>
                                </div> : null
                        }
                        {/* <div className="user-post">
                                        <Link to="#">
                                            <ResponsiveMasonry>
                                                <Masonry columnsCount={3} gutter="10px">
                                                    {elm?.postimages.map((image, i) => (
                                                        image?.file_type === "video" ?
                                                            <div key={image?.id} >
                                                                <div className="img-fluid" style={{ width: 200, height: 200 }} >
                                                                    <img style={{ height: 200, width: 200, marginRight: "17px", }} loading="lazy"
                                                                        src={image?.file_name} alt="gallary" className="img-fluid" />
                                                                </div>
                                                                <Link style={{ position: "absolute", marginTop: "-123px", marginLeft: "70px", zIndex: 1 }}>
                                                                    <img style={{ height: 50, width: 50 }} src={playButton} />
                                                                </Link>
                                                            </div> :
                                                            <img key={image?.id} loading="lazy" src={image?.file_name}
                                                                alt="post" className="img-fluid w-100" />
                                                    ))}
                                                </Masonry>
                                            </ResponsiveMasonry>
                                        </Link>
                                    </div>
                                    <div className="like-block position-relative d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div style={{ marginTop: "10px" }}>
                                                <h6 className="mb-0 d-inline-block" style={{ cursor: "pointer" }}
                                                    onClick={() => { getBlogLike() }}>
                                                    {
                                                        elm?.is_like === 1 ?
                                                            <img loading="lazy" src={likeFillIcon} alt="icon" className="img-fluid" style={{ marginTop: "-2px" }} /> :
                                                            <img loading="lazy" src={likeIcon} alt="icon" className="img-fluid" style={{ marginTop: "-5px" }} />
                                                    }

                                                    {
                                                        elm?.is_like === 1 ?
                                                            likeCount > 1 ?
                                                                <Link to="#">You and {(likeCount - 1) + " "} others</Link> :
                                                                <Link to="#">You like</Link> :
                                                            <Link to="#" style={{ marginLeft: "6px" }}>{likeCount + " "}like</Link>
                                                    }
                                                </h6>
                                                <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                    <Link to="#" onClick={handleShow}
                                                    >{elm?.comment?.length + " "}Comment</Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </div> */}
                        {
                            elm?.comment_count > 0 ?
                                <BlogCommentList postId={elm?.id} refreshCommentList={refreshCommentList} setRefreshCommentList={setRefreshCommentList} />
                                : null
                        }
                    </div>
                </Modal.Body>
                <ModalFooter>
                    <div className="w-100">
                        <BlogCommentBox postId={elm?.id} commentId={""} setRefreshCommentList={setRefreshCommentList} />
                    </div>
                </ModalFooter>
            </Modal>

            {/* <Col lg="12">
                <Card className="card-block card-stretch card-height blog-list list-even">
                    <Card.Body>
                        <Row className="align-items-center">
                            <Col md="6">
                                <div className="blog-description rounded p-2">
                                    <div className="date mb-2">
                                        <Link to="#" tabIndex="-1">4 Month ago</Link>
                                    </div>
                                    <h5 className="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <Link to="#" tabIndex="-1" className="d-flex align-items-center">Read More<i className="material-symbols-outlined md-14 filled">arrow_forward_ios</i></Link>
                                    <div className="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between position-right-side">
                                        <div className="iq-media-group">
                                            <Link to="#" className="iq-media">
                                                <Image className="img-fluid rounded-circle" src={icon1} alt="" />
                                            </Link>
                                            <Link to="#" className="iq-media">
                                                <Image className="img-fluid rounded-circle" src={icon2} alt="" />
                                            </Link>
                                            <Link to="#" className="iq-media">
                                                <Image className="img-fluid rounded-circle" src={icon3} alt="" />
                                            </Link>
                                            <Link to="#" className="iq-media">
                                                <Image className="img-fluid rounded-circle" src={icon4} alt="" />
                                            </Link>
                                        </div>
                                        <div className="comment d-flex align-items-center"><i className="material-symbols-outlined me-2 md-18">chat_bubble_outline</i>7 comments</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="image-block">
                                    <Image src={blog2} className="img-fluid rounded w-100" alt="blog-img" />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col> */}
        </>
    )
}

export default BlogCommentModal