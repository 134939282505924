import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Container, Button, Form, Modal, Dropdown } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Moment from 'moment';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { apiHandler } from '../../../api/apihandler';
import { BLOG_DETAILS, BLOG_LIKE, BLOG_LIKES, BLOG_LIST } from '../../../api/constants'
import { InView } from "react-intersection-observer";
import Swal from 'sweetalert2'
import LoaderModal from '../../../components/loaderModal';

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

// img
import blog9 from '../../../assets/images/blog/01.jpg'
import blog3 from '../../../assets/images/blog/03.jpg'
import blog4 from '../../../assets/images/blog/04.jpg'
import blog5 from '../../../assets/images/blog/05.jpg'
import blog6 from '../../../assets/images/blog/06.jpg'
import blog7 from '../../../assets/images/blog/07.jpg'
import blog8 from '../../../assets/images/blog/08.jpg'

import user1 from '../../../assets/images/user/01.jpg'
import user2 from '../../../assets/images/user/02.jpg'
import user3 from '../../../assets/images/user/03.jpg'
import user4 from '../../../assets/images/user/04.jpg'
import playButton from '../../../assets/images/icon/play_button.png'
import BlogCommentModal from '../../../components/blog/blogCommentModal';
import likeFillIcon from '../../../assets/images/icon/like_fill_icon.png'
import likeIcon from '../../../assets/images/icon/like_icon.png'

const BlogDetail = () => {
   const profileData = JSON.parse(useSelector((state) => state.setting.setting.user_data));
   const { slug } = useParams();
   const navigate = useNavigate();
   const [blogDetails, setBlogDetails] = useState();
   const [blogList, setBlogList] = useState([]);
   const [showCommentModal, setShowCommentModal] = useState(false);
   const [likesList, setLikesList] = useState([]);
   const [showLikesList, setShowLikesList] = useState(false);
   const [showLoader, setShowLoader] = useState(false);

   const handleClose = () => {
      setShowCommentModal(false)
   }
   const handleShow = () => {
      setShowCommentModal(true);
   }

   useEffect(() => {
      if (localStorage.getItem("uzoneAuthToken")) {
         getBlogDetails()
         getBlogList()
      } else {
         navigate('/auth/sign-in')
      }
   }, [slug]);

   async function getBlogDetails() {
      try {
         const res = await apiHandler(BLOG_DETAILS, "POST", {
            user_id: profileData?.id,
            slug: slug,
         })
         if (res.data?.response?.status === 200) {
            setBlogDetails(res?.data?.data?.blogdetails)
            getBlogLikesList(res?.data?.data?.blogdetails?.id)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   async function getBlogList() {
      try {
         const res = await apiHandler(BLOG_LIST, "POST", {
            page: 1,
            limit: 10
         })
         if (res.data?.response?.status === 200) {
            // setBlogList(res?.data?.data?.blogs?.data)
            let temporaryarray = res?.data?.data?.blogs?.data
            for (var i = 0; i < temporaryarray?.length; i++) {
               if (temporaryarray[i]?.slug === slug) {
                  temporaryarray.splice(i, 1)
               }
            }
            setBlogList(temporaryarray);
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   async function getBlogLikesList(blogId) {
      try {
         const res = await apiHandler(BLOG_LIKES, "POST", {
            blog_id: blogId,
            page: 1,
            limit: 100
         })
         if (res.data?.response?.status === 200) {
            setLikesList(res?.data?.data?.bloglikes?.data)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowLoader(false)
      }
   }

   async function getBlogLike() {
      setShowLoader(true)
      try {
         const res = await apiHandler(BLOG_LIKE, "POST", {
            blog_id: blogDetails?.id
         })
         if (res.data?.response?.status === 200) {
            if (blogDetails?.is_like === 1) {
               blogDetails['is_like'] = 0
               blogDetails['like_count'] = blogDetails?.like_count - 1
            } else {
               blogDetails['is_like'] = 1
               blogDetails['like_count'] = blogDetails?.like_count + 1
            }
         } else {
            Swal.fire({
               title: 'Error',
               text: res?.response?.data?.data?.message,
               icon: 'error',
               confirmButtonText: 'Okay'
            })
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowLoader(false)
      }
   }

   return (
      <>
         <LoaderModal showLoader={showLoader} />
         <div id='content-page' className='content-page'>
            <Container>
               <Row>
                  <Col lg="8">
                     <Card className="card-block card-stretch card-height blog blog-detail">
                        <Card.Body>
                           <div className="image-block">
                              {/* <Image src={blog9} className="img-fluid rounded w-100" alt="blog-img"/>   */}
                              <Link to="#" >
                                 {
                                    blogDetails?.postimages?.length === 1 ?
                                       <ResponsiveMasonry
                                          columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 1 }}>
                                          <Masonry columnsCount={3} gutter="10px">
                                             {blogDetails?.postimages.map((image, i) => (
                                                image?.file_type === "video" ?
                                                   <div key={image?.id} onClick={() => {
                                                      //imageOnSlide(i + 1)
                                                   }}>
                                                      <div className="img-fluid" style={{ position: "relative" }} >
                                                         <img loading="lazy"
                                                            src={image?.file_name} alt="gallary" className="img-fluid" />
                                                      </div>
                                                      <Link style={{ position: "absolute", left: "47%", top: "47%", zIndex: 1 }}>
                                                         <img style={{ height: 50, width: 50 }} src={playButton} />
                                                      </Link>
                                                   </div> :
                                                   <img key={image?.id} loading="lazy" src={image?.file_name}
                                                      alt="post" className="img-fluid w-100"
                                                      onClick={() => {
                                                         //imageOnSlide(i + 1)
                                                      }} />

                                             ))}
                                          </Masonry>
                                       </ResponsiveMasonry> :
                                       blogDetails?.postimages?.length === 2 ?
                                          <ResponsiveMasonry
                                             columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 2 }}>
                                             <Masonry columnsCount={3} gutter="10px">
                                                {blogDetails?.postimages.map((image, i) => (
                                                   image?.file_type === "video" ?
                                                      <div key={image?.id} onClick={() => {
                                                         //imageOnSlide(i + 1)
                                                      }} style={{ position: "relative" }}>
                                                         <div className="img-fluid">
                                                            <img loading="lazy"
                                                               src={image?.file_name} alt="gallary" className="img-fluid" />
                                                         </div>
                                                         <Link style={{ position: "absolute", left: "45%", top: "40%", zIndex: 1 }}>
                                                            <img style={{ height: 50, width: 50 }} src={playButton} />
                                                         </Link>
                                                      </div> :
                                                      <img key={image?.id} loading="lazy" src={image?.file_name}
                                                         alt="post" className="img-fluid w-100"
                                                         onClick={() => {
                                                            //imageOnSlide(i + 1)
                                                         }} />
                                                ))}
                                             </Masonry>
                                          </ResponsiveMasonry> :
                                          <ResponsiveMasonry
                                             columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                                             <Masonry columnsCount={3} gutter="10px">
                                                {blogDetails?.postimages.map((image, i) => (
                                                   image?.file_type === "video" ?
                                                      <div key={image?.id} onClick={() => {
                                                         //imageOnSlide(i + 1)
                                                      }} style={{ position: "relative" }}>
                                                         <div className="img-fluid">
                                                            <img loading="lazy"
                                                               src={image?.file_name} alt="gallary" className="img-fluid" />
                                                         </div>
                                                         <Link className="video-img-btn">
                                                            <img style={{ height: 50, width: 50 }} src={playButton} />
                                                         </Link>
                                                      </div> :
                                                      <img key={image?.id} loading="lazy" src={image?.file_name}
                                                         alt="post" className="img-fluid w-100"
                                                         onClick={() => {
                                                            //imageOnSlide(i + 1)
                                                         }} />

                                                ))}
                                             </Masonry>
                                          </ResponsiveMasonry>
                                 }

                              </Link>
                           </div>
                           <div className="blog-description mt-3">
                              <h5 className="mb-3 pb-3 border-bottom">{blogDetails?.title}</h5>
                              <div className="blog-meta d-flex align-items-center mb-3 position-right-side flex-wrap">
                                 <div className="date date me-4 d-flex align-items-center">
                                    <i className="material-symbols-outlined pe-2 md-18 text-primary">calendar_month</i>{Moment(blogDetails?.updated_at).format('DD MMMM YYYY')}
                                 </div>
                                 <div className="comments date me-4 d-flex align-items-center" style={{ cursor: "pointer" }}>
                                    {
                                       blogDetails?.is_like === 1 ?
                                          <img loading="lazy" src={likeFillIcon} alt="icon" className="img-fluid" style={{ marginTop: "-2px", cursor: "pointer" }}
                                             onClick={() => { getBlogLike() }} /> :
                                          <i className="material-symbols-outlined md-18 text-primary"
                                             onClick={() => { getBlogLike() }} >thumb_up_alt</i>
                                    }
                                    {
                                       blogDetails?.is_like === 1 ?
                                          blogDetails?.like_count > 1 ?
                                             <div
                                                onMouseEnter={() => setShowLikesList(true)}
                                                onMouseLeave={() => setShowLikesList(false)}>
                                                You and {((blogDetails?.like_count) - 1) + " "} others
                                             </div> :
                                             <div
                                                onMouseEnter={() => setShowLikesList(true)}
                                                onMouseLeave={() => setShowLikesList(false)}>
                                                You like
                                             </div> :
                                          <div style={{ marginLeft: "6px" }}
                                             onMouseEnter={() => setShowLikesList(true)}
                                             onMouseLeave={() => setShowLikesList(false)}>
                                             {blogDetails?.like_count + " "}like
                                          </div>
                                    }
                                    {
                                       showLikesList ?
                                          <div className="card-header-toolbar d-flex align-items-center" style={{ position: "absolute" }}
                                             onMouseEnter={() => setShowLikesList(true)}
                                             onMouseLeave={() => setShowLikesList(false)}>
                                             <Dropdown>
                                                <Card className="dropdown-menu-right cardBackgroundColor">
                                                   <div style={{ backgroundColor: "#e4f0f1", margin: "1px" }}>
                                                      {
                                                         likesList ?
                                                            likesList?.map((elm, i) => (
                                                               <>
                                                                  <Dropdown.Item
                                                                     key={elm?.id}
                                                                     href={`/dashboard/app/friend-profile/${elm?.user?.slug}`} >
                                                                     {elm?.user?.first_name + " " + elm?.user?.last_name}
                                                                  </Dropdown.Item>
                                                               </>
                                                            )) : null
                                                      }
                                                   </div>
                                                </Card>
                                             </Dropdown>
                                          </div> : null
                                    }
                                 </div>
                                 <div className="comments date me-4 d-flex align-items-center" style={{ cursor: "pointer" }}
                                    onClick={() => { handleShow() }}>
                                    <i className="material-symbols-outlined pe-2 md-18 text-primary">
                                       mode_comment
                                    </i>{blogDetails?.comment_count} comments
                                 </div>
                                 {/* <div className="share date me-4 d-flex align-items-center">
                                    <i className="material-symbols-outlined pe-2 md-18 text-primary">
                                       share
                                    </i>share
                                 </div> */}
                              </div>
                              <p>{blogDetails?.body}</p>
                              {/* <Link className="d-flex align-items-center" to="#" tabIndex="-1">Read More
                                 <i className="material-symbols-outlined md-14">arrow_forward_ios</i>
                              </Link> */}
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col lg="4">
                     <div>
                        <Card className="card-block card-stretch card-height blog-post">
                           <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                 <h4 className="card-title">Related Blogs</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <ul className="list-inline p-0 mb-0 mt-1">
                                 {
                                    blogList.map((elm, i) => (
                                       <>
                                          {
                                             blogList?.length - 1 === i ?
                                                <li >
                                                   <Row className="align-items-top">
                                                      <Col md="4">
                                                         <div className="image-block" >
                                                            <Image style={{ maxHeight: 90 }}
                                                               src={elm?.default_image?.file_name ? elm?.default_image?.file_name : "https://uzone360live.s3.amazonaws.com/noimage.png"} className="img-fluid rounded w-100" alt="blog-img" />
                                                         </div>
                                                      </Col>
                                                      <Col md="8">
                                                         <div className="blog-description mt-1 mt-md-0">
                                                            <div className="date mb-1">{Moment(elm?.updated_at).format('DD MMMM YYYY')}</div>
                                                            <h6>
                                                               <Link to={`/dashboard/blog/blog-detail/${elm?.slug}`} className="mb-2">{elm?.title}</Link>
                                                            </h6>
                                                         </div>
                                                      </Col>
                                                   </Row>
                                                </li> :
                                                <li className="mb-3">
                                                   <Row className="align-items-top pb-3 border-bottom">
                                                      <Col md="4">
                                                         <div className="image-block" >
                                                            <Image style={{ maxHeight: 90 }}
                                                               src={elm?.default_image?.file_name ? elm?.default_image?.file_name : "https://uzone360live.s3.amazonaws.com/noimage.png"} className="img-fluid rounded w-100" alt="blog-img" />
                                                         </div>
                                                      </Col>
                                                      <Col md="8">
                                                         <div className="blog-description mt-1 mt-md-0">
                                                            <div className="date mb-1">{Moment(elm?.updated_at).format('DD MMMM YYYY')}</div>
                                                            <h6>
                                                               <Link to={`/dashboard/blog/blog-detail/${elm?.slug}`} className="mb-2">{elm?.title}</Link>
                                                            </h6>
                                                         </div>
                                                      </Col>
                                                   </Row>
                                                </li>
                                          }
                                       </>

                                    ))
                                 }
                              </ul>
                           </Card.Body>
                        </Card>
                     </div>
                  </Col>
                  {/* <Col lg="12">
                     <Card className="card-block card-stretch card-height blog user-comment">
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">User Comment</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <Row>
                              <Col lg="12">
                                 <Card className="card-block card-stretch card-height blog">
                                    <Card.Body>
                                       <div className="d-flex align-items-center">
                                          <div className="user-image mb-3">
                                             <Image className="avatar-80 rounded" src={user1} alt="#" data-original-title="" title="" />
                                          </div>
                                          <div className="ms-3">
                                             <h5>Kaya Scodelario</h5>
                                             <p>Web Developer</p>
                                          </div>
                                       </div>
                                       <div className="blog-description">
                                          <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p>
                                          <div className="d-flex align-items-center justify-content-between mb-2 position-right-side">
                                             <Link to="#" className="comments d-flex align-items-center"><i className="material-symbols-outlined pe-2 md-18 text-primary">
                                                mode_comment
                                             </i>82 comments</Link>
                                             <span className="font-size-12 text-warning">
                                                <i className="material-symbols-outlined md-18">star_half</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                             </span>
                                          </div>
                                       </div>
                                    </Card.Body>
                                 </Card>
                              </Col>
                              <Col lg="12" className="ps-0 ps-md-5">
                                 <Card className="card-block card-stretch card-height blog">
                                    <Card.Body>
                                       <div className="d-flex align-items-center">
                                          <div className="user-image mb-3">
                                             <Image className="avatar-80 rounded" src={user2} alt="#" data-original-title="" title="" />
                                          </div>
                                          <div className="ms-3">
                                             <h5>Tom Cruise</h5>
                                             <p>Web Designer</p>
                                          </div>
                                       </div>
                                       <div className="blog-description">
                                          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum Many desktop publishing packages and web page editors now use Lorem Ipsum.</p>
                                          <div className="d-flex align-items-center justify-content-between mb-2 position-right-side">
                                             <Link to="#" className="comments d-flex align-items-center"><i className="material-symbols-outlined pe-2 md-18 text-primary">
                                                mode_comment
                                             </i>98 comments</Link>
                                             <span className="font-size-12 text-warning">
                                                <i className="material-symbols-outlined md-18">star_half</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                             </span>
                                          </div>
                                       </div>
                                    </Card.Body>
                                 </Card>
                              </Col>
                              <Col lg="12">
                                 <Card className="card-block card-stretch card-height blog">
                                    <Card.Body>
                                       <div className="d-flex align-items-center">
                                          <div className="user-image mb-3">
                                             <Image className="avatar-80 rounded" src={user3} alt="#" data-original-title="" title="" />
                                          </div>
                                          <div className="ms-3">
                                             <h5>Walter Hucko</h5>
                                             <p>Web Designer</p>
                                          </div>
                                       </div>
                                       <div className="blog-description">
                                          <p>TThere are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                          <div className="d-flex align-items-center justify-content-between mb-2 position-right-side">
                                             <Link to="#" className="comments d-flex align-items-center"><i className="material-symbols-outlined pe-2 md-18 text-primary">
                                                mode_comment
                                             </i>28 comments</Link>
                                             <span className="font-size-12 text-warning">
                                                <i className="material-symbols-outlined md-18">star_half</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                             </span>
                                          </div>
                                       </div>
                                    </Card.Body>
                                 </Card>
                              </Col>
                              <Col lg="12" className="ps-0 ps-md-5">
                                 <Card className="card-block card-stretch card-height blog">
                                    <Card.Body>
                                       <div className="d-flex align-items-center">
                                          <div className="user-image mb-3">
                                             <Image className="avatar-80 rounded" src={user4} alt="#" data-original-title="" title="" />
                                          </div>
                                          <div className="ms-3">
                                             <h5>Mark Walton</h5>
                                             <p>Web Manager</p>
                                          </div>
                                       </div>
                                       <div className="blog-description">
                                          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.randomised words which don't look even slightly believable variations of passages.</p>
                                          <div className="d-flex align-items-center justify-content-between mb-2 position-right-side">
                                             <Link to="#" className="comments d-flex align-items-center"><i className="material-symbols-outlined pe-2 md-18 text-primary">
                                                mode_comment
                                             </i>98 comments</Link>
                                             <span className="font-size-12 text-warning">
                                                <i className="material-symbols-outlined md-18">star_half</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                                <i className="material-symbols-outlined md-18">star</i>
                                             </span>
                                          </div>
                                       </div>
                                    </Card.Body>
                                 </Card>
                              </Col>
                              <Col lg="12">
                                 <Card className="card-block card-stretch card-height blog mb-0">
                                    <Card.Header className="d-flex justify-content-between">
                                       <div className="header-title">
                                          <h4 className="card-title">Your Comment</h4>
                                       </div>
                                    </Card.Header>
                                    <Card.Body>
                                       <Form>
                                          <Form.Group className="form-group">
                                             <Form.Label htmlFor="email1">Email address:</Form.Label>
                                             <Form.Control type="email" id="email1" />
                                          </Form.Group>
                                          <Form.Group className="form-group">
                                             <Form.Label htmlFor="pwd">Password:</Form.Label>
                                             <Form.Control type="password" id="pwd" />
                                          </Form.Group>
                                          <Form.Group className="form-group">
                                             <Form.Label htmlFor="exampleFormControlTextarea1">Comment</Form.Label>
                                             <Form.Control as="textarea" id="exampleFormControlTextarea1" rows={4} />
                                          </Form.Group>
                                          <Form.Check className="form-check mt-3 mb-3 w-100">
                                             <Form.Check.Input type="checkbox" id="remember-box" value="option1" />
                                             <Form.Check.Label htmlFor="remember-box">Remember me</Form.Check.Label>
                                          </Form.Check>
                                          <Button type="button" variant="btn btn-primary me-2 mb-3">Submit</Button>
                                          <Button type="button" variant="btn bg-soft-danger mb-3">Cancel</Button>
                                       </Form>
                                    </Card.Body>
                                 </Card>
                              </Col>
                           </Row>
                        </Card.Body>
                     </Card>
                  </Col> */}
               </Row>
               {
                  showCommentModal ?
                     <BlogCommentModal elm={blogDetails} showCommentModal={showCommentModal} handleClose={handleClose} /> : null
               }
            </Container>
         </div>
      </>
   )
}

export default BlogDetail