import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter, Form } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { GET_CONNECTED_USERS, GROUP_ADD_MEMBER, GROUP_DELETE, GROUP_MEMBERS, GROUP_REMOVE_MEMBER } from '../../api/constants'
import Swal from 'sweetalert2';
import Loader from "react-js-loader";
import Cropper from 'react-easy-crop'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

import getCroppedImg from '../../utilities/crop-image'
//import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import img7 from '../../assets/images/page-img/profile-bg7.jpg'
import NoDataFound from '../noDataFound';

const AddNewMemberModal = ({ showAddMemberModal, setShowAddMemberModal, groupDetails }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [userListForAddNewMember, setUserListForAddNewMember] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [searchContent, setSearchContent] = useState('');


    useEffect(() => {
        userListForAddNewMemberApi(1, "")
    }, [])

    async function userListForAddNewMemberApi(pageNo, searchContent) {
        try {
            const res = await apiHandler(GET_CONNECTED_USERS, 'POST', {
                group_id: groupDetails?.group_id,
                page: pageNo,
                limit: 10,
                search_text: searchContent
            });
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.users?.meta?.last_page)
                if (pageNo === 1) {
                    setUserListForAddNewMember(res?.data?.data?.users?.data)
                } else {
                    setUserListForAddNewMember(userListForAddNewMember.concat(res?.data?.data?.users?.data))
                }
            } else {
                //setUserListForAddNewMember([])
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function removeGroupMemberApi(groupId, memberId, index, addedVal) {
        try {
            const res = await apiHandler(GROUP_REMOVE_MEMBER, 'DELETE', {
                group_id: groupId,
                member_id: memberId
            });
            if (res.data?.response?.status === 200) {
                let temporaryarray = userListForAddNewMember.slice();
                temporaryarray[index]['is_added'] = addedVal;
                setUserListForAddNewMember(temporaryarray);
            } else {
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function addGroupMemberApi(groupId, memberId, index, addedVal) {
        try {
            const res = await apiHandler(GROUP_ADD_MEMBER, 'POST', {
                group_id: groupId,
                member_id: memberId
            });
            if (res.data?.response?.status === 200) {
                let temporaryarray = userListForAddNewMember.slice();
                temporaryarray[index]['is_added'] = addedVal;
                setUserListForAddNewMember(temporaryarray);
            } else {
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    return (
        <>
            <Modal scrollable={true} show={showAddMemberModal} onHide={() => setShowAddMemberModal(false)} size="md" style={{ marginTop: 80 }}>
                <Modal.Header className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        <div className="w-100">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div className="me-3">
                                    <img loading="lazy" className="rounded-circle  avatar-60"
                                        src={groupDetails?.user_group?.image ? groupDetails?.user_group?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                </div>
                                <div>
                                    <h4 className="mb-0 d-inline-block"><Link to="#">{groupDetails?.user_group?.name}</Link></h4>
                                    <p className="mb-0">+Add more members</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-secondary lh-1" onClick={() => setShowAddMemberModal(false)} ><span className="material-symbols-outlined">close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="post-item" style={{ maxHeight: "500px" }}>
                        <ul className="request-list m-0 p-0">
                            <div className="iq-todo-page">
                                <Form className="position-relative">
                                    <Form.Group className="form-group mb-0">
                                        <Form.Control
                                            type="text"
                                            className="form-control todo-search bg-soft-primary"
                                            id="exampleInputEmail002"
                                            placeholder="Search by name or email"
                                            value={searchContent} onChange={(e) => {
                                                setSearchContent(e.target.value)
                                                userListForAddNewMemberApi(1, e.target.value)
                                            }}
                                        />
                                        <Link className="search-link" to="#">
                                            <span className="material-symbols-outlined lh-1">
                                                search
                                            </span>
                                        </Link>
                                    </Form.Group>
                                </Form>
                            </div>
                            {userListForAddNewMember?.length > 0 ?
                                userListForAddNewMember.map((elm, i) => (
                                    <li key={elm?.id} className="d-flex align-items-center  flex-wrap mt-2">
                                        <div className="user-img img-fluid flex-shrink-0">
                                            <img src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                alt="story-img" className="rounded-circle avatar-40" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <Link to={`/dashboard/app/friend-profile/${elm?.slug}`}>
                                                <h5>{elm?.first_name && (elm?.first_name + " " + elm?.last_name)}</h5>
                                            </Link>
                                            {/* <p className="mb-0">{elm?.follower_count + " Followers " + " ● " + elm?.following_count + " Following "}</p> */}
                                        </div>
                                        <div className="d-flex align-items-center mt-2 mt-md-0">
                                            {
                                                elm?.is_added == 1 ?
                                                    <Link to="#" className="me-3 btn btn-secondary rounded"
                                                        onClick={() => {
                                                            removeGroupMemberApi(groupDetails?.group_id, elm?.id, i, 0)
                                                        }}>Added</Link> :
                                                    <Link to="#" className="me-3 btn btn-primary rounded"
                                                        onClick={() => {
                                                            addGroupMemberApi(groupDetails?.group_id, elm?.id, i, 1)
                                                        }}><i className="ri-user-add-line me-1"></i>Add</Link>
                                            }
                                            {/* <Link to="#" className="me-3 btn btn-primary rounded"
                                       onClick={() => {
                                          setPage(page + 1)
                                          getPeopleYouMayKnow()
                                       }}><i className="ri-user-add-line me-1"></i>Follow</Link> */}
                                            {/* <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Remove</Link> */}
                                        </div>
                                    </li>
                                )) : <NoDataFound />
                            }
                            {
                                page < maxPage ?
                                    <li className="d-block text-center mb-0 pb-3">
                                        <Link to="#" className="me-3 btn btn-primary"
                                            onClick={() => {
                                                userListForAddNewMemberApi(page + 1, searchContent)
                                                setPage(page + 1)
                                            }}>View More Request</Link>
                                    </li> : null
                            }
                        </ul>
                    </div>
                </Modal.Body>
                {/* <ModalFooter>
                                    <div className="w-100">
                                        <CommentBox postId={elm?.id} commentId={""} />
                                    </div>
                                </ModalFooter> */}
            </Modal>
        </>
    )
}

export default AddNewMemberModal
