import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { FEEDS_LIST, } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'
import FeedPostCard from './feedPostCard'
import LoaderModal from '../loaderModal'


const FeedsList = ({ userId }) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [feedsList, setFeedsList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        getFeedsList()
    }, [])

    async function getFeedsList() {
        setShowLoader(true)
        try {
            const res = await apiHandler(FEEDS_LIST, "POST", {
                user_id: userId,
                page: 1,
                limit: 100,
            })
            if (res.data?.response?.status === 200) {
                setFeedsList(res?.data?.data?.feeds?.data)
            } else {
                //navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            {
                feedsList?.map((elm, i) => (
                    <FeedPostCard elm={elm} getFeedsList={getFeedsList} key={elm?.id} feedDetails={false} />
                ))
            }
        </>
    )
}

export default FeedsList
