import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter, Form } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { handleImageUpload } from "../../api/uploads";
import { apiHandler } from '../../api/apihandler';
import { GROUP_CREATE, GROUP_PROFILE_IMAGE } from '../../api/constants'
import Swal from 'sweetalert2';
import Cropper from 'react-easy-crop'
import LoaderModal from '../loaderModal'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

import getCroppedImg from '../../utilities/crop-image'
//import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import img7 from '../../assets/images/page-img/profile-bg7.jpg'

const CreateNewGroup = ({ getGroupListApi }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [profileImage, setProfileImage] = useState('')
    const [groupName, setGroupName] = useState('')
    const [showLoader, setShowLoader] = useState(false);

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [showCroper, setShowCroper] = useState(false)
    const [imageForCrop, setImageForCrop] = useState("")
    const [croppedImage, setCroppedImage] = useState("")
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const handleShowCroper = () => {
        setShowCroper(true)
    }
    const handleCloseCroper = () => {
        setShowCroper(false)
    }

    const resizeComplete = async () => {
        await onCropComplete()
        await showCroppedImage()
    }
    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const showCroppedImage = async () => {
        try {
            const croppedImage1 = await getCroppedImg(
                imageForCrop,
                croppedAreaPixels,
                rotation
            )
            //console.log(croppedImage)
            //handleUpload(croppedImage)
            setCroppedImage(croppedImage1)
            setProfileImage(URL.createObjectURL(croppedImage1))
            handleCloseCroper()
        } catch (e) {
            console.error(e)
        }
    }

    const handleUpload = async (file) => {
        setShowCreateGroup(false)
        setShowLoader(true)
        try {
            const fileCon = new File([file], `cropped-image${Date.now()}.jpg`, { type: 'image/jpeg' });
            const url = await handleImageUpload(fileCon, "feedImages/", "image/jpeg")
            createNewGroupApi(url)
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        event.target.value = "";
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                handleShowCroper()
                setImageForCrop(reader.result)
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
    }, [])

    async function createNewGroupApi(url) {
        try {
            const res = await apiHandler(GROUP_CREATE, 'POST', {
                name: groupName,
                //path: url
            });
            if (res.data?.response?.status === 200) {
                groupProfileImageUpload(url, res?.data?.data?.group?.id)
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function groupProfileImageUpload(url, id) {
        try {
            const res = await apiHandler(GROUP_PROFILE_IMAGE, 'POST', {
                group_id: id,
                path: url
            });
            if (res.data?.response?.status === 200) {
                setShowLoader(false)
                getGroupListApi()
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader}/>
            <Card id="post-modal-data">
                <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">Create New Group</h4>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex align-items-center">
                        <div className="user-img">
                            <img src={profileData?.user_setting?.photo ? profileData?.user_setting?.photo :
                                "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="userimg" className="avatar-60 rounded-circle" />
                        </div>
                        <form className="post-text ms-3 w-100 " onClick={() => (setShowCreateGroup(true))}>
                            <input type="text" className="form-control rounded" placeholder="Group Name here..." style={{ border: "none" }} />
                        </form>
                    </div>
                </Card.Body>
                <Modal show={showCreateGroup} onHide={() => (setShowCreateGroup(false))} size="lg" style={{ marginTop: "100px" }}>
                    <Modal.Header className="d-flex justify-content-between" closeButton>
                        <h5 className="modal-title" id="post-modalLabel">Create New Group</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="form-group align-items-center">
                            <div className="profile-img-edit">
                                <img className="profile-pic" style={{ height: 150, width: 150 }}
                                    src={profileImage ? profileImage : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="profile-pic" />
                                <div className="p-image">
                                    <label htmlFor="imageUpload1">
                                        <i className="ri-pencil-line upload-button text-white" alt="preview image" />
                                    </label>
                                    <input className="file-upload"
                                        id="imageUpload1" type="file" accept="image/*"
                                        //multiple="multiple"
                                        onChange={
                                            handleImageChange
                                        }
                                    />
                                </div>
                            </div>
                        </Form.Group>
                        <div className="d-flex align-items-center">
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="gname" className="form-label">Group Name: *</Form.Label>
                                <Form.Control type="text" className="form-control" id="fname" placeholder="Group name here..."
                                    value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                            </Form.Group>
                        </div>
                        <Button variant="primary" className="d-block w-100 mt-3" onClick={() => {
                            if (groupName != "" && croppedImage != "") {
                                handleUpload(croppedImage)
                            } else {
                                Swal.fire({
                                    title: 'Error!',
                                    text: 'Select group image / enter group name',
                                    icon: 'error',
                                    confirmButtonText: 'Okay'
                                })
                            }
                        }}>Create Group</Button>
                    </Modal.Body>
                </Modal>

                <Modal show={showCroper} onHide={handleCloseCroper} size="lg" style={{ marginTop: 100 }}>
                    <Modal.Header className="d-flex justify-content-between">
                        <h5 className="modal-title" id="post-modalLabel">Resize Profile Image </h5>
                        <button type="button" className="btn btn-secondary lh-1" onClick={handleCloseCroper} ><span className="material-symbols-outlined">close</span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center" style={{ height: "300px" }}>
                            <Cropper
                                image={imageForCrop}
                                crop={crop}
                                zoom={zoom}
                                aspect={4 / 4}
                                cropShape={"rect"}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className="d-block w-100 mt-3"
                            onClick={() => resizeComplete()}
                        >Crop Image</Button>
                    </Modal.Footer>
                </Modal>
            </Card>
        </>
    )
}

export default CreateNewGroup
