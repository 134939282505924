import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Modal, Tab, Nav } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { apiHandler } from '../../../../api/apihandler';
import { MY_TICKETS } from "../../../../api/constants";
import { InView } from "react-intersection-observer";

//profile-header
import ProfileHeader from '../../../../components/profile-header'

// images
import img6 from '../../../../assets/images/page-img/profile-bg6.jpg'
import loader from '../../../../assets/images/page-img/page-load-loader.gif'
import MyEventCard from '../../../../components/event/myEventCard';
import MyTicketsCard from '../../../../components/event/myTicketsCard';
import NoDataFound from '../../../../components/noDataFound';
import MyTicketsList from '../../../../components/event/myTicketsList';

const MyTickets = () => {
    const navigate = useNavigate();
    const [upcomingTicketsList, setUpcomingTicketsList] = useState([]);
    const [upcomingPage, setUpcomingPage] = useState(1);
    const [upcomingMaxPage, setUpcomingMaxPage] = useState(1);
    const [pastTicketsList, setPastTicketsList] = useState([]);
    const [pastPage, setPastPage] = useState(1);
    const [pastMaxPage, setPastMaxPage] = useState(1);
    const [isPast, setIsPast] = useState(false);
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("uzoneAuthToken")) {
            getUpcomingTicketsList(1)
        } else {
            navigate('/auth/sign-in')
        }
    }, []);

    useEffect(() => {
        if (isPast)
           pastRefreshApi()
        else
           upcomingRefreshApi()
     }, [isPast]);

    function upcomingRefreshApi() {
        setUpcomingPage(1)
        getUpcomingTicketsList(1)
    }

    const upcomingLoadMore = (inView) => {
        if (inView) {
            if (upcomingPage < upcomingMaxPage) {
                getUpcomingTicketsList(upcomingPage + 1)
                setUpcomingPage(upcomingPage + 1)
            }
        }
    };

    function pastRefreshApi() {
        setPastPage(1)
        getPastTicketsList(1)
    }

    const pastLoadMore = (inView) => {
        if (inView) {
            if (pastPage < pastMaxPage) {
                getPastTicketsList(pastPage + 1)
                setPastPage(pastPage + 1)
            }
        }
    };

    async function getUpcomingTicketsList(pageVal) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(MY_TICKETS, "POST", {
                page: pageVal,
                limit: 10,
                type: "upcoming"
            })
            if (res.data?.response?.status === 200) {
                setUpcomingMaxPage(res?.data?.data?.myTickets?.meta?.last_page)
                if (res?.data?.data?.myTickets?.meta?.total > 0) {
                    if (pageVal === 1) {
                        setUpcomingTicketsList(res?.data?.data?.myTickets?.data)
                    } else {
                        setUpcomingTicketsList(upcomingTicketsList.concat(res?.data?.data?.myTickets?.data))
                    }
                }
            } else {
                //navigate('/auth/sign-in')
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }

    async function getPastTicketsList(pageVal) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(MY_TICKETS, "POST", {
                page: pageVal,
                limit: 10,
                type: "past"
            })
            if (res.data?.response?.status === 200) {
                setPastMaxPage(res?.data?.data?.myTickets?.meta?.last_page)
                if (res?.data?.data?.myTickets?.meta?.total > 0) {
                    if (pageVal === 1) {
                        setPastTicketsList(res?.data?.data?.myTickets?.data)
                    } else {
                        setPastTicketsList(pastTicketsList.concat(res?.data?.data?.myTickets?.data))
                    }
                }
            } else {
                //navigate('/auth/sign-in')
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }



    return (
        <>
            <ProfileHeader title="My Tickets" img={img6} />
            <div id="content-page" className="content-page">
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Card className="p-0">
                            <Card.Body className="p-0">
                                <div className="user-tabing">
                                    <Nav as="ul" variant="pills" className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                                        <Nav.Item as="li" className=" col-12 col-sm-6 p-0 " onClick={()=> setIsPast(false)}>
                                            <Nav.Link href="#upcomming-tab" eventKey="first" role="button" className=" text-center p-3">Upcoming Event Tickets</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li" className="col-12 col-sm-6 p-0" onClick={()=> setIsPast(true)}>
                                            <Nav.Link href="#past-tab" eventKey="second" role="button" className="text-center p-3">Past Event Tickets</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Card.Body>
                        </Card>
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first" >
                                    <Card.Body className=" p-0">
                                        <Row>
                                            <Col lg={12}>
                                                {/* <div className="d-grid gap-3 d-grid-template-1fr-19 mb-5"> */}
                                                <div className=" mb-5">
                                                    {
                                                        upcomingTicketsList?.length > 0 ? upcomingTicketsList.map((elm, i) => (
                                                            <MyTicketsList elm={elm} getMyTicketsList={upcomingRefreshApi} key={elm?.id} isPast={false}/>
                                                        )) : <NoDataFound/>}
                                                </div>
                                                {
                                                    showMoreDataLoader ?
                                                        <div className="col-sm-12 text-center">
                                                            <img src={loader} alt="loader" style={{ height: "100px" }} />
                                                        </div> : null
                                                }
                                                <InView as="div" onChange={(inView, entry) => upcomingLoadMore(inView)} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey="second">
                                    <Card.Body className=" p-0">
                                        <Row>
                                            <Col lg={12}>
                                                {/* <div className="d-grid gap-3 d-grid-template-1fr-19 mb-5"> */}
                                                <div className=" mb-5">
                                                    {
                                                        pastTicketsList?.length > 0 ? pastTicketsList.map((elm, i) => (
                                                            <MyTicketsList elm={elm} getMyTicketsList={pastRefreshApi} key={elm?.id} isPast={true}/>
                                                        )) : <NoDataFound/>}
                                                </div>
                                                {
                                                    showMoreDataLoader ?
                                                        <div className="col-sm-12 text-center">
                                                            <img src={loader} alt="loader" style={{ height: "100px" }} />
                                                        </div> : null
                                                }
                                                <InView as="div" onChange={(inView, entry) => pastLoadMore(inView)} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                    {/* <div className="d-grid gap-3 d-grid-template-1fr-19 mb-5">
                  {
                     eventList?.length > 0 ? eventList.map((elm, i) => (
                        <MyEventCard elm={elm?.event} getEventList={refreshApi} key={elm?.id} />
                     )) : null}
               </div>
               {
                  showMoreDataLoader ?
                     <div className="col-sm-12 text-center">
                        <img src={loader} alt="loader" style={{ height: "100px" }} />
                     </div> : null
               }
               <InView as="div" onChange={(inView, entry) => loadMore(inView)} /> */}
                </Container>
            </div>
        </>
    )

}

export default MyTickets;