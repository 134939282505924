import React, { useState, useEffect } from 'react'
import { Card, Image } from 'react-bootstrap'

import { Link } from 'react-router-dom';

const RightSidebar = ({ onlineUserList }) => {

    const minirightsidebar = () => {
        document.getElementById('rightSidebar').classList.toggle('right-sidebar');
        document.body.classList.toggle('right-sidebar-close');
    }

    return ( 
        <>
            <div className="right-sidebar-mini" id="rightSidebar">
                <div className="right-sidebar-panel p-0">
                    <Card className="shadow-none">
                        <Card.Body className="p-0">
                            <div className="media-height p-3" data-scrollbar="init">
                                {
                                    onlineUserList?.map((elm, i) => (
                                        <Link key={elm?.id} to={`/chat/index?type=user&slug=${elm?.slug}`} target='_blank noopener,noreferrer'>
                                            <div className="d-flex align-items-center mb-4">
                                                <div className="avatar ">
                                                    <img loading="lazy" src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="chatuserimage" className="avatar-60 rounded-circle img-fluid" />
                                                    {
                                                        elm?.is_online === 1 ?
                                                            <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span> :
                                                            <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-warning"></i></span>
                                                    }
                                                </div>
                                                {/* <div className="iq-profile-avatar status-online">
                                                <Image className="rounded-circle avatar-50"
                                                    src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                    alt="" loading="lazy" />
                                            </div> */}
                                                <div className="ms-3">
                                                    <h6 className="mb-0">{elm?.first_name && (elm?.first_name + " " + elm?.last_name)}</h6>
                                                    <p className="mb-0">{elm?.slug}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}

                                {/* <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user1} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Anna Sthesia</h6>
                                        <p className="mb-0">Just Now</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user2} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Paul Molive</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user3} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Anna Mull</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user4} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Paige Turner</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user5} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Bob Frapples</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user2} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Barb Ackue</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user3} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Greta Life</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-away">
                                        <Image className="rounded-circle avatar-50" src={user6} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Ira Membrit</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-away">
                                        <Image className="rounded-circle avatar-50" src={user1} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Pete Sariya</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user2} alt="" loading="lazy" />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Monty Carlo</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div> */}
                            </div>
                            <div className="right-sidebar-toggle bg-primary text-white mt-3 d-flex" onClick={minirightsidebar}>
                                <span className="material-symbols-outlined">chat</span>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>

        </>
    )
}

export default RightSidebar
