import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { handleImageUpload } from "../../api/uploads";
import { BLOG_COMMENT_LIST, COMMENT_LIST } from "../../api/constants";
import BlogSingleUserComment from './blogSingleUserComment'


const BlogCommentList = ({ postId, refreshCommentList, setRefreshCommentList }) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [commentList, setCommentList] = useState([]);

    // useEffect(() => {
    //     getCommentList()
    // }, [])

    useEffect(() => {
        getCommentList()
        setRefreshCommentList(false)
    }, [refreshCommentList])


    async function getCommentList() {
        try {
            const res = await apiHandler(BLOG_COMMENT_LIST, "POST", {
                page: 1,
                limit: 100,
                blog_id: postId
            })
            if (res.data?.response?.status === 200) {
                setCommentList(res?.data?.data?.blogcats?.data)
            } else {
                //navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    return (
        <>
            <div className="comment-area mt-3" style={{ marginTop: "10px" }}>
                <h5 style={{ marginTop: "5px" }}>List of Comments</h5>
                {
                    commentList?.map((elm, i) => (
                        <div key={elm?.id}>
                            <BlogSingleUserComment postId={postId} commentElm={elm} setRefreshCommentList={setRefreshCommentList} />
                            {/* {
                                elm?.replies?.map((innerElm, index) => (
                                    <div style={{ marginLeft: "40px" }}>
                                        <BlogSingleUserComment postId={postId} commentElm={innerElm} setRefreshCommentList={setRefreshCommentList} />
                                    </div>
                                ))
                            } */}
                        </div>
                    ))
                }
                {/* <CommentBox postId={postId} commentId={""} /> */}
            </div>
        </>
    )
}

export default BlogCommentList
